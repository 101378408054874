.Sem-enquire-now {
    height: 10%;
}

.Sem-Second-page {
    position: relative;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}
.product-in-glanace {
    height: 15%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 3%;
    font-size: 20px;
    color: #fff;
    width: 100%;
}
.Drop-secondpage-cards-sem {
    height: 80%;
    width: 100%;
}

.Sem-Second-page-opacity-layer {
    position: absolute;
    height: 62%;
    width: 50%;
    opacity: 0.5;
    z-index: 999;
    background-color: grey;
    box-sizing: border-box;
    top: 22%;
}
.Sem-fourth-page p {
    font-size: 18px;
}

.Sem-fourth-page {
    height: 100%;
    width: 100%;
    background-image: url('../../../../public/images/Drop-fourth-page-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
}
.sem-fourth-page-inner-div {
    height: 526px;
    width: 1082px;
    background-image: url('../../../../public/images/sem-polygon.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.Sem-4-page-div-1 {
    height: 20%;
    width: 16%;
    position: absolute;
    bottom: 10%;
    font-size: 25px;
    left: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.Sem-4-page-div-2 {
    height: 20%;
    width: 16%;
    position: absolute;
    bottom: 39%;
    font-size: 25px;
    left: 21%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}


.Sem-4-page-div-3 {
    line-height: 55px;
    color: #ffff;
    text-align: left;
    position: absolute;
    top: 10%;
    left: 41%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

#Sem-p1 {
    font-size: 51px;
    font-weight: 700;
}

.Sem-4-page-div-3>p {
    font-size: 20px;
}

.Sem-4-page-div-4 {
    height: 20%;
    width: 14%;
    position: absolute;
    bottom: 40%;
    font-size: 27px;
    right: 21.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.Sem-4-page-div-5 {
    height: 20%;
    width: 14%;
    position: absolute;
    /* background-color: wheat; */
    bottom: 18%;
    font-size: 25px;
    right: 43%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.Sem-4-page-div-6 {
    height: 20%;
    width: 15%;
    position: absolute;
    /* background-color: wheat; */
    bottom: 11%;
    font-size: 25px;
    right: 4%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

#Sem-second-page-card-2 {
    background-color: #FFF8E4;
}

#Sem-second-page-card-3 {
    background-color: #C0DCFC;
}

#Drop {
    margin: 0;
    font-size: 45px;
    margin-left: 15px;
}
.Bustracking-card-container {
    height: 81%;
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 0;
}
.Bustracking-card-scroller {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

@media only screen and (max-width: 1537px) {

    .Drop-heading {
        font-size: 25px;
        height: 10%;
    }

    .Drop-paragraph {
        font-size: 25PX;
    }

    .Drop-enquire-now {
        margin-top: 10%;
    }

    .Drop-second-page-card-1 {
        height: 80%;
    }

    .product_in_glance {
        font-size: 20px;

    }

    .product_in_glance h1 {
        font-size: 24px;
    }

    .Drop-second-page-card-1>img {
        height: 35%;
    }

    .Drop-second-page-card-1>h1 {
        font-size: 30px;
    }

    .Drop-how-it-work {

        height: 10%;

    }

    .Drop-third-page-right-content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

    }

    .Third-right-div-1 {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        height: 40%;
    }

    .Drop-second-page-card-1 {
        width: 90% !important;
    }

    .drop-4-page-div-3>#p1 {
        font-size: 80px;
        font-weight: 700;
    }

    .Sem-fourth-page p {
        font-size: 20px;
    }

    #Sem-p1 {
        font-size: 65px;
    }
}

/* Responsive for Apple laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)) {


    .Drop-heading {
        height: 10%;

    }

    .Drop-paragraph {
        font-size: 23PX;
    }

    .Drop-first-image-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    .Drop-how-it-work>h1 {
        font-size: 44px;
    }

    .third-page-left-paragaph>p {
        font-size: 20px;
    }

    .drop-third-page-left-content {
        height: 80%;

    }

    .step02 {
        height: 62px;
        width: 162px;
    }

    .third-page-left-paragaph>h1 {

        font-size: 18px;
        width: 72%;
    }

    #Step-left-image {
        position: absolute;
        left: 0px;
        top: 66%;
        height: 45%;
    }

    .step01 {
        height: 62px;
        width: 162px;
    }

    .step-1-paragraph>p {
        font-size: 20px;
        width: 80%;
        text-align: left;

    }

    .Drop-third-page-right-content {

        margin-top: 25px;
    }

    .Drop-third-page-middle-content {
        height: 65%;
        margin-top: 54px;
    }

    .third-page-left-paragaph {
        height: 65%;

    }

    .step-div {
        height: 42%;
    }

    .step-1-paragraph>h1 {
        font-size: 20px;
    }

    .step-div {
        height: 37%;
    }

    .Sem-4-page-div-3 {
        height: 5%;
        width: 18%;
        line-height: 30px;
        color: #ffff;
        text-align: left;
        position: absolute;
        top: 22%;
        left: 43%;
    }

    .Sem-fourth-page {
        background-size: 100%;
    }

    .Sem-fourth-page p {
        font-size: 15px;
    }

    #Sem-p1 {
        font-size: 40px;
    }

    #step03-image {
        position: absolute;
        left: 65%;
        top: 2%;
        width: 35%;
    }

    .Sem-4-page-div-1 {
        bottom: 20%;
        left: 16%;

    }

    .Sem-4-page-div-2 {
        bottom: 42%;
    }

    .Sem-4-page-div-3 {
        top: 26%;
    }

    .Sem-4-page-div-5 {
        bottom: 25%;
    }

    .Sem-4-page-div-4 {
        bottom: 42%;
    }

    .Sem-4-page-div-6 {
        bottom: 20%;
    }

    #step01-image {
        top: 5%;
        right: 0;
        width: 30%;
    }

    .Sem-fourth-page {
        background-size: 100% 100%;
    }
}

/* Tablets */
/* @media (min-width: 601px) and (max-width: 768px) {
    .Sem-Second-page {
        height: 100%;
        width: 100%;
        background-size: 100% 100%;
    }

    .Sem-fourth-page {
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;
    }

    .Sem-4-page-div-1,
    .Sem-4-page-div-2,
    .Sem-4-page-div-4,
    .Sem-4-page-div-5,
    .Sem-4-page-div-6 {
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border: 1px solid #fff;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 10px;
        height: 14%;
        border: 1px solid #aba8a8;

    }

    .Sem-4-page-div-3 {
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 90%;
        left: 30px;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Sem-fourth-page>div>p {
        font-size: 20px;
        text-align: left;

    }
} */

/* @media (min-width: 769px) and (max-width: 1080px) {
    #Drop {
        margin: 0;
        font-size: 45px;
        margin-left: 15px;
    }

    .Drop-first-page-text-content p {
        margin: 0;
        margin-left: 15px;
        font-size: 20px;
        text-align: left;
    }

    .Drop-first-image-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    #drop-vector-3 {
        display: none;
    }

    .Drop-enquire-now {
        margin-top: 0%;
    }

    .drop-enquire-now-btn p {
        margin-top: 10px;
        margin-left: 40px;
    }

    #drop-vector-5 {
        position: absolute;
        bottom: 10%;
        right: 30%;
    }

    .Bustracking-second-page {
        padding-top: 15%;
    }

    .Drop-second-page-card-1>h1 {
        font-size: 22px;
    }

    .bustarcking-product-in-glanace {
        top: 15%;
        right: 0;
    }

    .Bustracking-card-container {
        overflow-x: hidden;
    }

    .Drop-second-page-card-1>p {
        font-size: 13px;
    }
} */

/* Laptops and Desktops */
@media (min-width: 1081px) and (max-width: 1366px) {
    #Drop {
        margin: 0;
        font-size: 55px;
        margin-left: 15px;
    }

    .Drop-paragraph {
        margin: 0;
        margin-left: 15px;
        font-size: 24px;
    }

    .Drop-enquire-now {
        margin-top: 2%;
    }

    #drop-vector-3 {
        left: 58%;
    }

    #drop-vector-4 {
        bottom: 8%;
    }

    .Drop-second-page-card-1>p {
        font-size: 15px;
        color: #000;
    }

    .Drop-second-page-card-1>h1 {
        font-size: 25px;
    }
    #Sem-p1 {
        font-size: 52px;
    }
    #Step-left-image {
        position: absolute;
        left: 20px;
        top: 0%;
        width: 200px;
        height: auto;
    }
    #step01-image {
        position: absolute;
        top: 5%;
        right: 0;
        width: 200px;
        height: auto;
    }
    #step03-image {
        position: absolute;
        bottom: 60%;
        right: 0%;
        width: 200px;
        height: auto;
        top: unset;
        left: unset;
    }
    .Drop-third-page-middle-content>svg {
        height: 62%;
    }
    .Third-right-div-1 {
        padding-top: 20%;
    }
    .step01 {
        height: 50px;
        width: 200px;
    }
    .step01>h1, .step03>h1 {
        font-size: 28px;
    }
    .third-page-left-paragaph>h1 {
        font-size: 28px;
    }
    .step-1-paragraph>h1 {
        font-size: 28px;
    }
    .step-1-paragraph>p {
        font-size: 20px;
    }
    .third-page-left-paragaph>p {
        text-align: right;
        font-size: 20px;
    }
    .step-div {
        height: 62%;
    }
    .Sem-fourth-page p {
        font-size: 17px;
    }
    .drop-4-page-div-3>#p1 {
        font-size: 64px;
        font-weight: 700;
    }
    .Drop-second-page-card-1-sem {
        width: 100% !important;
    }
    .sem-card-scroller .swiper-slide {
        width: 15% !important;
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    .Drop-first-page-sub-div {

        padding-top: 100px;
    }

    #Drop-image {
        height: auto;
        width: 75%;
    }

    .Drop-first-image-content {
        height: 100%;
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .Drop-third-page-middle-content>svg {
        height: 65%;
        width: auto;
        margin-top: 80%;
    }
    #Sem-p1 {
        font-size: 52px;
    }
    .Drop-third-page-middle-content>svg {
        height: 62%;
    }
    .Third-right-div-1 {
        padding-top: 20%;
    }
    .step01 {
        height: 50px;
        width: 200px;
    }
    .step01>h1, .step03>h1 {
        font-size: 28px;
    }
    .third-page-left-paragaph>h1 {
        font-size: 28px;
    }
    .step-1-paragraph>h1 {
        font-size: 28px;
    }
    .step-1-paragraph>p {
        font-size: 20px;
    }
    .third-page-left-paragaph>p {
        text-align: left;
        font-size: 20px;
    }
    .step-div {
        height: 62%;
    }
    #step03-image {
        position: absolute;
        bottom: 60%;
        right: 0%;
        width: 200px;
        height: auto;
        top: unset;
        left: unset;
    }
    #drop-vector-3 {
        position: absolute;
        top: 45%;
        left: 55%;
    }
    #Step-left-image {
        position: absolute;
        left: 20px;
        top: 0%;
        width: 200px;
        height: auto;
    }
    #step01-image {
        position: absolute;
        top: 5%;
        right: 0;
        width: 200px;
        height: auto;
    }
    .Sem-fourth-page p {
        font-size: 17px;
    }
    .drop-4-page-div-3>#p1 {
        font-size: 64px;
    }
    .Drop-second-page-card-1-sem {
        width: 100% !important;
    }
    .sem-card-scroller .swiper-slide {
        width: 15% !important;
    }

}

@media screen and (max-width: 1024px){

}

/* Responsive for ipad pro and ipad air */
@media (min-width: 820px) and (max-width: 1080px){
    .Sem-Second-page {
        height: 100%;
        width: 100%;
        background-size: 100% 100%;
    }


    .Sem-fourth-page {
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;
    }

    .Sem-4-page-div-1,
    .Sem-4-page-div-2,
    .Sem-4-page-div-4,
    .Sem-4-page-div-5,
    .Sem-4-page-div-6 {
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border: 1px solid #fff;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 10px;
        height: 14%;
        border: 1px solid #aba8a8;

    }
    #Sem-p1{
        font-size: 40px !important;
        width: 100%;
        text-align: center;
    }

    .Sem-4-page-div-3 {
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Sem-fourth-page>div>p {
        font-size: 20px;
        text-align: left;
    }

    .Drop-second-page-card-1 {
        width: 100% !important;
    }
    .product-in-glanace {
        height: 4%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 3%;
        font-size: 10px;
        color: #fff;
        width: 100%;
    }

    .sem-fourth-page-inner-div {
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    #Sem-p1{
        font-size: 100px;
        position: absolute;
        margin: 0;
        top: -40%;
    }
    .Sem-fourth-page p {
        font-size: 35px;
    }
    .swiper-slide {
        width: 400px !important;
    }
    .drop-product .Drop-second-page-card-1 {
        width: 120% !important;
    }
    .Sem-fourth-page p {
        font-size: 35px;
        width: 100%;
    }

}

@media (min-width: 768px) and (max-width: 819px){
    .Sem-Second-page {
        height: 100%;
        width: 100%;
        background-size: 100% 100%;
    }


    .Sem-fourth-page {
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;
    }

    .Sem-4-page-div-1,
    .Sem-4-page-div-2,
    .Sem-4-page-div-4,
    .Sem-4-page-div-5,
    .Sem-4-page-div-6 {
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border: 1px solid #fff;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 10px;
        height: 14%;
        border: 1px solid #aba8a8;

    }
    #Sem-p1{
        font-size: 40px !important;
        width: 100%;
        text-align: center;
    }

    .Sem-4-page-div-3 {
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Sem-fourth-page>div>p {
        font-size: 20px;
        text-align: left;
    }

    .Drop-second-page-card-1 {
        width: 100% !important;
    }
    .product-in-glanace {
        height: 4%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 3%;
        font-size: 10px;
        color: #fff;
        width: 100%;
    }

    .sem-fourth-page-inner-div {
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    #Sem-p1{
        position: absolute;
        margin: 0;
        top: -50%;
    }

    .Sem-fourth-page p {
        font-size: 30px;
    }

}

/* Responsive for Mobile */
@media screen and (max-width: 767px){
    .Sem-Second-page {
        height: 100%;
        width: 100%;
        background-size: 100% 100%;
    }


    .Sem-fourth-page {
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;
    }

    .Sem-4-page-div-1,
    .Sem-4-page-div-2,
    .Sem-4-page-div-4,
    .Sem-4-page-div-5,
    .Sem-4-page-div-6 {
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        border: 1px solid #fff;
        border-radius: 20px;
        box-sizing: border-box;
        padding: 10px;
        height: 14%;
        border: 1px solid #aba8a8;

    }
    #Sem-p1{
        font-size: 40px !important;
        width: 100%;
        text-align: center;
    }

    .Sem-4-page-div-3 {
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Sem-fourth-page>div>p {
        font-size: 20px;
        text-align: left;
    }

    .Drop-second-page-card-1 {
        width: 100% !important;
    }
    .product-in-glanace {
        height: 4%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 3%;
        font-size: 10px;
        color: #fff;
        width: 100%;
    }

    .sem-fourth-page-inner-div {
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    #Sem-p1{
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -80%;
    }

}
/* End for Mobile Responsive */
