#Web-first-page-image{
    height: 60%;
    /* width: 100%; */
}

#web-development-second-page{
    position: relative;
    background-image: url('../../../../public/images/web.png');
    background-repeat: no-repeat;
    background-size: cover;
}

#vector-color{
    position: absolute;
    right: 20%;
    top: 8%
}

#Vectorempty{
    position: absolute;
    left: 28%;
    top: 14%;
}

#Vectoremptyrotate{
    position: absolute;
    left: 10%;
    bottom: 10%;
    transform: rotate(40deg);
}

#white-Vector{
    position: absolute;
    top: 35%;
    left: 7%;
}

#VectorsmallWhite{
 position: absolute;
 left: 45%;
 bottom: 20%;   
}

#service-threeDot{
    position: absolute;
    bottom: 2%;
    right: 2%;
}

#vector-color,#Vectorempty,#Vectoremptyrotate,#white-Vector,#VectorsmallWhite{


    animation: 3s infinite vectors linear;
}

@keyframes vectors {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(60deg);
    }
}

/* Responsive for mobile */
@media (max-width: 320px) {
    #Web-first-page-image{
        height: 80%;
        width: 80% !important; 
        
    }
}
@media (min-width: 321px) and (max-width: 375px){
    #Web-first-page-image{
        height: 80%;
        width: 80% !important; 
        
    }
}

@media (min-width: 376px) and (max-width: 600px){
    #Web-first-page-image{
        height: 80%;
        width: 80% !important; 
        
    }
}

@media screen and (max-width: 767px){
    #Web-first-page-image {
        height: 79%;
        width: 60%;
    }
}




