.TechNodeJs-main-container {
   height: 100vh;
   width: 100%;
   background-color: #272d2f;
   display: flex;
   justify-content: center;
   align-items: center;
   position: relative;

}

#nodejs-VectorEmpty1 {
   position: absolute;
   top: 10%;
   left: 13%;
}

#nodejs-Vectorcolor1 {
   position: absolute;
   top: 10%;
   right: 15%;
   height: 4%;
}

#nodejs-white-Vector {
   position: absolute;
   bottom: 50%;
   left: 5%;
   height: 3%;
}

#nodejs-Vectorcolor2 {
   position: absolute;
   bottom: 14%;
   left: 30%;
   height: 4%;
   transform: rotate(170deg);
}

#nodejs-VectorEmpty2 {
   position: absolute;
   bottom: 15%;
   right: 25%;
   transform: rotate(40deg);
}

#nodejs-VectorEmpty1,
#nodejs-Vectorcolor1,
#nodejs-white-Vector,
#nodejs-Vectorcolor2,
#nodejs-VectorEmpty2 {


   animation: 3s infinite vectors linear;
}

@keyframes vectors {
   0% {
      transform: rotate(0deg);
   }

   50% {
      transform: rotate(60deg);
   }
}



#TechNodeJs-top-right-corner-logo {
   position: absolute;
   right: 0;
   top: 0%;
}

.TechNodeJs-sub-container {
   height: 90%;
   width: 80%;
   /* background-color: aqua; */
   display: flex;
   justify-content: center;
   align-items: center;
}



.nodejs-logo-div-center {
   /* background-color: aqua; */
   height: 40%;
   width: 25%;
   position: relative;

}

.TechNodeJs-sub-container div svg {
   /* background-color: aqua; */
   height: 100%;
   width: 100%;
}

#node-logo {
   position: absolute;
   left: 27%;
   height: 170px;
   width: 170px;
   /* background-color: bisque; */
   top: 27%;
}



.nodejs-logo-div-top-left {
   position: absolute;
   height: 80%;
   width: 80%;
   /* background-color: rgb(170, 103, 15); */
   top: -65%;
   left: -33%;
   display: flex;
   align-items: center;
   justify-content: center;

}

#nodejs-logo-div-top-left-svg {
   height: 100px;
   width: 100px;
   position: absolute;
}


.nodejs-logo-div-top-left-content {
   position: absolute;
   height: 100%;
   width: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   text-align: left;
   flex-direction: column;
   align-items: center;
   padding: 50px 50px 80px 70px;
   opacity: 0;
   color: #fff;
}


.nodejs-logo-div-top-right {
   position: absolute;
   height: 80%;
   width: 80%;
   /* background-color: antiquewhite; */
   top: -65%;
   left: 52%;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

#nodejs-logo-div-top-right-svg {
   height: 100px;
   width: 100px;
   position: absolute;
}

.nodejs-logo-div-top-right-content {
   position: absolute;
   height: 100%;
   width: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   text-align: left;
   flex-direction: column;
   align-items: center;
   padding: 50px 50px 80px 70px;
   opacity: 0;
   color: #fff;
}



.nodejs-logo-div-center-left {
   position: absolute;
   height: 80%;
   width: 80%;
   /* background-color: antiquewhite; */
   top: 10%;
   right: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

}


#nodejs-logo-div-center-left-svg {
   height: 100px;
   width: 100px;
   position: absolute;
}

.nodejs-logo-div-center-left-content {
   position: absolute;
   height: 100%;
   width: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   text-align: left;
   flex-direction: column;
   align-items: center;
   padding: 50px 50px 80px 70px;
   opacity: 0;
   color: #fff;
   /* background-color: yellow; */
}

.nodejs-logo-div-center-right {
   position: absolute;
   height: 80%;
   width: 80%;
   /* background-color: antiquewhite; */
   top: 10%;
   left: 90%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

}


#nodejs-logo-div-center-right-svg {
   height: 100px;
   width: 100px;
   position: absolute;
}

.nodejs-logo-div-center-right-content {
   position: absolute;
   height: 100%;
   width: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   text-align: left;
   flex-direction: column;
   align-items: center;
   padding: 50px 50px 80px 70px;
   opacity: 0;
   color: #fff;
}


.nodejs-logo-div-bottom-left {
   position: absolute;
   height: 80%;
   width: 80%;
   /* background-color: antiquewhite; */
   top: 85.5%;
   right: 51.2%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;

}

#nodejs-logo-div-bottom-left-svg {
   height: 100px;
   width: 100px;
   position: absolute;
}

.nodejs-logo-div-bottom-left-content {
   position: absolute;
   height: 100%;
   width: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   text-align: left;
   flex-direction: column;
   align-items: center;
   padding: 50px 50px 80px 70px;
   opacity: 0;
   color: #fff;
}

.nodejs-logo-div-bottom-right {
   position: absolute;
   height: 80%;
   width: 80%;
   /* background-color: antiquewhite; */
   top: 85.5%;
   left: 51.2%;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

#nodejs-logo-div-bottom-right-svg {
   height: 200px;
   width: 200px;
   position: absolute;
}

.nodejs-logo-div-bottom-right-content {
   position: absolute;
   height: 100%;
   width: 100%;
   box-sizing: border-box;
   display: flex;
   justify-content: center;
   text-align: left;
   flex-direction: column;
   align-items: center;
   padding: 50px 50px 80px 70px;
   opacity: 0;
   color: #fff;
}

.TechNodeJs-sub-container p {
   font-size: 12px;
}

.TechNodeJs-sub-container h3 {
   font-size: 15px;
}

.nodejs-logo-div-center-right-content {
   padding: 0;
   text-align: center;
   width: 60%;
}

.nodejs-logo-div-top-right-content {
   padding: 0;
   text-align: center;
   width: 60%;
}

.nodejs-logo-div-bottom-right-content {
   padding: 0;
   text-align: center;
   width: 60%;
}

.nodejs-logo-div-bottom-left-content {
   padding: 0;
   text-align: center;
   width: 60%;
}

.nodejs-logo-div-center-left-content {
   padding: 0;
   text-align: center;
   width: 60%;
}

.nodejs-logo-div-top-left-content {
   padding: 0;
   text-align: center;
   width: 60%;
}

.nodejs-logo-div-top-left {
   top: -75%;
   left: -32%;
}

.nodejs-logo-div-bottom-left {
   top: 94.5%;
   right: 51.2%;
}

.nodejs-logo-div-bottom-right {
   top: 95.5%;
   left: 51.2%;
}

.nodejs-logo-div-top-right {
   top: -72%;
   left: 52%;
}


/* Responsive for width:1920 and height:1080 */


@media (min-width: 1081px) and (max-width: 1366px) {

   .TechNodeJs-sub-container p {
      font-size: 8px;
   }

   .TechNodeJs-sub-container h3 {
      font-size: 10px;
   }

   .nodejs-logo-div-center-right-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-top-right-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-bottom-right-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-bottom-left-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-center-left-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-top-left-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   #node-logo {
      left: 43px;
      top: 44px;
   }

   .TechNodeJs-sub-container {
      height: auto;
      width: 75%;
   }

   .TechNodeJs-sub-container div svg {
      height: 86%;
      width: 78%;
   }

   #nodejs-logo-div-center-left-svg {
      height: auto;
      position: absolute;
      width: 30%;
   }

   #nodejs-logo-div-bottom-left-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #nodejs-logo-div-bottom-right-svg {
      height: auto;
      width: 42%;
      position: absolute;
   }

   #nodejs-logo-div-center-right-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #nodejs-logo-div-top-right-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #nodejs-logo-div-top-left-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #node-logo {
      height: auto;
      width: 65%;
   }

   .nodejs-logo-div-top-left {
      top: -68%;
      left: -31%;
   }

   .nodejs-logo-div-top-right {
      top: -69%;
      left: 52%;
   }


}

@media (min-width: 1366px) and (max-width: 1600px) {

   .TechNodeJs-sub-container p {
      font-size: 8px;
   }

   .TechNodeJs-sub-container h3 {
      font-size: 10px;
   }

   .nodejs-logo-div-center-right-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-top-right-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-bottom-right-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-bottom-left-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-center-left-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   .nodejs-logo-div-top-left-content {
      padding: 0;
      text-align: center;
      width: 60%;
   }

   #node-logo {
      left: 51px;
      top: 64px;
   }

   .TechNodeJs-sub-container {
      height: auto;
      width: 75%;
   }

   .TechNodeJs-sub-container div svg {
      height: 86%;
      width: 78%;
   }

   #nodejs-logo-div-center-left-svg {
      height: auto;
      position: absolute;
      width: 30%;
   }

   #nodejs-logo-div-bottom-left-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #nodejs-logo-div-bottom-right-svg {
      height: auto;
      width: 42%;
      position: absolute;
   }

   #nodejs-logo-div-center-right-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #nodejs-logo-div-top-right-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #nodejs-logo-div-top-left-svg {
      height: auto;
      width: 30%;
      position: absolute;
   }

   #node-logo {
      height: auto;
      width: 65%;
   }

   .nodejs-logo-div-top-left {
      top: -68%;
      left: -31%;
   }

   .nodejs-logo-div-top-right {
      top: -69%;
      left: 52%;
   }
}


/* Responsive for Ipad pro, ipad mini */
@media (min-width: 820px) and (max-width: 1080px){
   .TechNodeJs-main-container {
      height: 100vh;
      width: 100%;
   }

   .TechNodeJs-sub-container,
   #TechNodeJs-top-right-corner-logo,
   #nodejs-VectorEmpty1,
   #nodejs-Vectorcolor1,
   #nodejs-white-Vector,
   #nodejs-Vectorcolor2,
   #nodejs-VectorEmpty2 {
      display: none;
   }

   .nodejs-page-for-mobile {
      display: block;
      height: 100%;
      width: 100%;
   }
   .nodejsSvg{
      position: relative;
      height:auto;
      width: 73%;
   }
   .nodejs-mobile-card-1-left h3{
     font-size: 25px;
   }

   .nodejs-mobile-card-1-right p{
      font-size: 30px;
   }
}

@media (min-width: 768px) and (max-width: 819px){
   .TechNodeJs-main-container {
      height: 100vh;
      width: 100%;
   }

   .TechNodeJs-sub-container,
   #TechNodeJs-top-right-corner-logo,
   #nodejs-VectorEmpty1,
   #nodejs-Vectorcolor1,
   #nodejs-white-Vector,
   #nodejs-Vectorcolor2,
   #nodejs-VectorEmpty2 {
      display: none;
   }

   .nodejs-page-for-mobile {
      display: block;
      height: 100%;
      width: 100%;
   }
   .nodejsSvg{
      position: relative;
      height:auto;
      width: 73%;
   }
}

/* Responsive for mobile */
@media screen and (max-width: 767px) {
   .TechNodeJs-main-container {
      height: 100vh;
      width: 100%;
   }

   .TechNodeJs-sub-container,
   #TechNodeJs-top-right-corner-logo,
   #nodejs-VectorEmpty1,
   #nodejs-Vectorcolor1,
   #nodejs-white-Vector,
   #nodejs-Vectorcolor2,
   #nodejs-VectorEmpty2 {
      display: none;
   }

   .nodejs-page-for-mobile {
      display: block;
      height: 100%;
      width: 100%;
   }
   .nodejsSvg{
      position: relative;
      height:auto;
      width: 73%;
   }
}
