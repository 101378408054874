.BusTracking-enqiure-now {
    height: 28%;
}

.Bustracking-second-page {
    height: 100%;
    width: 100%;
    background-image: url('../../../../public/images/Bustracking-2-page-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding-top: 3%;
    flex-direction: column;
}

.bustarcking-product-in-glanace {
    position: absolute;
    top: 5%;
    right: 0;
}

.Bustracking-card-container {
    height: 90%;
    /* background-color: blueviolet; */
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
    padding: 2%;
}

.Bustracking-card-scroller {
    height: 100%;
    width: 120vw;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}





.bustracking-card-1 {
    height: 60%;
    width: 25%;
    background-color: tomato;
}


.Bustracking-fourth-page {
    height: 100%;
    width: 100%;
    background-image: url('../../../../public/images/Drop-fourth-page-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
}

.bustracking-fourth-page-inner-div {
    height: 526px;
    width: 1165px;
    background-image: url("../../../../public/images/bustracking-polygon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.bustracking-3-page-div-1 {
    height: 28%;
    position: absolute;
    bottom: 14%;
    left: 6%;
    font-size: 25px;
    width: 12%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.bustracking-3-page-div-2 {
    width: 10%;
    color: #fff;
    font-size: 20px;
    position: absolute;
    left: 24%;
    bottom: 37%;
}
.bustracking-3-page-div-5 {
    height: 20%;
    width: 10%;
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: 28%;
    bottom: 39%;
}
.bustracking-3-page-div-3 {
    height: 25%;
    width: 18%;
    position: absolute;
    top: 13%;
    left: 39.5%;
    color: #fff;
    line-height: 55px;
}

.bustracking-3-page-div-3>p {
    text-align: left;
    font-size: 25px
}

#p1 {
    font-size: 95px;
    color: #fff;
    font-weight: 700;
    text-align: left;
}

.bustracking-3-page-div-4 {
    height: 20%;
    width: 12%;
    /* background-color: yellow; */
    font-size: 25px;
    position: absolute;
    bottom: 11%;
    left: 42.5%;
    color: #fff;
}

.dbustracking-3-page-div-5 {
    height: 18%;
    width: 10%;
    /* background-color: yellow; */
    position: absolute;
    font-size: 25px;
    color: #fff;
    bottom: 37%;
    right: 29.5%;
}

.bustracking-3-page-div-6 {
    height: 28%;
    position: absolute;
    bottom: 13%;
    right: 5%;
    font-size: 25px;
    width: 12%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#Bustracking-second-page-card-2 {
    background-color: #FFF8E4;
}

#Bustracking-second-page-card-3 {
    background-color: #C0DCFC;
}

#Bustracking-second-page-card-4 {
    background-color: #FFF8E4;
}

.Bustracking-card-scroller .swiper {
    height: 100%;
    width: 100%;
}

.Drop-second-page-card-1 {
    width: 90% !important;
    margin-top: 20%;
    margin-left: 10%;
}
.Bustracking-fourth-page p {
    font-size: 15px;
}
#p1 {
    font-size: 58px;
}
.Bustracking-fourth-page svg {
    width: 40px;
    height: auto;
}
.Bustracking-fourth-page p {
    font-size: 15px;
}

@media (max-width: 320px){
        
    .Bustracking-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .bustracking-3-page-div-1,.bustracking-3-page-div-2, .bustracking-3-page-div-4, .bustracking-3-page-div-5,.bustracking-3-page-div-6{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;

    }

    .bustracking-3-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 90%;
        left: 19px;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Bustracking-fourth-page > div > p{
        font-size: 20px;
        text-align: left;
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding-left: 10%;
        
    }
}
@media (min-width: 321px) and (max-width: 375px){
        
    .Bustracking-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .bustracking-3-page-div-1,.bustracking-3-page-div-2, .bustracking-3-page-div-4, .bustracking-3-page-div-5,.bustracking-3-page-div-6{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;

    }

    .bustracking-3-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 90%;
        left: 19px;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Bustracking-fourth-page > div > p{
        font-size: 20px;
        text-align: left;
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding-left: 10%;
        
    }
}
@media (min-width: 601px) and (max-width: 768px){
        
    .Bustracking-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .bustracking-3-page-div-1,.bustracking-3-page-div-2, .bustracking-3-page-div-4, .bustracking-3-page-div-5,.bustracking-3-page-div-6{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;

    }

    .bustracking-3-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 90%;
        left: 19px;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Bustracking-fourth-page > div > p{
        font-size: 20px;
        text-align: left;
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding-left: 10%;
        
    }
}
@media only screen and (max-width: 1537px) {
    .Bustracking-fourth-page p {
        font-size: 20px;
    }
    .Bustracking-fourth-page svg{
        height: auto;
        width: 60px;
    }
    #p1 {
        font-size: 75px;
    }
    #drop-vector-6 {
        position: absolute;
        left: 2%;
        top: 13%;
    }
    .Bustracking-card-scroller {
        margin-left: 30px;
    }
    
}

/* Responsive for Apple Laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)) {
  
    .Bustracking-fourth-page {
        background-size: 100% 100%; 
    }

    .bustracking-3-page-div-3 {
        top: 26%;
        left: 40%;
        line-height: 34px;
    }
    #p1 {
        font-size: 45px;
    }
    .bustracking-3-page-div-1 {
        bottom: 18%;
    }
    .bustracking-3-page-div-2 {
        font-size: 6px;
        bottom: 41%;
    }
    .Bustracking-fourth-page p {
        font-size: 16px;
    }
    .dbustracking-3-page-div-5 {
        bottom: 40%;
    }
    .bustracking-3-page-div-4 {
        bottom: 15%;
    }
    .bustracking-3-page-div-6 {
        bottom: 16%;
    }

}

/* @media only screen and (max-width: 600px) {
        
    .Bustracking-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .bustracking-3-page-div-1,.bustracking-3-page-div-2, .bustracking-3-page-div-4, .bustracking-3-page-div-5,.bustracking-3-page-div-6{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;

    }

    .bustracking-3-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 90%;
        left: 19px;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Bustracking-fourth-page > div > p{
        font-size: 20px;
        text-align: left;
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        padding-left: 10%;
        
    }
} */

@media (min-width: 1081px) and (max-width: 1366px) {
    .Bustracking-fourth-page p {
        font-size: 15px;
    }
    .Bustracking-fourth-page svg {
        width: 40px;
    }
    #p1 {
        font-size: 58px;
    }
}
@media (min-width: 1367px) and (max-width: 1600px) {
    .Bustracking-fourth-page p {
        font-size: 15px;
    }
    .Bustracking-fourth-page svg {
        width: 40px;
    }
    #p1 {
        font-size: 58px;
    }
}
@media (min-width: 1600px) {
    .Bustracking-card-scroller .swiper-wrapper{
        transform: translate3d(14.833px, 0px, 0px) !important;
    }
    
}

@media (min-width: 820px) and (max-width: 1080px){
    .Bustracking-card-scroller {
        height: 90%;
        width: 225vw !important;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30px;
    }

    .Bustracking-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .bustracking-3-page-div-1,.bustracking-3-page-div-2, .bustracking-3-page-div-4, .bustracking-3-page-div-5,.bustracking-3-page-div-6{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;

    }

    .bustracking-3-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #p1{
        font-size: 40px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Drop-first-page-text-content p {
        margin: 0;
        margin-left: 15px;
        /* font-size: 15px !important; */
        text-align: left;
    }
    .Drop-second-page-card-1 {
        width: 100% !important;
}
    .Drop-second-page-card-1>p {
        font-size: 35px !important;
        margin: 0px !important;
    }
    .Drop-second-page-card-1>h1 {
        font-size: 40px !important;
    }

    .connect p {
        font-size: 25px !important;
        height: 43px;
        width: 214px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Bustracking-fourth-page p {
        font-size: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        text-align: left;
        padding-left: 5%;
    }

    .bustracking-fourth-page-inner-div{
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    .bustracking-p1{
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -80%;
    }
    .drop-product .swiper-slide {
        margin-right: 19% !important;
        margin-left: 45% !important;
        width: 366px !important;
    }
    .bustracking-p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -45%;
    }

    .Bustracking-fourth-page svg {
        height: auto;
        width: 90px;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .Bustracking-card-scroller {
        height: 90%;
        width: 225vw !important;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30px;
    }

    .Bustracking-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .bustracking-3-page-div-1,.bustracking-3-page-div-2, .bustracking-3-page-div-4, .bustracking-3-page-div-5,.bustracking-3-page-div-6{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;

    }

    .bustracking-3-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #p1{
        font-size: 40px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Drop-first-page-text-content p {
        margin: 0;
        margin-left: 15px;
        font-size: 30px !important;
        text-align: left;
    }
    .Drop-second-page-card-1 {
        width: 100% !important;
}
    .Drop-second-page-card-1>p {
        font-size: 25px !important;
        margin: 0px !important;
    }
    .Drop-second-page-card-1>h1 {
        font-size: 35px !important;
    }

    .connect p {
        font-size: 25px !important;
        height: 43px;
        width: 214px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Bustracking-fourth-page p {
        font-size: 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        text-align: left;
        padding-left: 5%;
    }

    .bustracking-fourth-page-inner-div{
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    .bustracking-p1{
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -80%;
    }
    .drop-product .swiper-slide {
        margin-right: 19% !important;
        margin-left: 45% !important;
        width: 325px !important;
    }
    .bustracking-p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -45%;
    }
}

@media screen and (max-width: 767px) {
        
    .Bustracking-card-scroller {
        height: 90%;
        width: 225vw !important;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 30px;
    }

    .Bustracking-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .bustracking-3-page-div-1,.bustracking-3-page-div-2, .bustracking-3-page-div-4, .bustracking-3-page-div-5,.bustracking-3-page-div-6{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       flex-direction: row;
       justify-content: flex-start;
       align-items: center;

    }

    .bustracking-3-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #p1{
        font-size: 40px;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Drop-first-page-text-content p {
        margin: 0;
        margin-left: 15px;
        font-size: 15px !important;
        text-align: left;
    }
    .Drop-second-page-card-1 {
        width: 100% !important;
}
    .Drop-second-page-card-1>p {
        font-size: 20px !important;
        margin: 0px !important;
    }
    .Drop-second-page-card-1>h1 {
        font-size: 30px !important;
    }

    .connect p {
        font-size: 20px !important;
        height: 43px;
        width: 214px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Bustracking-fourth-page p {
        font-size: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        text-align: left;
        padding-left: 5%;
    }

    .bustracking-fourth-page-inner-div{
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    .bustracking-p1{
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -80%;
    }
    .drop-product .swiper-slide {
        margin-right: 19% !important;
        margin-left: 45% !important;
        width: 266px !important;
    }
}