
.ServieUiUxClick-main-container{
    height: 100%;
    width: 100%;
    margin: 0;
}

.ServiceUiUxClick-firstpage-page{
    height: 100vh;
    width: 100%;
    background-color: #444647;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ServiceUiUxClick-first-page-content-container{
    height: 85%;
    width: 90%;
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;


}

.left-container-SeriviceUiUxclick-image{
    
    height: 100%;
    width: 40%;
    /* background-color: aliceblue; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;

}

.left-container-Seriviceiotclick-image > img {
    height: 98%;
}

.right-container-SeriviceiUiUxclick{

    height: 100%;
    width: 50%;
    /* background-color: aliceblue; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 70px;
    color: #fff;

}

.right-container-SeriviceUiUxclick-heading{
    height: 17%;
    width: 50%;
    /* background-color: aqua; */
    font-size: 60px;
    text-align: left;
    font-weight: 700;
    font-family: "Montserrat";
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20px;
}

.right-container-SeriviceUiUxclick-para{
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    font-family: "Montserrat";
    width: 100%;
    /* background-color: aquamarine; */
    text-align: left;
    margin-top: 20px;
    padding-left: 20px;
    height: 40%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    width: 100%;
    /* background-color: rgb(161, 100, 9); */
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    padding-left: 20px; 
    gap: 80px;
}


.card-content{
    height: 100%;
    width: 30%;
    /* background-color: blue; */
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    color: #fff;
    box-sizing: border-box;
}

.card-content > h1 {
    font-weight: 700;
    margin-bottom: 0px;
    width: 40%;
    box-sizing: border-box;
    color: #fff;


}

#card-content-h1{
    color: #FF8402;
}

.Mobile-application-secondpage-container{
    height: 100vh;
    width: 100%;
    background-color: #0a6390;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UiUx-first-page-content-container{
    flex-direction: row-reverse;
}


.UiUx-second-apge{
    background-image: url('../../../../public/images/Service-Uiux-second-page-image.png');
    background-repeat: no-repeat;
    background-size: cover;

}

#Service-Uiux-first-page-image{
    height: 75%;
    width: auto;
}

/* Responsive for mobile */


@media only screen and (max-width: 768px) {
 
    .UiUx-first-page-content-container{
        flex-direction: column;
    }
    .cards {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 100%;
        /* background-color: rgb(161, 100, 9); */
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0px;
        gap: 80px;
    }
    #Service-Uiux-first-page-image {
        height: 107%;
        width: auto;
    }
}