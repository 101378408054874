.tech-springboot {
    height: 100%;
    width: 100%;
    background-color: #272d2f;
    color: #ffffff;
}

.springboot-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.springboot-info-container {
    width: 1134.369384765625px;
    height: 491.0000305175781px;
    z-index: 1;

}

.springboot-page-for-mobile {
    display: none;
}

.springboot-info {
    width: 1134.369384765625px;
    height: 491.0000305175781px;
    display: flex;
    flex-wrap: nowrap;
}

.sbinfo {
    height: 488.15592488068427px;
    width: 242.03885071726742px;
    border-radius: 68px;
    background-color: #3E4142;
    box-shadow: 0px 8px 9px 0px #0000004D, 3px -5px 18px 0px #0000004A inset;
    margin: 0px 20px;

}

.springboot-heading img {
    height: 100px;
    margin-right: 20px;
}

.springboot-svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.sbsvg1 {
    display: inline-block;
    position: absolute;
    top: 40%;
    left: 2%;
}

.sbsvg2 {
    display: inline-block;
    position: absolute;
    top: 77%;
    left: 0%;
}

.sbsvg3 {
    display: inline-block;
    position: absolute;
    bottom: 0%;
    left: 0%;
}

.sbsvg4 {
    display: inline-block;
    position: absolute;
    top: 7%;
    left: 27%;
}

.sbsvg5 {
    display: inline-block;
    position: absolute;
    bottom: 5%;
    right: 32%;
}

.sbsvg6 {
    bottom: 30%;
    right: 19.8%;
    display: inline-block;
    position: absolute;
}

.sbsvg7 {
    display: inline-block;
    position: absolute;
    right: 1%;
    bottom: 15px;
}

.sbsvg8 {
    display: inline-block;
    position: absolute;
    top: 10%;
    right: 11%;
}

.sbsvg9 {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
}

.sbsvg1,
.sbsvg4,
.sbsvg5,
.sbsvg6,
.sbsvg8 {
    animation: vector infinite 3s linear;

}

@keyframes vector {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(90deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.container-sbinfo {
    height: 50%;
    width: 100%;
    position: relative;
}

.container-svg-polygon {
    width: 100%;
    height: 196.22px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: matrix(1, 0.02, -0.02, 1, 10, 7);
    background: url("../../images/Vector\ 95.png");
    background-repeat: no-repeat;
    rotate: -1.9deg;
    position: relative;

}

.container-svg-polygon svg {
    margin-right: 10%;
    margin-bottom: 5%;


}

.container-sbinfo h2 {
    font-family: 'Poppins';
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    margin: 0;
    text-align: center;
    margin-top: 5px;
    position: relative;
}

.sbinfo p {
    visibility: hidden;
}

.container-sbinfo:hover .http,
.container-sbinfo:hover .db {
    top: 95px !important;
}

.container-sbinfo:hover .container-svg-polygon {
    transition: 700ms ease;
    position: relative;
    top: 65px;
    width: 210px;
    height: 172.9px;
    background-size: 210px 171.9px;
    margin-left: 5%;
}

.container-sbinfo:hover .dbHead {
    font-size: 25px;
}

.container-sbinfo:hover p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    visibility: visible;
    margin-left: 15px;
    margin-top: 40px;
}

.container-sbinfo:hover .dbpara {
    margin-top: 10px !important;
}

.container-sbinfo:hover h2 {
    top: -165px;
    transition: 300ms ease;

}


@media (min-width: 769px) and (max-width: 1080px) {

    .sbinfo {
        height: 96%;
        width: 20%;
        border-radius: 68px;
        background-color: #3E4142;
        box-shadow: 0px 8px 9px 0px #0000004D, 3px -5px 18px 0px #0000004A inset;
        margin: 0px 20px;
    }

    .container-svg-polygon {
        background-size: 100%;
        width: 100%;
        height: 80%;
        transition: 300ms ease;
    }

    .container-sbinfo:hover .container-svg-polygon {
        transition: 300ms ease;
        position: relative;
        top: 63px;
        width: 100%;
        height: 73%;
        background-size: 100%;
        margin-left: 0;
    }

    .container-sbinfo:hover h2 {
        top: -132px;
        transition: 300ms ease;
    }

    .container-sbinfo:hover p {
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        width: 80%;
        margin-left: 10%;
    }

    .container-svg-polygon svg {
        height: auto;
        width: 50%;
    }

    .container-sbinfo:hover .dbHead {
        font-size: 15px;
    }

    .container-sbinfo h2 {
        font-size: 15px;
        line-height: 26px;
        margin-top: 12px;
    }

    .springboot-info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    .springboot-info-container {
        width: 75%;
        height: 75%;
        z-index: 1;
    }

    .container-sbinfo:hover .http,
    .container-sbinfo:hover .db {
        top: 72px !important;
    }

    .springboot-info-container {
        width: 94%;
    }

    .sbsvg3 {
        width: 10%;
        height: auto;
    }

    .sbsvg2 {
        width: 8%;
        height: auto;
    }

    .sbsvg7 {
        width: 2%;
        height: auto;
    }

}


@media (min-width: 1081px) and (max-width: 1366px) {
    .sbinfo {
        height: 96%;
        width: 20%;
        border-radius: 68px;
        background-color: #3E4142;
        box-shadow: 0px 8px 9px 0px #0000004D, 3px -5px 18px 0px #0000004A inset;
        margin: 0px 20px;
    }

    .container-svg-polygon {
        background-size: 100%;
        width: 100%;
        height: 80%;
        transition: 300ms ease;
    }

    .container-sbinfo:hover .container-svg-polygon {
        transition: 300ms ease;
        position: relative;
        top: 63px;
        width: 100%;
        height: 73%;
        background-size: 100%;
        margin-left: 0;
    }

    .container-sbinfo:hover h2 {
        top: -159px;
        transition: 300ms ease;
    }

    .container-sbinfo:hover p {
        font-size: 10px;
        line-height: 20px;
        text-align: center;
        width: 80%;
        margin-left: 10%;
    }

    .container-svg-polygon svg {
        height: auto;
        width: 50%;
    }

    .container-sbinfo:hover .dbHead {
        font-size: 15px;
    }

    .container-sbinfo h2 {
        font-size: 15px;
        line-height: 26px;
        margin-top: 12px;
    }

    .springboot-info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: nowrap;
    }

    .springboot-info-container {
        width: 75%;
        height: 75%;
        z-index: 1;
    }

    .container-sbinfo:hover .http,
    .container-sbinfo:hover .db {
        top: 72px !important;
    }

    .container-sbinfo:hover .dbpara {
        margin-top: 47px !important;
    }

    .container-sbinfo:hover .listp {
        margin-top: 34px !important;
    }

}

@media (min-width: 1366px) and (max-width: 1600px) {
    .container-sbinfo:hover .dbpara {
        margin-top: 47px !important;
    }

    .container-sbinfo:hover .listp {
        margin-top: 34px !important;
    }

    .container-sbinfo:hover .dbHead {
        font-size: 19px;
        line-height: 31px;
        margin-top: 2px;
    }

    .dbHead {
        font-size: 19px;
        line-height: 31px;
        margin-top: 2px;
    }

    .container-sbinfo:hover .http,
    .container-sbinfo:hover .db {
        top: 74px !important;
    }

    .container-sbinfo h2 {
        font-size: 22px;
        line-height: 31px;
        text-align: center;
    }

    .container-sbinfo:hover .dbpara {
        margin-top: 20px !important;
    }
}

/* Responsive for Ipad Mini, Ipad air */
@media (min-width: 820px) and (max-width: 1080px) {

    .springboot-container,
    .springboot-svg {
        display: none;
    }

    .tech-springboot {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 10px;
    }

    .springboot-page-for-mobile {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #spring-mobile-card {
        height: 80%;
        width: 100%;
    }

    #spring-card-4,
    #spring-card-1,
    #spring-card-3,
    #spring-card-2 {
        height: 30%;
    }
}

@media (min-width: 768px) and (max-width: 819px) {

    .springboot-container,
    .springboot-svg {
        display: none;
    }

    .tech-springboot {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 10px;
    }

    .springboot-page-for-mobile {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #spring-mobile-card {
        height: 80%;
        width: 100%;
    }

    #spring-card-4,
    #spring-card-1,
    #spring-card-3,
    #spring-card-2 {
        height: 30%;
    }
}

/* Responsive for Mobile */
@media screen and (max-width: 767px) {

    .springboot-container,
    .springboot-svg {
        display: none;
    }

    .tech-springboot {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 10px;
    }

    .springboot-page-for-mobile {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    #spring-mobile-card {
        height: 80%;
        width: 100%;
    }

    #spring-card-4,
    #spring-card-1,
    #spring-card-3,
    #spring-card-2 {
        height: 30%;
    }
}