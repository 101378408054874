.tech-react {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    background-color: #272d2f;
}

.tech-react-conatiner {
    width: 100%;
    padding-top: 5%;
    height: 83.7%;
    color: #ffffff;
    position: relative;
}

.nodejs-page-for-mobile {
    display: none;
}

.react-page-for-mobile {
    display: none;
}

.tech-react-text {
    width: 100%;
    height: 100%;
    position: relative;
}

.tech-react-heading h1 {
    margin: 0;
    font-family: 'Montserrat';
    font-size: 61px;
    line-height: 74.36px;
    text-align: left;
    padding-left: 5%;
}

.tech-react-info {
    width: 50%;
    height: 50%;
    margin-left: 25%;
    position: relative;
    display: block;
}

.line-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.linebox-first {
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.linebox-first {
    height: 90px;
    width: 910px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.linebox-second {
    height: 60%;
    width: 911px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.box-upper {
    height: 100%;
    width: 40%;
    border: 0px solid;
    border-left: 2px solid;
    border-bottom: 2px solid;
    border-style: dashed;
}

.box-lower {
    width: 242px;
    border: 0px solid;
    border-right: 2px dashed;
}

.up-rem {
    border-left: 0px dashed;
}

.lo-rem {
    border-left: 2px dashed;
}

.react-info-logo {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.upper-react {
    height: 124.5px;
    width: 124.5px;
    position: absolute;
    left: 393.5px;
    top: -55px;
}

.lower-react {
    height: 104.36px;
    width: 104.36px;
    background-color: #666666;
    position: absolute;
    /* display: flex; */
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: 400ms ease-in-out;
    z-index: 999;
}

.speed {
    left: 37px;
    bottom: 0%;

}

.performance {
    bottom: 0;
    left: 282px;
}

.seoFriendly {
    bottom: 0;
    left: 526px;
}

.reusability {
    left: 768px;
    bottom: 0;
}

.react-svg-container {
    display: inline-block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;
}

.rsvg1 {
    left: 4%;
    position: absolute;
    display: inline-block;
    top: 42%;
}

.rsvg2 {
    left: 6%;
    position: absolute;
    display: inline-block;
    top: 80%;
}

.rsvg3 {
    left: 20%;
    position: absolute;
    display: inline-block;
    top: 85%;
}

.rsvg4 {
    left: 50%;
    position: absolute;
    display: inline-block;
    top: 80%;
}

.rsvg5 {
    right: 3%;
    position: absolute;
    display: inline-block;
    top: 20%;
}

.rsvg6 {
    right: 0%;
    position: absolute;
    display: inline-block;
    top: 0%;
}

.speed:hover {
    left: -44px;
    transition: 400ms ease-in-out;
    height: 282px;
    width: 265px;
    border-radius: 50px;
    bottom: -43.9%;
}

.seoFriendly:hover {
    left: 446px;
    transition: 400ms ease-in-out;
    height: 282px;
    width: 265px;
    border-radius: 50px;
    bottom: -43.9%;
}

.reusability:hover {
    left: 688px;
    transition: 400ms ease-in-out;
    height: 282px;
    width: 265px;
    border-radius: 50px;
    bottom: -43.9%;
}

.performance:hover {
    left: 201px;
    transition: 400ms ease-in-out;
    height: 282px;
    width: 265px;
    border-radius: 50px;
    bottom: -43.9%;
}

.lower-react p {
    opacity: 0;
    transition: 100ms ease-in-out;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;

}

.lower-react:hover p {
    transition: 2000ms ease-in-out;
    opacity: 10;
}

.lower-react svg {
    margin-top: 20px;
}

.lower-react h1 {
    margin-top: 20px;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    position: absolute;
    left: -36px;
    width: 172%;
}

.lower-react:hover h1 {
    position: unset;
    left: -36px;
    width: auto;
    transition: 100ms ease-in-out;
}

p.react-description {
    width: 536px;
    text-align: left;
    margin-left: 5%;
    font-size: 20px;
    margin-top: 5px;
}

/* @media (min-width: 769px) and (max-width: 1080px) {

    .tech-react-info {
        height: 60%;
    }
    .tech-react-info {
  
        margin-left: 12%;
        margin-top: 3%;
    }
    .lower-react svg {
        margin-top: 20px;
        width: 40px;
        height: auto;
    }
    .lower-react {
        width: 90px;
        height: 90px;
    }
    .line-box {
        height: 60%;
    }
    .speed {
        left: 26px;
        bottom: 29%;
    }
    .performance {
        bottom: 29%;
        left: 237px;
    }
    .seoFriendly {
        bottom: 29%;
        left: 440px;
    }
    .reusability {
        left: 645px;
        bottom: 29%;
    }
    .lower-react h1 {
        font-size: 17px;
    }
    .speed:hover,.reusability:hover,.performance:hover, .seoFriendly:hover  {
        bottom: -20%;
        height: 233px;
        width: 210px;
    }
    .lower-react p {
        font-size: 10px;
       
    }
    .box-lower {
        width: 203px;
    }
    .linebox-second {
        width: 766px;
    }
    .linebox-first {
        height: 90px;
        width: 766px;
    }
    .upper-react svg{
        width: 75px;
        height: auto;
    }
    .upper-react {
        left: 321.5px;
    }
    .lower-react:hover p{
        transition: 2000ms ease-in-out;
        opacity: 10; 
    }
} */


@media (min-width: 1081px) and (max-width: 1366px) {

    .tech-react-info {
        height: 60%;
    }

    .tech-react-info {

        margin-left: 12%;
        margin-top: 3%;
    }

    .lower-react svg {
        margin-top: 20px;
        width: 40px;
        height: auto;
    }

    .lower-react {
        width: 90px;
        height: 90px;
    }

    .line-box {
        height: 60%;
    }

    .speed {
        left: 40px;
        bottom: 29%;
    }

    .performance {
        bottom: 29%;
        left: 286px;
    }

    .seoFriendly {
        bottom: 29%;
        left: 526px;
    }

    .reusability {
        left: 778px;
        bottom: 29%;
    }

    .lower-react h1 {
        font-size: 17px;
    }

    .speed:hover,
    .reusability:hover,
    .performance:hover,
    .seoFriendly:hover {
        bottom: -20%;
        height: 233px;
        width: 210px;
    }

    .lower-react p {
        font-size: 10px;

    }

    .seoFriendly:hover {
        left: 473px;
    }

    .performance:hover {
        left: 229px
    }

    .reusability:hover {
        left: 719px;
    }

    .speed:hover {
        left: -15px;
    }

    .lower-react:hover p {
        transition: 2000ms ease-in-out;
        opacity: 10;
    }

    .lower-react h1 {
        margin-top: 30px;
    }

}

/* Responsive for Ipad pro, ipad mini */
@media (min-width: 820px) and (max-width: 1080px) {
    .nodejs-page-for-mobile {
        display: block;
    }

    .tech-react {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: flex-start;
        box-sizing: border-box;
        overflow: hidden;

    }

    .tech-react-conatiner {
        display: none;
    }


    .react-page-for-mobile {
        box-sizing: border-box;
        padding-top: 80px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .react-mobile-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10%;
        width: 100%;
        color: #fff;
        font-size: 20px;

    }

    .react-mobile-cards {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding-top: 20px;
        gap: 20px;
        padding-bottom: 10px;
    }

    .react-mobile-card-1 {
        height: 25%;
        width: 90%;
        background-color: #666666;
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        margin: 0;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 20px;

    }

    .react-mobile-card-1-right>p {
        margin-left: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .react-mobile-card-1-right {
        height: 100%;
        /* background-color: rgb(130, 238, 152); */
        width: 70%;
        display: flex;
    }

    .react-mobile-card-1-left {
        width: 30%;
        height: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        color: #FFF;
        font-size: 12px;

    }

    .react-mobile-heading {

        justify-content: center;
        align-items: flex-end;
        margin-top: 50px;
    }

    .react-mobile-card-1-left>svg {
        height: 100px;
    }

    .react-mobile-card-1-right {
        width: 80%;
        height: 100%;
        /* background-color: wheat; */
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        text-align: left;
        color: #fff;
    }

    .react-mobile-card-1-left h3 {
        font-size: 32px;
        width: 100%;

    }

    .react-mobile-card-1-right>p {
        font-size: 35px;
    }
}

@media (min-width: 768px) and (max-width: 819px) {
    .nodejs-page-for-mobile {
        display: block;
    }

    .tech-react {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: flex-start;
        box-sizing: border-box;
        overflow: hidden;

    }

    .tech-react-conatiner {
        display: none;
    }


    .react-page-for-mobile {
        box-sizing: border-box;
        padding-top: 80px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .react-mobile-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10%;
        width: 100%;
        color: #fff;
        font-size: 20px;

    }

    .react-mobile-cards {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding-top: 20px;
        gap: 20px;
        padding-bottom: 10px;
    }

    .react-mobile-card-1 {
        height: 25%;
        width: 90%;
        background-color: #666666;
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        margin: 0;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 20px;

    }

    .react-mobile-card-1-right>p {
        margin-left: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .react-mobile-card-1-right {
        height: 100%;
        /* background-color: rgb(130, 238, 152); */
        width: 70%;
        display: flex;
    }

    .react-mobile-card-1-left {
        width: 30%;
        height: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        color: #FFF;
        font-size: 12px;

    }

    .react-mobile-heading {

        justify-content: center;
        align-items: flex-end;
        margin-top: 50px;
    }

    .react-mobile-card-1-left>svg {
        height: 75px;
    }

    .react-mobile-card-1-right {
        width: 80%;
        height: 100%;
        /* background-color: wheat; */
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        text-align: left;
        color: #fff;
    }

    .react-mobile-card-1-left h3 {
        font-size: 20px;
        width: 100%;

    }

    .react-mobile-card-1-right>p {
        font-size: 25px;
    }
}

/* Responsive for mobile */
@media screen and (max-width: 767px) {
    .nodejs-page-for-mobile {
        display: block;
    }

    .tech-react {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: flex-start;
        box-sizing: border-box;
        overflow: hidden;

    }

    .tech-react-conatiner {
        display: none;
    }


    .react-page-for-mobile {
        box-sizing: border-box;
        padding-top: 80px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
    }

    .react-mobile-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10%;
        width: 100%;
        color: #fff;
        font-size: 15px;

    }

    .react-mobile-cards {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding-top: 20px;
        gap: 20px;
        padding-bottom: 10px;
    }

    .react-mobile-card-1 {
        height: 25%;
        width: 90%;
        background-color: #666666;
        display: flex;
        flex-direction: row;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        margin: 0;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 20px;

    }

    .react-mobile-card-1-right>p {
        margin-left: 10px;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .react-mobile-card-1-right {
        height: 100%;
        /* background-color: rgb(130, 238, 152); */
        width: 70%;
        display: flex;
    }

    .react-mobile-card-1-left {
        width: 30%;
        height: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        color: #FFF;
        font-size: 12px;

    }

    .react-mobile-heading {

        justify-content: center;
        align-items: flex-end;
        margin-top: 50px;
    }

    .react-mobile-card-1-left>svg {
        height: 60px;
    }

    .react-mobile-card-1-right {
        width: 80%;
        height: 100%;
        /* background-color: wheat; */
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        text-align: left;
        color: #fff;
    }

    .react-mobile-card-1-left h3 {
        font-size: 13px;
        width: 100%;

    }

    .react-mobile-card-1-right>p {
        font-size: 15px;
    }
}