@media only screen and (max-width: 1536px) {
    .vector-corner1 {
        position: absolute;
        left: 0%;
        bottom: -16px;
    }

    .vector-corner1 svg {
        width: 105px;
    }

    .vector-corner-up {
        position: absolute;
        left: 0;
        bottom: 92px;
        display: inline-block;
    }

    .vector-corner-up svg {
        width: 105px;
    }

    .vector-corner-up-side {
        position: absolute;
        left: 106px;
        bottom: 92px;
        display: inline-block;
    }

    .vector-corner-up-side svg {
        width: 105px;
    }

    .vector-corner-cone {
        right: 151px;
        bottom: -15px;
    }

    .vector-corner-cone svg {
        width: 75px;
    }

    .vector-corner-round {
        position: absolute;
        display: inline-block;
        right: 75px;
        bottom: -15px;
    }

    .vector-corner-round svg {
        width: 75px;
    }

    .vector-corner2 {
        bottom: -15px;
    }

    .vector-corner2 svg {
        width: 75px;
    }

    .corner-faint-cone {
        bottom: 58px;
    }

    .corner-faint-cone svg {
        width: 63px;
    }

    .corner-square-vector {
        bottom: 58px;
        right: 62px;
    }

    .corner-square-vector svg {
        width: 105px;
    }

    .aside-square-vector {
        bottom: 58px;
        right: 165px;
    }

    .aside-square-vector svg {
        width: 106px;
    }

    .svg2 {
        top: 45%;
    }

    .svg2 svg {
        height: auto;
        width: 62px;
    }

    .svg1 {
        top: 18.5%;
        left: 21%;
    }

    .svg3 {
        bottom: 25%;
        left: 28.5%;
    }

    .vector-arrow {
        bottom: -48%;
    }

    .svg4 {
        bottom: 16%;
    }

    .svg5 {
        top: 21%;
        right: 22%;
    }

    .svg5 svg {
        height: auto;
        width: 56px;
    }

}

@media only screen and (max-width: 1366px) {

    .vector-arrow {
        bottom: -41%;
    }

    .svg4 {
        bottom: 19%;
    }

    .svg5 {
        top: 19%;
        right: 19%;
    }

    .svg5 svg {
        height: auto;
        width: 56px;
    }

    .text-containt h1 {
        font-size: 77px;
    }

    .text-containt h1 span {
        font-size: 80px;
    }

    #h3 {
        font-size: 21px;
    }

    .connect {
        width: 215px;
        height: 46.6px;
    }

    .connect p {
        font-size: 21px;
    }
}
/* Tablet Pro and air */
@media (min-width: 820px) and (max-width: 1080px) {
    .animation-container{
        height: 100%;
        width: 100%;
    }
    .text-containt {
        width: 100%;
        height: 70%;
        gap: 0px;
    }
    .text-containt h1 {
        font-size: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0;
    }
    #text-content-span {
        font-family: "Oleo Script";
        color: #ff8402;
        font-size: 70px;
        font-weight: 700;
        display: inline-block;
        width: 100%;
    }
    #h3 {
        width: 95%;
        line-height: 72px;
        font-size: 40px !important;
        padding: 10px;
    }

    .svg1, .svg2, .svg3, .svg4, .svg5 ,.tsvg7, .vector-corner-up, .vector-arrow, .vector-corner1, .aside-square-vector, .vector-corner-up-side, .corner-square-vector,.corner-faint-cone, .vector-corner-cone, .vector-corner-round, .vector-corner2{
        display:none;
    }
    .connect {
        width: 335px;
        height: 75px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .connect p {
        font-size: 35px;
        margin-top: 0;

    }

}

/* Tablet i pad mini */
@media (min-width: 768px) and (max-width:819px){
    .animation-container{
        height: 100%;
        width: 100%;
    }
    .text-containt {
        width: 100%;
        height: 62%;
        gap: 0px;
    }
    .text-containt h1 {
        font-size: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0;
    }
    #text-content-span {
        font-family: "Oleo Script";
        color: #ff8402;
        font-size: 60px;
        font-weight: 700;
        display: inline-block;
        width: 100%;
    }
    #h3 {
        width: 100%;
        line-height: 65px;
        font-size: 32px !important;
        padding: 10px;
    }

    .svg1, .svg2, .svg3, .svg4, .svg5 ,.tsvg7, .vector-corner-up, .vector-arrow, .vector-corner1, .aside-square-vector, .vector-corner-up-side, .corner-square-vector,.corner-faint-cone, .vector-corner-cone, .vector-corner-round, .vector-corner2{
        display:none;
    }
    .connect {
        width: 327px;
        height: 74px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .connect p {
        font-size: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        margin-top: 0;
    }

}

/* Responsive for Mobile */
@media screen and (max-width:767px){
    .animation-container{
        height: 100%;
        width: 100%;
    }
    .text-containt{
        width: 100%;
        height: 50%;
        gap: 50px;
    }
    .text-containt h1 {
        font-size: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0;
    }
    #text-content-span {
        font-family: "Oleo Script";
        color: #ff8402;
        font-size: 35px;
        font-weight: 700;
        display: inline-block;
        width: 100%;
    }
    #h3 {
        width: 100%;
        line-height: 25px;
        font-size: 20px !important;
        padding: 10px;
        
    }

    .tsvg7, .vector-corner-up, .vector-arrow, .vector-corner1, .aside-square-vector, .vector-corner-up-side, .corner-square-vector,.corner-faint-cone, .vector-corner-cone, .vector-corner-round, .vector-corner2{
        display:none;
    }

}
