
.BottomContact-main-container {
    height: 36%;
    width: 100%;
    background-color: #272D2F;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding-left: 20px;
    justify-content: space-between;
    position: relative;
    box-shadow: 0px -11px 21px 0px #0000007d;
    flex-wrap: nowrap;
    color: #ffffff;
}

.bottomcontact-left-container {
    height: 100%;
    width: 60%;
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    /* padding: 1%; */
}


.bottom-left-content {
    height: 100%;
    width: 100%;
    /* background-color: rgb(53, 141, 141); */
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.bottom-text {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    box-sizing: border-box;
    font-size: 34px;
    font-weight: 600;
    font-family: "Poppins";
    margin-top: 10px;
}

.bottomcontact-button{
    height: 40%;
    width: 100%;
    /* background-color: aquamarine; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
}

.bottomcontact-button > button{
    font-size: 40px;
    font-family: "Montserrat";
    color: #272D2F;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 700;
}

.bottom-contact-left-logo {
    height: 150px;
    width: 100%;
    box-sizing: border-box;
    /* padding: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 15px;
}

.bottomcontact-logo {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    margin-top: 1%;
}

.contact-bottom-copyright {
    height: 40px;
    width: 100%;
    /* background-color: aquamarine; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    /* padding: 15px; */
    font-weight: 400;
    font-family: "Poppin";
    font-size: 22px;
    text-align: left;
}

.bottomcontact-right-container {
    height: 100%;
    width: 45%;
    /* background-color: rgb(119, 72, 11); */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    /* justify-content: space-between; */
    /* box-shadow: 11px 11px 21px 11px #2222227d; */

    
}

.bottomcontact-social-media-logos {
    height: 100%;
    width: 10%;
    box-sizing: border-box;
    padding-top: 3%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
}

.bottomcontact-social-media-logos  img{
    cursor: pointer;
    width: 26px;
    height: 27px;
}

.bottom-contact-rightcontainer-right {
    height: 100%;
    width: 100%;
    border-top-left-radius: 100px;
    box-sizing: border-box;
    box-shadow: 0px 0px 40px 0px #1c1c1c7d;
}

.bottomcontact-info {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;
}

.h{
    height: 20%;
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-family: "Poppins";
    font-weight: 600;
}

.mobile-number {
    height: 60px;
    width: 82%;
    margin-top: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    margin-left: 0%;
}

.mobile-number > p{
    margin-left: 20px;
}

.adress {
    width: 84%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-left: 0%;
    margin-top: 2%;
}
.adress > p{
    text-align: left;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 400;
}

.bottomcontact-mail {
    height: 60px;
    width: 84%;
    margin-top: 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    margin-left: 0;
}
.bottomcontact-mail  a{
    text-align: left;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
}

.bottomcontact-mail a:hover{
    color: rgb(70, 70, 233);
}

.bottomcontact-mail > img{
    cursor: pointer;
}


#adress-icon{
    cursor: pointer;
}

#adress-icon:hover{
    fill: lightblue;
}
.bottomcontact-social-media-logos #youtube{
    width: 30px;
    height: 24px;
}



/* Reponsive for Laptop */
@media only screen and (max-width: 1537px) {
    .BottomContact-main-container {
        height: 42%;
        overflow-y: hidden;
    }
}

/* Responsive for Apple Laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)) {
    .bottom-text {
        font-size: 31px;
    }
    .h {
        font-size: 35px;
    }
    .mobile-number > p {
        font-size: 15px;
    }
    #mail {
        font-size: 15px;
    }

    .adress > p {
        font-size: 15px;
    }
}

/* Tablets */
/* @media (min-width: 601px) and (max-width: 768px){
    .BottomContact-main-container slide{
        height: 100vh !important;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
} */

/* @media (min-width: 769px) and (max-width: 1080px) {
    .bottom-text,.h {
        font-size: 25px;
    }
    .mobile-number {
        font-size: 14px;
    }
    .bottomcontact-mail  a {
        font-size: 14px;
        margin-left: 13px;
    }
    .adress > p {
        font-size: 14px;
        margin-left: 13px;
    }
    .bottomcontact-logo img{
        height: auto;
        width: 150px;
    }
    .bottomcontact-logo {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        margin-top: 5%;
    }
    .contact-bottom-copyright {
        font-size: 20px;
    }
    .adress img{
        width: 18px;
        height: auto;
    }
    #mailimg{
        width: 18px;
        height: auto;
    }
    .bottomcontact-mail {
        margin-left: 18%;
    }
    #phone {
        width: 18px;
        height: auto;
    }
    .mobile-number > p {
        margin-left: 13px;
    }
    .bottomcontact-social-media-logos  img {
        cursor: pointer;
        width: 22px;
        height: auto;
    }
    .bottomcontact-social-media-logos #youtube {
        width: 22px;
        height: auto;
    }
    .bottomcontact-logo {
        margin-top: 0%;
    }
} */

/*laptop*/
@media (min-width: 1081px) and (max-width: 1366px) {
    .bottom-text,.h {
        font-size: 25px;
    }
    .mobile-number {
        font-size: 17px;
    }
    .bottomcontact-mail  a {
        font-size: 17px;
    }
    .adress > p {
        font-size: 16px;
    }
    .bottomcontact-logo img{
        height: auto;
        width: 150px;
    }
    .bottomcontact-logo {
        height: 60px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        margin-top: 5%;
    }
    .contact-bottom-copyright {
        font-size: 20px;
    }

}

/* Responsive for Tablets */
/* @media screen and (max-width: 1024px){
    .BottomContact-main-container{
        height: 92% !important;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0px;
        flex-direction: column;
        margin: 0;
        padding-left: 0;
        padding: 0;
        overflow: hidden;
      }
  
      .bottomcontact-left-container{
          width: 100%;
          height: 50%;
      }
      .bottom-left-content{
          width: 100%;
          height: 100%;
      }
      .bottom-text{
          font-size: 56px;
      }
      .contact-bottom-copyright{
          font-size: 35px;
      }
      .bottomcontact-logo img {
        height: 74px;
        width: 270px;
    }
      .bottomcontact-right-container{
        height: 55%;
        width: 100%;
        flex-direction: column;
      }

      .bottomcontact-social-media-logos {
        flex-direction: row;
        width: 100%;
        height: 30%;
    }
    .bottomcontact-social-media-logos  img {
        cursor: pointer;
        width: 65px;
        height: auto;
    }
    .bottomcontact-social-media-logos #youtube {
        width: 65px;
        height: 65px;
    }
    .h{
        font-size: 60px;
    }
    .mobile-number {
        font-size: 35px;
        gap: 50px;
        height: 100px;
    }
    #phone{
        height: 40px;
        width: 35px;
    }

    .bottomcontact-mail{
        font-size: 35px;
        gap: 50px;
        height: 100px;
    }
    .bottomcontact-mail a{
        font-size: 35px;
    }

    #mailimg{
        height: 35px;
        width: 35px;
    }

    .adress{
        gap: 50px;
    }

    .adress > p{
        font-size: 35px;   
    }

    .adress img {
        height: 35px;
        width: 35px;
    }
} */

/* Responsive for ipad pro, ipad air, ipad mini */
@media (min-width: 820px) and (max-width: 1080px){
    .BottomContact-main-container{
        height: 100% !important;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0px;
        flex-direction: column;
        margin: 0;
        padding-left: 0;
        padding: 0;
        overflow: hidden;
      }
  
      .bottomcontact-left-container{
          width: 100%;
          height: 50%;
      }
      .bottom-left-content{
          width: 100%;
          height: 100%;
      }
      .bottom-text{
          font-size: 50px;
      }
      .contact-bottom-copyright {
        font-size: 33px;
         margin-top: 6%;
          height:55px;
      }
  
      .bottomcontact-right-container{
        height: 55%;
        width: 100%;
        flex-direction: column;
      }
      .bottomcontact-social-media-logos {
          flex-direction: row;
          width: 100%;
          height: 20%;
      }

      .bottomcontact-logo img {
        height: 111px;
        width: 301px;
}
      .bottomcontact-social-media-logos #youtube {
          width: 40px;
          height: 32px;
      }
      .h{
          font-size: 50px;
      }
      .bottomcontact-social-media-logos  img{
        width: 60px;
        height: 60px;
    }
    .bottomcontact-social-media-logos #youtube {
        width: 60px;
        height: 60px;
    }
      .mobile-number {
          font-size: 35px;
          gap: 00px;
          height: 50px;
      }
      #phone {
          height: 35px;
          width: 25px;
      }
      .bottomcontact-mail {
          gap: 0;
          height: 50px;
          margin-left: 0%;
    
      }
      .bottomcontact-mail a {
        font-size: 35px;
        margin-left: 29px;
    }
  
      #mailimg {
        height: 35px;
        width: 40px;

      }
  
      .adress > p {
          font-size: 35px !important;
          margin-left: 25px;
          font-family: 'Poppins';
          width: auto;
      }
      .adress {
          gap: 0;
      }
      .adress img {
        width: 35px;
        height: auto;
    }
    .bottomcontact-info {
   justify-content: space-around;
    }

}

@media (min-width: 768px) and (max-width: 819px){
    .BottomContact-main-container{
        height: 100% !important;
        width: 100%;
        box-sizing: border-box;
        padding-top: 0px;
        flex-direction: column;
        margin: 0;
        padding-left: 0;
        padding: 0;
        overflow: hidden;
      }
  
      .bottomcontact-left-container{
          width: 100%;
          height: 50%;
      }
      .bottom-left-content{
          width: 100%;
          height: 100%;
      }
      .bottom-text{
          font-size: 50px;
      }
      .contact-bottom-copyright {
        font-size: 33px;
         margin-top: 6%;
          height:55px;
      }
  
      .bottomcontact-right-container{
        height: 55%;
        width: 100%;
        flex-direction: column;
      }
      .bottomcontact-social-media-logos {
          flex-direction: row;
          width: 100%;
          height: 20%;
      }
    
      .bottomcontact-logo img {
        height: 111px;
        width: 301px;
    }
      .bottomcontact-social-media-logos #youtube {
          width: 40px;
          height: 32px;
      }
      .h{
          font-size: 50px;
      }
      .bottomcontact-social-media-logos  img {
        width: 45px;
        height: 45px;
    }
    .bottomcontact-social-media-logos #youtube {
        width: 45px;
        height: 45px;
    }
      .mobile-number {
          font-size: 35px;
          gap: 00px;
          height: 50px;
      }
      #phone {
          height: 35px;
          width: 25px;
      }
      .bottomcontact-mail {
          gap: 0;
          height: 50px;
          margin-left: 0%;
    
      }
      .bottomcontact-mail a {
        font-size: 35px;
        margin-left: 29px;
    }
  
      #mailimg {
        height: 35px;
        width: 40px;

      }
  
      .adress > p {
          font-size: 35px !important;
          margin-left: 25px;
          font-family: 'Poppins';
          width: auto;
      }
      .adress {
          gap: 0;
      }
      .adress img {
        width: 35px;
        height: auto;
    }
    .bottomcontact-info {
   justify-content: space-around;
    }
}
/* Responsive for mobile */
@media screen and (max-width: 767px){
    .BottomContact-main-container{
      height: 92% !important;
      width: 100%;
      box-sizing: border-box;
      padding-top: 0px;
      flex-direction: column;
      margin: 0;
      padding-left: 0;
      padding: 0;
      overflow: hidden;
    }

    .bottomcontact-left-container{
        width: 100%;
        height: 50%;
    }
    .bottom-left-content{
        width: 100%;
        height: 100%;
    }
    .bottom-text{
        font-size: 28px;
    }
    .contact-bottom-copyright{
        font-size: 20px;
    }
    .bottomcontact-logo img{
        height:55px;
    }

    .bottomcontact-right-container{
      height: 55%;
      width: 100%;
      flex-direction: column;
    }
    .bottomcontact-social-media-logos {
        flex-direction: row;
        width: 100%;
        height: 20%;
    }
    .bottomcontact-social-media-logos  img {
        width: 32px;    
    }
    .bottomcontact-logo img {
        height: 47px;
        width: 138px;
    }
    .bottomcontact-social-media-logos #youtube {
        width: 40px;
        height: 32px;
    }
    .h{
        font-size: 30px;
    }
    .mobile-number {
        font-size: 20px;
        gap: 00px;
        height: 50px;
    }
    #phone {
        height: 25px;
        width: 20px;
    }
    .bottomcontact-mail {
        gap: 0;
        height: 50px;
    }

    #mailimg {
        height: 25px;
        width: 20px;
    }
    .bottomcontact-mail a {
        font-size: 20px;
        margin-left: 29px;
    }

    .adress > p {
        font-size: 15px !important;
        margin-left: 25PX;
        font-family: 'Poppins';
        width: auto;
    }
    .adress {
        gap: 0;
    }

}
