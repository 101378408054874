
.Mobile-application-main-container{
    height: 100%;
    width: 100%;
    margin: 0;
    overflow-x: hidden;
}

.Mobile-application-firstpage-page{
    height: 100vh;
    width: 100%;
    background-color: #444647;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.Mobile-application-first-page-content-container{
    height: 85%;
    width: 90%;
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
}

.left-container-Mobile-image{
    
    height: 100%;
    width: 40%;
    /* background-color: aliceblue; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;

}

.left-container-Mobile-image > img {
    height: 75%;
    width: auto;
}

.right-container-Mobile-application {
    height: 80%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 70px;
    color: #fff;
}

.right-container-Mobile-application-heading {
    height: 17%;
    width: 98%;
    font-size: 60px;
    text-align: left;
    font-weight: 700;
    font-family: "Montserrat";
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20px;
}

.right-container-Mobile-application-para{
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    font-family: "Montserrat";
    width: 100%;
    /* background-color: aquamarine; */
    text-align: left;
    margin-top: 20px;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
}



.Mobile-application-second-page-content-container{
    height: 90%;
    width: 100%;
    /* background-color: rgb(195, 122, 28); */
    margin-top: 100px;
    display: flex;
    flex-direction: column;

}

.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    padding-left: 20px; 
    gap: 80px;
}


.card-content{
    height: 100%;
    width: 30%;
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    color: #fff;
    box-sizing: border-box;
}

.card-content > h1 {
    font-size: 110px;
    font-weight: 700;
    margin-bottom: 0px;
    width: 40%;
    box-sizing: border-box;
    color: #fff;


}
.cms-paraghraph{
    border: 0.84px solid;
    background: linear-gradient(107.89deg, rgba(255, 255, 255, 0) 12.23%, #FFFFFF 47.14%, rgba(255, 255, 255, 0) 90.42%),
    linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25));
      
}

#card-content-h1{
    color: #FF8402;
}
#threeDot {
    right: 10%;
}

.mobile-application-second-apge{
    height: 100%;
    width: 100%;
    background-image: url('/public/images/MobileApplicationClick.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center; 
    align-items: center;
    box-sizing: border-box;
    padding-top: 80px;
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.mobile-application-second-apge-subcontainer{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.mobile-application-cms{
    height: 20%;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
        
}

.cms-paraghraph {
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    border: 1px solid rgb(168, 163, 163);
    border-radius: 20px;
    box-sizing: border-box;
    margin-right: 10PX;
    padding-left: 10PX;
    color: #ffffff;
    scale: 0;
    margin-left: 60px;
    background: linear-gradient(188deg, rgba(255, 255, 255, 0) 0, #FFFFFF 0.14%, rgba(255, 255, 255, 0) 91.42%), linear-gradient(181deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25));
    background: linear-gradient(107.89deg, rgba(255, 255, 255, 0) 12.23%, #ffffff73 47.14%, rgba(255, 255, 255, 0) 90.42%),
linear-gradient(0deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25));
}

.cms-card{
    width: 40%;
    background-color:#fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}
.cms-logo{
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.mobile-cms-card-2{
    box-sizing: border-box;
    padding-right: 2%;
}

.cms-heading{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #444647;
    font-size: 30px;
    height: 100%;
    width: 50%;
}
 
  
  /* Laptops and Desktops */
  @media (min-width: 1081px) and (max-width: 1366px) {
    .left-container-Mobile-image > img{
        height: 80%;
    }

    .right-container-Mobile-application {
        height: 75%;
        display: flex;
        justify-content: space-evenly;
        padding-left: 0;
    }

    .right-container-Mobile-application-heading{
        width: 100%;
        font-size: 40px;
    }

    .right-container-Mobile-application-para > p {
        font-size: 19px;
    }

    .cards{
        padding: 0;
        box-sizing: border-box;
    }

    .cards > .card-content {
        width: 50%;
        height: 100%;
    }

    .cards > .card-content > h1{
        font-size: 60px;
    }

    .card-content{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 100%;
    }

    .WenDevelopmentClickScroll-main-container{
        height: 100%;
    }

    .Mobile-application-secondpage-container{
        height: 100%;
        width: 100%;
    }
    #Web-first-page-image {
        height: 50%;
        width: auto;
    }
    #white-Vector {
        position: absolute;
        top: 35%;
        left: 3%;
    }
    #Vectorempty {
        position: absolute;
        left: 28%;
        top: 9%;
    }
  }

  @media (min-width: 1367px) and (max-width: 1600px) {
    #Web-first-page-image {
        height: auto;
        width: 100%;
    }

    #vector-color {
        right: 30%;
    }

    #Vectorempty {
        left: 25%;
    }

    #Vectoremptyrotate {
        left: 8%;
        bottom: 8%;
        transform: rotate(0deg); 
    }

    #white-Vector {
        top: 30%;
        left: 5%;
    }

    #VectorsmallWhite {
        left: 40%;
        bottom: 15%;
    }

    #threeDot {
        position: absolute;
        bottom: 2%;
        right: 2%;
    }
    .right-container-Mobile-application {
        padding-left: 18px;
    }
    .right-container-Mobile-application-heading {
        width: 100%;
        margin-bottom: 10px;
        font-size: 55px;
    }
    .card-content > h1 {
        margin-top: 17px;
    }
    .right-container-Mobile-application-para {
        font-size: 20px;
        margin-top: 0px;
        padding-left: 20px;
    }
}


/* Responsive for ipad air, ipad pro */
@media (min-width: 820px) and (max-width: 1080px){
    .Mobile-application-main-container{
        height: 100%;
        width: 100%;
        padding-top: 0;
        box-sizing: border-box;
        overflow:hidden;
        padding-top: 50px;
        overflow: hidden;
    }     
    .Mobile-application-firstpage-page{
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .Mobile-application-first-page-content-container {
        width: 100%;
        height: 100%;
        flex-direction: column !important;
    }
    .left-container-Mobile-image {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-top: 0;
    }
    .left-container-Mobile-image > img {
        height: 110%;
        width: 54%;
    }
    .right-container-Mobile-application{
        height: 50%;
        width: 100%;
        padding: 0;
    }

    .right-container-Mobile-application-heading{
        height: 10%;
        font-size: 50px;
        width: 100%;
    }

    .right-container-Mobile-application-para{
        height: 50%;
        width: 100%;
        font-size: 27px;
        box-sizing: border-box;
        padding-right: 10px;
    }

    .cards{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 95% !important; 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0px;
        gap: 50px !important;
    
    }
    .card-content{
        height: 100%;
        width: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-content > h1 {
        font-size: 90px;
        width: 100% !important;
    }

    .card-content > h3 {
        font-size: 25px;
        text-align: left;
        width: 100%;
        text-align: center;
    }

    #vector-color, #Vectorempty, #Vectoremptyrotate, #white-Vector, #service-threeDot, #VectorsmallWhite{
        display: none;
    }

    #Chatbot-first-page-image{
        height: 130%;
    }

    #Web-first-page-image {
        height: 100%;
        /* width: 100%; */
    }

    #Webxr-first-page-image {
        width: 45% !important;
        height: auto;
    }
    #Service-Uiux-first-page-image {
        height: 100% !important; 
        width: 40% !important;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .Mobile-application-main-container{
        height: 100%;
        width: 100%;
        padding-top: 0;
        box-sizing: border-box;
        overflow:hidden;
        padding-top: 50px;
        overflow: hidden;
    }     
    .Mobile-application-firstpage-page{
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .Mobile-application-first-page-content-container {
        width: 100%;
        height: 100%;
        flex-direction: column !important;
    }
    .left-container-Mobile-image {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-top: 0;
    }
    .left-container-Mobile-image > img {
        height: 110%;
        width: 54%;
    }
    .right-container-Mobile-application{
        height: 50%;
        width: 100%;
        padding: 0;
    }

    .right-container-Mobile-application-heading{
        height: 10%;
        font-size: 50px;
        width: 100%;
    }

    .right-container-Mobile-application-para{
        height: 50%;
        width: 100%;
        font-size: 25px;
        box-sizing: border-box;
        padding-right: 10px;
    }

    .cards{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 95% !important; 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0px;
        gap: 50px !important;
    
    }
    .card-content{
        height: 100%;
        width: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-content > h1 {
        font-size: 90px;
        width: 100% !important;
    }

    .card-content > h3 {
        font-size: 25px;
        text-align: left;
        width: 100%;
        text-align: center;
    }

    #vector-color, #Vectorempty, #Vectoremptyrotate, #white-Vector, #service-threeDot, #VectorsmallWhite{
        display: none;
    }

    #Chatbot-first-page-image{
        height: 100%;
    }

    #Web-first-page-image {
        height: 100%;
        /* width: 100%; */
    }

    #Webxr-first-page-image {
        width: 45% !important;
        height: auto;
    }
    #Service-Uiux-first-page-image {
        height: 100% !important; 
        width: 40% !important;
    }
}

/* Responsive for Mobile */
@media screen and (max-width: 767px){
    .Mobile-application-main-container{
        height: 100%;
        width: 100%;
        padding-top: 0;
        box-sizing: border-box;
        overflow:hidden;
        padding-top: 50px;
        overflow: hidden;
    }     
    .Mobile-application-firstpage-page{
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .Mobile-application-first-page-content-container {
        width: 100%;
        height: 100%;
        flex-direction: column !important;
    }
    .left-container-Mobile-image {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        margin-top: 0;
    }
    .left-container-Mobile-image > img {
        height: 110%;
        width: 54%;
    }
    .right-container-Mobile-application{
        height: 50%;
        width: 100%;
        padding: 0;
    }

    .right-container-Mobile-application-heading{
        height: 10%;
        font-size: 27px;
        width: 100%;
    }

    .right-container-Mobile-application-para{
        height: 50%;
        width: 100%;
        font-size: 15px;
        box-sizing: border-box;
        padding-right: 10px;
    }

    .cards{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30%;
        width: 95% !important; 
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 0px;
        gap: 50px !important;
    
    }
    .card-content{
        height: 100%;
        width: 50% !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .card-content > h1 {
        font-size: 35px;
        width: 100% !important;
    }

    .card-content > h3 {
        font-size: 14px;
        text-align: left;
        width: 100%;
        text-align: center;
    }

    #vector-color, #Vectorempty, #Vectoremptyrotate, #white-Vector, #service-threeDot, #VectorsmallWhite{
        display: none;
    }

    #Chatbot-first-page-image{
        height: 100%;
    }
  }
  