
.ServieIotClick-main-container{
    height: 100%;
    width: 100%;
    margin: 0;
}

.ServiceIotClick-firstpage-page{
    height: 100vh;
    width: 100%;
    background-color: #444647;
    display: flex;
    justify-content: center;
    align-items: center;
}


.ServiceIotClick-first-page-content-container{
    height: 85%;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
}

.left-container-Seriviceiotclick-image{ 
    height: 100%;
    width: 40%;
    /* background-color: aliceblue; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;
}

.left-container-Seriviceiotclick-image > img {
    height: 98%;
}

.right-container-Seriviceiotclick{

    height: 100%;
    width: 50%;
    /* background-color: aliceblue; */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-left: 70px;
    color: #fff;

}

.right-container-Seriviceiotclick-heading{
    height: 17%;
    width: 50%;
    /* background-color: aqua; */
    font-size: 60px;
    text-align: left;
    font-weight: 700;
    font-family: "Montserrat";
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20px;
}

.right-container-Seriviceiotclick-para{
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    font-family: "Montserrat";
    width: 100%;
    /* background-color: aquamarine; */
    text-align: left;
    margin-top: 20px;
    padding-left: 20px;
    height: 40%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30%;
    width: 100%;
    /* background-color: rgb(161, 100, 9); */
    display: flex;
    justify-content: flex-start;
    align-items: center;   
    padding-left: 20px; 
    gap: 80px;
}


.card-content{
    height: 100%;
    width: 30%;
    /* background-color: blue; */
    font-family: "Montserrat";
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    color: #fff;
    box-sizing: border-box;
}

.card-content > h1 {
    font-weight: 700;
    margin-bottom: 0px;
    width: 40%;
    box-sizing: border-box;
    color: #fff;


}

#card-content-h1{
    color: #FF8402;
}

.Mobile-application-secondpage-container{
    height: 100vh;
    width: 100%;
    background-color: #0a6390;
    display: flex;
    justify-content: center;
    align-items: center;
}
#ServiceIot-first-page-image{
    height: 70%;
    width: auto;
}

.Iot-second-page{
    background-image: url('../../../../public/images/ServiceIot-second-page-image.png');
    background-repeat: no-repeat;
    background-size: cover
}

/* Responsive for Mobile */
@media  screen and (max-width: 768px){
    .right-container-iot-heading{
        margin-bottom: 25px;
    }
    .right-container-iot{
        padding-top: 0;
        height: 50%;
    }
    .right-container-iot-para{
        margin-bottom: 10px;
    }
    #ServiceIot-first-page-image {
        height: 90%;
        width: auto;
    }
}

