.tech-flutter {
    background-color: #272d2f;
    height: 100%;
    width: 100%;
}

.tech-flutter-container {

    width: 100%;
    height: 91%;
    padding-top: 4%;
    color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;

}

.tech-flutter-text-container {
    width: 52%;
    margin-left: 8%;
    text-align: left;

}
.tech-flutter-text-container {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
  }
  
  .tech-flutter-text-container.fade-out {
    opacity: 0;
  }

.tech-flutter-text{
    margin-top: 15%;
    margin-left: 10%;
}
.tech-flutter-text h1{
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 61px;
}
.tech-flutter-text h3 {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 5px;
}
.tech-flutter-text p{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 30px;
    width: 80%;
}

.flutter-info {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 7%;
    margin-bottom: 3%;
    z-index: 999;
}

.info-tab {
    width: 50%;
    /* height: 21%; */
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.techinfo-svg {
    background-color: #666666;
    height: 120px;
    width: 120px;
    border-radius: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-tab p {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 25px;
    line-height: 37.5px;
    margin-top: 8px;
}
.tech-svg-container{

    display: block;
    position: absolute;
    width: 100%;
    height: 91%;

}
svg.techsvg1 {
    left: 3%;
    display: inline-block;
    position: absolute;
    bottom: 65%;
}
svg.techsvg2 {
    display: inline-block;
    position: absolute;
    left: 5%;
    bottom: 15%;

}
svg.techsvg3 {
    display: inline-block;
    position: absolute;
    left: 12%;
    top: 0%;

}
svg.techsvg5{
    left: 26%;
    position: absolute;
    display: inline-block;
    bottom: 10%;
}
svg.techsvg6 {
    bottom: 20%;
    position: absolute;
    display: inline-block;
    right: 40%;
}
svg.techsvg7 {
    position: absolute;
    display: inline-block;
    right: 0;
    top: -78px;
}

.react-page-for-mobile {
    display: none;
}


.Flutter-mobiler-card {
    transition: 300ms ease;
    display: none;
}

#flutter-mobiler-card {
    transition: 300ms ease;
    position: absolute;
    left: 0;
    margin-left: 8%;
    margin-top: 2%;
    text-align: left;
    height: 60%;
}

.left-card-side h1 {
    font-family: Montserrat;
    font-size: 61px;
    font-weight: 700;
    line-height: 74px;
    letter-spacing: 0em;
    text-align: left;
}

.left-card-side h3 {
    font-family: Poppins;
    font-size: 40px;
    font-weight: 600;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 15px;

}
.left-card-side p {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 400;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left; 
    width: 578px;
    height: 158px;

}

.techinfo-svg:hover path{
    transition: 200ms ease-in;
    fill:#FF8402 ;
}

/*  Reponsive for the mobile */


/* Responsive for Tablets */
@media screen and ((max-width: 601px)and (min-height: 962px)) {
    
    .flutter-info-for-mobileresponsive{
        display: none;
    }

    .tech-flutter{
        height: 120%;
    }

    .tech-flutter-text > p{

        width: 100%;
        /* background-color: yellow; */
        box-sizing: border-box;
        padding: 20px;
    }

    .tech-flutter-container{
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .tech-flutter-text-container{
        width: 100%;
    }

    .flutter-info{
        height: 100%;
        width: 100%;
    }

    .tech-flutter-text-container{
        margin-left: 0;
        /* background-color: violet; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .tech-flutter-text{
        width: 100%;
        margin-left: 0;
        /* background-color: wheat; */
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

@media (min-width: 601px) and (max-width: 768px){
    .tech-flutter-text h1 {  
        font-size: 35px;
        margin-bottom: 15px;
    }
    .tech-flutter-text h3 {
        font-size: 25px;
    }
    .tech-flutter-text p {
        font-size: 15px;
    }
    .techinfo-svg {
        height: 73px;
        width: 73px;
        border-radius: 20px;
    }
    .techinfo-svg svg{
        height: auto;
        width: 40px;
    }
    .flutter-info {
        margin-left: 2%;
        margin-top: 7%;
    }
    svg.techsvg5 {
        bottom: 0%;
    }
    .techsvg4 {
      display: none;
    }
    svg.techsvg2 {
        width: 24px;
        height: auto;
    }
    svg.techsvg7 {
        display: none;
    }
    .tech-flutter-text {
        margin-top: 30%;
    }
    .info-tab p {
        font-size: 12px;
    }
}

@media (min-width: 769px) and (max-width: 1080px) {

    .tech-flutter-text h1 {  
        font-size: 35px;
        margin-bottom: 15px;
    }
    .tech-flutter-text h3 {
        font-size: 25px;
    }
    .tech-flutter-text p {
        font-size: 15px;
    }
    .techinfo-svg {
        height: 90px;
        width: 90px;
    }
    .techinfo-svg svg{
        height: auto;
        width: 40px;
    }
    .info-tab p {
        font-size: 15px;
    }
    .flutter-info {
        margin-top: 4%;
    }
    svg.techsvg5 {
        bottom: 0%;
    }
    .techsvg4 {
        width: 30px;
        height: auto;
        margin-top: 20px;
    }
    svg.techsvg2 {
        width: 24px;
        height: auto;
    }

}

@media (min-width: 1081px) and (max-width: 1366px) {
    .tech-flutter-text h1 {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 45px;
    }
    .tech-flutter-text h3 {
        font-size: 29px;
    }
    .tech-flutter-text p {
        font-family: 'Poppins';
        font-size: 20px;
    }
    .techinfo-svg {
        height: 103px;
        width: 103px;
    }
    .techinfo-svg svg{
        height: auto;
        width: 50px;
    }
    .info-tab p {
        font-size: 20px;
    }

}
@media (min-width: 1366px) and (max-width: 1600px) {
    .tech-flutter-text h1 {
        font-family: 'Montserrat';
        font-weight: 700;
        font-size: 45px;
    }
    .tech-flutter-text h3 {
        font-size: 29px;
    }
    .tech-flutter-text p {
        font-family: 'Poppins';
        font-size: 20px;
    }
    .techinfo-svg {
        height: 103px;
        width: 103px;
    }
    .techinfo-svg svg{
        height: auto;
        width: 50px;
    }
    .info-tab p {
        font-size: 20px;
    }


}

/* Responsive for ipad pro and ipad air */
@media (min-width: 820px) and (max-width: 1080px){
    .tech-flutter{
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }
    .tech-flutter-container{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .tech-svg-container{
        display: none;
    }
    .tech-flutter-text-container {
        height: 30%;
        width: 100%;
        margin: 0;
        padding-top: 50px;
        box-sizing: border-box;
    }

    .flutter-info{
        height: 60%;
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tech-flutter-text h1 {
        font-size: 50px;
    }

    .tech-flutter-text {
        margin-top: 0%;
        box-sizing: border-box;
        padding: 0 25px;
        width: 100%;
        margin: 0;
    }

    .info-tab {
        width: 40%;

    }
    .techinfo-svg {
        height: 130px;
        width: 180px;
    }
    .techinfo-svg > svg{
        height: 30px;
    }

    .info-tab p {
        font-size: 30px;
    }

    .tech-flutter-text h3 {
        font-size: 40px;
    }
    .tech-flutter-text p {
        font-size: 30px;
        width: 95%;
    }
    .flutter-info {
        height: 50%;
        margin-bottom: 20px;
        margin-top: 150px;
    }
    .techinfo-svg svg {
        height: auto;
        width: 79px;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .tech-flutter{
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }
    .tech-flutter-container{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .tech-svg-container{
        display: none;
    }
    .tech-flutter-text-container {
        height: 30%;
        width: 100%;
        margin: 0;
        padding-top: 50px;
        box-sizing: border-box;
    }

    .flutter-info{
        height: 60%;
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tech-flutter-text h1 {
        font-size: 25px;
    }

    .tech-flutter-text {
        margin-top: 0%;
        box-sizing: border-box;
        padding: 0 25px;
        width: 100%;
        margin: 0;
    }

    .info-tab {
        width: 40%;

    }

    .techinfo-svg {
        height: 75px;
        width: 80px;
    }
    .techinfo-svg > svg{
        height: 30px;
    }

    .info-tab p {
        font-size: 15px;
    }

    .tech-flutter-text h3 {
        font-size: 20px;
    }
    .tech-flutter-text p {
        font-size: 13px;
        width: 95%;
    }
    .tech-flutter-text h1 {
        font-size: 30px;
        margin-bottom: 5px;
    }
    .flutter-info {
        height: 50%;
        margin-bottom: 20px;
        margin-top: 78px;
    }
}

/* Responsive for Mobile */
@media  only screen and (max-width: 767px) {
    .tech-flutter{
        height: 100vh;
        width: 100%;
        overflow: hidden;
    }
    .tech-flutter-container{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .tech-svg-container{
        display: none;
    }
    .tech-flutter-text-container {
        height: 30%;
        width: 100%;
        margin: 0;
        padding-top: 50px;
        box-sizing: border-box;
    }

    .flutter-info{
        height: 60%;
        width: 100%;
        margin: 0;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tech-flutter-text h1 {
        font-size: 25px;
    }

    .tech-flutter-text {
        margin-top: 0%;
        box-sizing: border-box;
        padding: 0 25px;
        width: 100%;
        margin: 0;
    }

    .info-tab {
        width: 40%;

    }

    .techinfo-svg {
        height: 75px;
        width: 80px;
    }
    .techinfo-svg > svg{
        height: 30px;
    }

    .info-tab p {
        font-size: 15px;
    }

    .tech-flutter-text h3 {
        font-size: 20px;
    }
    .tech-flutter-text p {
        font-size: 13px;
        width: 95%;
    }
    .tech-flutter-text h1 {
        font-size: 30px;
        margin-bottom: 5px;
    }
    .flutter-info {
        height: 50%;
        margin-bottom: 20px;
        margin-top: 78px;
    }
}