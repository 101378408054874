@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Raleway:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Raleway:wght@600;700&display=swap');
html, body,#root{
  width: 100%;
  height: 100%;
  cursor: auto;

}
html {
  --cursor-color: #333;
  
}


html.dark-mode {
  --cursor-color: #fff;
}   
.App {
  text-align: center;
  height:100vh;
  width: 100%;
  /* overflow-x: auto; */
  position: relative;
}
.cursor{
  position: absolute;
  z-index: 10000;
}


/* @media screen and (min-width: 280px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    
  }

} */



