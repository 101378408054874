.chatbot-second-apge{
    background-image: url('../../../../public/images/Chatbot-second-page-image.png');
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 320px){}

@media (min-width: 321px) and (max-width: 375px){}

@media (min-width: 376px) and (max-width: 600px){}