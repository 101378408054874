/* Responsive for desktop */
.service-main-container {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 3%;
    background-color: #444647;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.service-heading {
    height: 10%;
    width: 100%;
    /* background-color:rgb(145, 176, 32) ; */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
}

.service-middle-contanier {
    height: 60%;
    width: 100%;
    /* background-color: rgb(150, 35, 150); */
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.service-bottom-container {
    height: 30%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: rgb(36, 24, 150); */
    color: #fff;
}

/* Css for onhover webdevelopment */
.webDevelopment-onhover-div-main-container {
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.web-development-onhover-image-container {
    width: 50%;
    height: 100%;
    /* background-color: blueviolet; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.web-development-onhover-image-container>img {
    height: 65%;
    margin-top: 0%;
}

.webdevelopment-onhover-container {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.heading-webdevelopment-onhover {
    font-size: 25px;
    color: #fff;
    text-align: left;
    height: 23%;
}

.webdevelopment-onhover-para {
    height: 135px;
    width: 80%;
    /* background-color: yellow; */
    font-size: 25px;
    text-align: left;
    font-weight: 400;
    color: #fff;
}

.webdevelopment-onhover-readMore {
    height: 20%;
    /* background-color: wheat; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-family: Montserrat;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

}

.webdevelopment-onhover-readMore a {
    display: flex;
    align-items: center;
    color: #ffffff !important;
}

/* End  Css for onhover webdevelopment */


/* Css for Onhover Mobile Application */
#MobileApplication-onhover-image,
#Webxr-onhiver-image {
    height: 100%;
    margin-bottom: 20px;
}

/* End Css for Onhover Mobile Application */

/* Css for Onhover Mobile Application */
#Iot-onhover-image,
#Chatbot-onhiver-image,
#UiUx-onhover-image {
    height: 80%;
}

/* End Css for Onhover Mobile Application */

/* Code for onhover service iot */
.service-iot-on-hover {
    height: 70%;
    width: 100%;
    /* background-color: wheat; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    animation: 3s infinite mobile-application linear;
}

.on-hover-iot-middle-conatiner {
    height: 100%;
    width: 50%;
    /* background-color: violet; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10%;
}

.on-hover-webxr-middle-conatiner {
    /* background-color: yellow; */
    box-sizing: border-box;
    padding-bottom: 5%;
}

#iot-rectangle-lock {
    position: absolute;
    left: 8%;
    top: 30%;
}

#IOT-group {
    position: absolute;
    top: 45%;
    left: 20%;
}

#circle-fancy-lock {
    position: absolute;
    left: 15%;
    top: 68%;
}

#watch-type-lock {
    position: absolute;
    top: 70%;
    left: 40%;

}

#iot-circle-lock {
    position: absolute;
    left: 30%;
    top: 25%;
}



.bottom-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row;
}

.one {
    height: 20%;
    display: flex;
    position: absolute;
    left: 0;
    bottom: 0;
}

.serivce-two {
    /* width: 80%; */
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: scroll;
    padding-left: 10%;
    /* padding-bottom: 2%; */
    /* background-color: violet; */
}

.scroll-bottom {
    height: 100%;
    width: 200%;
    /* background-color: wheat; */
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    box-sizing: border-box;
    animation: scrollText 10s infinite linear;

}

@keyframes scrollText {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-50);
    }
}

.scroll-bottom>.webdevelopment {
    width: 340px;
    height: 42%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

}

.scroll-bottom>.mobileapplication {

    width: 340px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    height: 42%;


}

.scroll-bottom>.chatbot {

    width: 205px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    height: 42%;
}

.scroll-bottom>.webxr {
    width: 205px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    height: 42%;
    margin-left: 70px;

}

.scroll-bottom>.iot {
    width: 340px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    height: 42%;
    /* background-color: yellow; */
    margin-left: 10px;
}

.scroll-bottom>.ui-ux {

    width: 205px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    height: 42%;
    margin-left: 20px;
}


.scroll-bottom>div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 30px;
    font-weight: 500;
    margin-left: 35px;
    margin-right: 38px;
    gap: 10px;
    width: 100%;
    height: 65px;
}


.scroll-bottom>div>a {
    text-decoration: none;
    color: #fff;

}

.circle-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

/* .on-hover-mobile-application-middle-conatiner{
    width: 50%;
} */
.scroll-bottom .swiper-slide img, .scroll-bottom .swiper-slide svg {
    height: 37px;
    width: auto;
}
.scroll-bottom .swiper-slide-active img, .scroll-bottom .swiper-slide-active svg {
    height: 55px !important;
    width: auto;
}
.scroll-bottom .swiper-slide-active p {
    font-size: 40px;
}
.scroll-bottom .swiper-slide-active div {
    margin-right: 0px;

}

.scroll-bottom .swiper-slide {
    width: auto !important;
    margin-right: 70px !important;
    display: flex;
    align-items: center;
}

.swiper-slide-active .webxr,
.swiper-slide-active .iot,
.swiper-slide-active .webdevelopment {
    margin-left: 0px;
    margin-right: 0;
}

.scroll-bottom .swiper{
    width: 90%;
}
.scroll-bottom img, .scroll-bottom svg {
    margin-right: 15px;
    margin-left: 0%;
    height: 37px;
}

.service-slide {
    display: flex;
}
.service-slide img{
   height: 37px !important;
}
.service-slide a {
    color: white !important;
}

/* Responsive for mobile */
@media screen and (max-width: 600px) {
    .service-main-container {
        height: 150%;
        width: 100%;
    }

    .service-heading {
        height: 10%;
        width: 100%;
        /* background-color: violet; */
    }


    /* For Iot on hover */
    .service-iot-on-hover {
        height: 100%;
        width: 100%;
        /* background-color: yellow; */
        display: flex;
        flex-direction: column;
        margin: 0;
        pad: 0;
    }

    .on-hover-iot-middle-conatiner {
        height: 50%;
        width: 100%;
        /* background-color: rgb(22, 173, 24); */
        padding: 0;
        margin: 0;
    }

    .service-content {
        height: 70%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        /* background-color: red; */
        padding: 0;
        margin: 0;
    }

    .service-heading-div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .service-paragraph-div {
        width: 100%;
        /* background-color: wheat; */
        box-sizing: border-box;

    }

    .service-paragraph-div>p {
        width: 100%;
        /* background-color: violet; */
        box-sizing: border-box;
        padding: 5px;
    }

    .service-readmore {
        box-sizing: border-box;
        padding-left: 3%;
    }

    #iot-rectangle-lock {
        position: absolute;
        left: 10px;
        top: 17%;
    }

    #IOT-group {
        top: 35%;
    }

    #circle-fancy-lock {
        left: 10%;
        top: 55%;
    }

    #watch-type-lock {
        left: 70%;
        top: 55%;
    }

    #iot-circle-lock {
        left: 70%;
        top: 15%;
    }

    .serivce-two {
        height: 100%;
        width: 100%;
    }

    .scroll-bottom {
        height: 200%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
    }

    .scroll-bottom>div {
        width: 100%;

    }

    .one,
    .three {
        display: none;
    }


    .service-middle-container {
        height: 100%;
        background-color: violet;
        flex-direction: column;
    }

    .laptop-image-container {
        width: 100%;
        height: 40%;
    }

    .laptop-image-container>img {
        height: 100%;
        width: 100%;
    }

}

#service-vector-1 {
    position: absolute;
    top: 15%;
    left: 35%;
    height: 30px;
}

#service-vector-2 {
    position: absolute;
    left: 70%;
    top: 15%;
    height: 45px;
}

#service-vector-3 {
    position: absolute;
    top: 42%;
    left: 3%;
}

#service-vector-4 {
    position: absolute;
    top: 95%;
    left: 62%;
}

#service-arrow {
    position: absolute;
    bottom: 4%;
}


#service-vector-1,
#service-vector-2,
#service-vector-3,
#service-vector-4 {
    animation: 3s infinite service-svg linear;
}

@keyframes service-svg {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(60deg);
    }
}


#service-arrow {

    animation: 3s infinite svg-bounce linear;

}
.scroll-bottom .swiper-slide {
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin-right: 25px !important;
    margin-left: 25px;
    display: flex;
    align-items: center;
}
@keyframes svg-bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}


@media (max-width: 320px) {
    .service-main-container{
        height: 100%;
        width: 100%;
    }

    .service-heading{
        height: 10%;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .service-heading h1 {
        font-size: 20px;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .webDevelopment-onhover-div-main-container{
        height: 80%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
    }

    .heading-webdevelopment-onhover h1 {
        font-size: 25px !important;
        width: 100%;
    }

    .webdevelopment-onhover-para{
        height: 80%;
        width: 100%;
        font-size: 15px !important;
    }
    .webdevelopment-onhover-readMore {
        margin-top: 60px !important;
        height: 20%;
    }
    .scroll-bottom>div {
        margin-left: 0px;
    }
    
    .mobileapplication p{
        display: flex;
        font-size: 15px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobileapplication img{
        height: 25px !important;
    }

}
  
@media (min-width: 321px) and (max-width: 375px) {
    .service-main-container{
        height: 100%;
        width: 100%;
    }

    .service-heading{
        height: 10%;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .service-heading h1 {
        font-size: 20px;
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .webDevelopment-onhover-div-main-container{
        height: 80%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
    }

    .heading-webdevelopment-onhover h1 {
        font-size: 25px !important;
        width: 100%;
    }

    .webdevelopment-onhover-para{
        height: 80%;
        width: 100%;
        font-size: 15px !important;
    }
    .webdevelopment-onhover-readMore {
        margin-top: 60px !important;
        height: 20%;
    }
    .scroll-bottom>div {
        margin-left: 0px;
    }
    
    .mobileapplication p{
        display: flex;
        font-size: 15px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mobileapplication img{
        height: 25px !important;
    }

}

@media (min-width: 376px) and (max-width: 600px) {
    .service-main-container{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 10%;
    }

    .service-heading{
        height: 20%;
        width: 100%;
    }
   
    .webDevelopment-onhover-div-main-container{
        height: 80%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
    }
    .service-heading h1{
        font-size: 30px;
    }
    .web-development-onhover-image-container{
        height: 40%;
        width: 100%;
    }

    .web-development-onhover-image-container img{
        height: 100%;
        width: 50%;
    }

    .webdevelopment-onhover-container{
        height: 60%;
        width: 100%;
        box-sizing: border-box;
        padding-left: 5%;
        margin-top: 40px;

    }

    .webdevelopment-onhover-para{
        height: 50%;
        width: 100% !important;
        font-size: 18px !important;
        box-sizing: border-box;
        padding-right: 10px;
    }
    .webdevelopment-onhover-readMore{
        height: 10%;
    }
  

    #service-vector-1, #service-vector-2, #service-vector-3,#service-vector-4, #service-arrow{
        display: none;
    }
    .heading-webdevelopment-onhover h1 {
        font-size: 30px;
    }

    .service-bottom-container {
        height: 20%;
    }

    .three{
        display: none;
    }
    .webdevelopment-onhover-readMore a {
        font-size: 17px;
    }
    .webdevelopment-onhover-readMore {
        margin-top: 15px;
        height: 20%;
    }
    .scroll-bottom>div {
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        padding-left: 80%;
    }
  }


/* Rersponsive for laptop */
@media only screen and (max-width: 1537px) {
   .heading-webdevelopment-onhover{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
    }

    .heading-webdevelopment-onhover h1{
        font-size: 35px;
    }

    #service-vector-2 {
        top: 10%;
    }

    .webdevelopment-onhover-para{
        font-size: 22px;
        width: 80%;
    }

    .serivce-two {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 6%;
        height: 45%;
    }
    .serivce-two .swiper-wrapper {
        margin-left: -5%;
    }
    .scroll-bottom{
 
        height: 100%;
        width: 100%;
        font-size: 50px;
        display: flex;
        justify-content: flex-start;
    }
    .webDevelopment-onhover-div-main-container {
        height: 60%;
    }

}   

/* Responsive for apple laptop */
@media screen and ((max-width: 1080px) and (max-height:713px)) {
     
    .service-heading{
        font-size: 20px;
        margin-top: 5%;
    } 

    .serivce-two .swiper-wrapper {
        margin-left: -14%;
        font-size: 23px;
    }

    .heading-webdevelopment-onhover h1 {
        font-size: 30px;
    }

    .webdevelopment-onhover-para {
        font-size: 20px;
        width: 80%;
    }
}



/* Responsive for mobile */
@media only screen and (max-width: 600px) {
   
    .service-main-container{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-bottom: 10%;
    }

    .service-heading{
        height: 20%;
        width: 100%;
    }
   
    .webDevelopment-onhover-div-main-container{
        height: 80%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
    }
    .service-heading h1{
        font-size: 30px;
    }
    .web-development-onhover-image-container{
        height: 40%;
        width: 100%;
    }

    .web-development-onhover-image-container img{
        height: 100%;
        width: 50%;
    }

    .webdevelopment-onhover-container{
        height: 50%;
        width: 100%;
        box-sizing: border-box;
        padding-left: 5%;
    }

    .webdevelopment-onhover-para{
        height: 60%;
        width: 100%;
        font-size: 20px;
    }
    .webdevelopment-onhover-readMore{
        height: 10%;
    }
  

    #service-vector-1, #service-vector-2, #service-vector-3,#service-vector-4, #service-arrow{
        display: none;
    }
    .heading-webdevelopment-onhover h1 {
        font-size: 30px;
    }

    .service-bottom-container {
        height: 20%;
    }

    .three{
        display: none;
    }
 
}

@media (min-width: 820px) and (max-width: 1080px){
    .service-main-container{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .service-heading{
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .service-heading h1{
        font-size: 60px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #service-vector-1, #service-vector-2, #service-vector-3, #service-vector-4, #service-arrow, .one, .three{
        display: none;
    } 

    .webDevelopment-onhover-div-main-container{
        height: 90%;
        width: 100%;
        flex-direction: column;
    }
    .web-development-onhover-image-container{
        height: 40%;
        width: 100%;
    }
    .web-development-onhover-image-container img {
        width: 50%;
        height: 85%;
    }
    .webdevelopment-onhover-container{
        height: 60%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: left;
        gap: 20px;
    }
    .heading-webdevelopment-onhover{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
    }
    .heading-webdevelopment-onhover h1 {
        font-size: 70px;
    }
    .webdevelopment-onhover-para {
        width: 100%;
        font-size: 40px;
        box-sizing: border-box;
        padding: 0px 40px;
        font-weight: 400;
        height: auto;
    }
    .webdevelopment-onhover-readMore{
        height: 10%;
        margin-top: 35PX;
        width: 100%;
        box-sizing: border-box;
        padding: 0 40px;
        font-family: 'Poppins';
        font-weight: 400 !important;
        font-size: 30px;
        
    }
    .webdevelopment-onhover-readMore svg{
        height: 60px;
        width: auto;
    }
    .service-slide{
        display: none;
    }
    .service-bottom-container {
        display: none;

    }

}

@media (min-width: 768px) and (max-width: 819px){
    .service-main-container{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .service-heading{
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .service-heading h1{
        font-size: 50px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #service-vector-1, #service-vector-2, #service-vector-3, #service-vector-4, #service-arrow, .one, .three{
        display: none;
    } 

    .webDevelopment-onhover-div-main-container{
        height: 90%;
        width: 100%;
        flex-direction: column;
    }
    .web-development-onhover-image-container{
        height: 40%;
        width: 100%;
    }
    .web-development-onhover-image-container img {
        width: 50%;
        height: 85%;
    }
    .webdevelopment-onhover-container{
        height: 60%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        text-align: left;
        gap: 20px;
    }
    .heading-webdevelopment-onhover{
        width: 100%;
        height: 10%;
        display: flex;
        justify-content: center;
    }
    .heading-webdevelopment-onhover h1 {
        font-size: 50px;
    }
    .webdevelopment-onhover-para {
        width: 100%;
        font-size: 35px;
        box-sizing: border-box;
        padding: 0px 40px;
        font-weight: 400;
        height: auto;
    }
    .webdevelopment-onhover-readMore{
        height: 10%;
        margin-top: 35PX;
        width: 100%;
        box-sizing: border-box;
        padding: 0 40px;
        font-family: 'Poppins';
        font-weight: 400 !important;
        font-size: 25px;
        
    }
    .webdevelopment-onhover-readMore svg{
        height: 60px;
        width: auto;
    }
    .service-slide{
        display: none;
    }
    .service-bottom-container {
        display: none;

    }


}

/* Resposive for mobile */
@media screen and (max-width: 767px){
    .service-main-container{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }
    .service-heading{
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .service-heading h1{
        font-size: 30px;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #service-vector-1, #service-vector-2, #service-vector-3, #service-vector-4, #service-arrow, .one, .three{
        display: none;
    } 

    .webDevelopment-onhover-div-main-container{
        height: 90%;
        width: 100%;
        flex-direction: column;
    }
    .web-development-onhover-image-container{
        height: 40%;
        width: 100%;
    }
    .web-development-onhover-image-container img{
        width: 65%;
        height: 70%;
    }
    .webdevelopment-onhover-container{
        height: 60%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        gap: 10px;
    }
    .heading-webdevelopment-onhover{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .webdevelopment-onhover-para{
        width: 100%;
        font-size: 19px;
        box-sizing: border-box;
        padding: 0 10px;
        font-weight: 400;
    }
    .webdevelopment-onhover-readMore{
        height: 10%;
        margin-top: 35PX;
        width: 100%;
        box-sizing: border-box;
        padding: 0 10px;
        font-family: 'Poppins';
        font-weight: 400 !important;
        font-size: 16px;
    }
    .service-slide{
        display: none;
    } 

}
