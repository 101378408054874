/* Reponsive for laptop and desktop */
.product-main-container{
    height: 100%;
    width: 100%;
    background-color: #272d2f;
    padding-top: 80px;
    box-sizing: border-box;
    color: #fff;
    overflow-x:hidden ;
}

.product-sub-container{
    height: 100%;
    width: 100%;
    /* background-color: violet; */
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 1;
}

.product-left-content-div{
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 5%;
    text-align: left;
    z-index: 1;
}

.product-left-content-div > h1 {
    width: 100%;
    height: 5%;
    font-size: 73px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    font-family: "Poppins";
}

.product-text-container > h3{
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    /* line-height: 46.94px; */
    font-family: 'Raleway';
    margin: 0;
    margin-bottom: 20px;
    /* background-color: turquoise; */

}
#text-product{
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 400;    

}
.product-text-container > p{

    font-size: 25px;
    font-weight: 700;
    margin-bottom: 16px;

}

.product-left-content-div > p{

    height: 50%;
    box-sizing: border-box;
    text-align: left;
    font-size: 20px;
}

.prod-readmore {
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 40px;
    /* background-color: yellow; */
}
.prod-readmore a{
    display: flex;
    color: white;
    display:flex;
    align-items: center;
}
.prod-readmore img{
    width: 45px;
    height: 45px;
}

.prod-readmore > img{
    margin-left: 20px;
    cursor: pointer;
}
.product-incubator-image-div{
    height: 100%;
    width: 50%;
    /* background-color: turquoise; */
    display: flex;
    justify-content: space-around;
    box-sizing: border-box;
    position: relative;
}
.product-incubator-image-div .swiper-wrapper {
    align-items: center;
    margin-top: 30%;
}

.product-text-container {
    height: 200px;
}

@keyframes c2w {
    0%{
        transform: rotateY(0deg);
    } 
    50%{
        transform: rotateY(180deg);
    } 
}

#product-vector-1{
    position: absolute;
    top: 5%;
    left: 15%;
}

#product-vector-2{
    position: absolute;
    top: 22%;
    left: 88%;
}

#product-vector-3 {
    position: absolute;
    bottom: 12%;
    left: 63%;
}

#product-vector-4{
    position: absolute;
    top: 85%;
    left: 30%;
}

#product-arrow{
    position: absolute;
    top: 90%;
    left: 48%;
}

#product-three-circle{
    position: absolute;
    top: 70%;
    left: 90%;
}

#product-color-circles {
    position: absolute;
    bottom: 5px;
    right: 155px;
}

#product-end-corner-vector {
    position: absolute;
    bottom: 0;
    right: 0;
}

.product-incubator-image-div .swiper-slide-active #sem-img {
    transform: scale(1.9);
}
.product-incubator-image-div .swiper-slide-active #drop-img{
    transform: scale(1.9);
}


/* Animation for Product page */
#product-vector-1, #product-vector-2, #product-vector-3, #product-vector-4,#product-white-Vector{
    animation: 3s infinite product-svg linear;
}


@keyframes product-svg {
    0%{
        transform: rotate(0deg);
    } 
    50%{
        transform: rotate(60deg);
    } 
}


#product-arrow{

    animation: 3s infinite svg-bounce linear;

}
.product-vectors{
    height: 100%;
    width: 100%;
    position: absolute;
}
@keyframes svg-bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-30px);} 
   60% {transform: translateY(-15px);} 
}
.product-incubator-image-div .swiper{
    height: 98%;
    width: 100%;
}
.product-incubator-image-div .swiper-slide{
    height: 98%;
    width: 50% !important;
    margin-left: 0px !important;
}
.product-incubator-image-div img{
    height: auto;
    width: 40%;
    filter: blur(2.5px);
}
#bus-tracking-img{
    height: auto;
    width: 35%;
}

#product-white-Vector {
    position: absolute;
    left: 0%;
    top: 30%;
}

.product-incubator-image-div .swiper-slide-active img{
    transform: scale(2.5);
    filter: blur(0px);
    opacity: 10;
}
 .swiper-slide-active #bus-tracking-img, .swiper-slide-active #drop-img {
    transform: scale(2.0);
    filter: blur(0px);
}

/* Animation for product end */


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1537px) {

    /* Product page */
    .product-left-content-div{
        width: 50%;
    }

   .product-left-content-div h1{
    font-size: 45px;
   } 

   #text-product{
    font-size: 20px;
   } 
   #product-vector-3{
    left: 75%;
   }
   .product-incubator-image-div .swiper-slide {
    height: 35% !important;
   }
   .product-incubator-image-div .swiper-wrapper {
    align-items: center;
    margin-top: 0;
}

}


/* Responsive for Apple laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)) {

.product-left-content-div {
    width: 58%;
}

.product-left-content-div > h1{
    font-size: 35px;
}

.product-text-container > p {
    font-size: 25px;
}

#text-product {
    font-size: 16px;
}
#product-vector-2{
    height: 40px;
}

#product-vector-3{
    height: 30px;
}

#product-color-circles{
    height: 70px;
}
#product-three-circle{
    height: 50px;
}
#product-end-corner-vector{
    height: 100px;
    right: -16px;
}
.prod-readmore img {
    width: 38px;
    height: 37px;
}

}

@media (min-width: 769px) and (max-width: 1080px) {
    .product-incubator-image-div .swiper-slide {
        height: 70% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #product-vector-2 {
        top: 0%;
    }
}
@media (min-width:1080px) and (max-width:1366px){
    #product-vector-2 {
        top: 0%;
    }
    #product-vector-3 {
        bottom: 10%;
        left: 63%;
        top:unset;
    }
}

/* Responsive for ipad Pro and ipad air */
@media (min-width: 820px) and (max-width: 1080px){
    .product-main-container{
        height: 100vh;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .product-vectors{
        display: none;
    }
    .product-sub-container{
        height: 100%;
        width: 100%;
        flex-direction:column-reverse;
        box-sizing: border-box;
    }

    .product-incubator-image-div{
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }

    .product-incubator-image-div .swiper-slide {
        height: auto !important;
        width: 100% !important;
    }

    .swiper-slide{
        height: 100%;
        
    }
    .product-incubator-image-div img {
        width: 15%;
        height: auto;
        filter: blur(2.5px);
    }

    .product-left-content-div{
        width: 100%;
        height: 60%;
    }
    .product-left-content-div h1{
        font-size: 70px;
        height: 15%;
    }

    .product-text-container{
        width: 100%;
        height: 60%;
        font-size: 30px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }
    #text-product{
        height: 70%;
        font-size: 35px;
    }

    .product-text-container p{
        font-size: 40px;
    }
    .prod-readmore {
        font-size: 27px;
    }
    .prod-readmore img {
        width: 60px;
        height: 60px;
    }
    #bus-tracking-img{
        height: 160px;
        width: 140px;
    }

}

/* Responsive for ipad mini */
@media (min-width: 768px) and (max-width:819px){
    .product-main-container{
        height: 100vh;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .product-vectors{
        display: none;
    }
    .product-sub-container{
        height: 100%;
        width: 100%;
        flex-direction:column-reverse;
        box-sizing: border-box;
    }

    .product-incubator-image-div{
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 100px;
    }

    .product-incubator-image-div .swiper-slide {
        height: auto !important;
        width: 100% !important;
    }

    .swiper-slide{
        height: 100%;
        
    }
    .product-incubator-image-div img {
        width: 15%;
        height: auto;
        filter: blur(2.5px);
    }

    .product-left-content-div{
        width: 100%;
        height: 60%;
    }
    .product-left-content-div h1{
        font-size: 50px;
        height: 15%;
    }

    .product-text-container{
        width: 100%;
        height: 60%;
        font-size: 30px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }
    #text-product{
        height: 70%;
        font-size: 25px;
    }

    .product-text-container p{
        font-size: 35px;
    }
    .prod-readmore {
        font-size: 27px;
    }
    .prod-readmore img {
        width: 60px;
        height: 60px;
    }
    #bus-tracking-img{
        height: 130px;
        width: 110px;
    }
}

/* Responsive for mobile */
@media screen and (max-width: 767px){
    .product-main-container{
        height: 100vh;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .product-vectors{
        display: none;
    }
    .product-sub-container{
        height: 100%;
        width: 100%;
        flex-direction:column-reverse;
        box-sizing: border-box;
    }

    .product-incubator-image-div{
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .product-incubator-image-div img{
        height: 100%;
    }

    .product-left-content-div{
        width: 100%;
        height: 60%;
    }
    .product-left-content-div h1{
        font: 30px;
        height: 20%;
    }

    .product-text-container{
        width: 100%;
        height: 60%;
        font-size: 30px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }
    #text-product{
        height: 70%;
    }

    .product-text-container p{
        font-size: 21px;
    }
    .product-incubator-image-div .swiper-slide {
        height: auto !important;
        width: 100% !important;
    }
    .product-incubator-image-div img {
        height: auto;
        width: 22%;
        filter: blur(2.5px);
    }
    #bus-tracking-img {
        height: auto;
        width: 22%;
    }
}

@media screen and(max-width: 280px){
    .product-main-container{
        height: 100vh;
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .product-vectors{
        display: none;
    }
    .product-sub-container{
        height: 100%;
        width: 100%;
        flex-direction:column-reverse;
        box-sizing: border-box;
    }

    .product-incubator-image-div{
        height: 40%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }
    .product-incubator-image-div img{
        height: 100%;
    }

    .product-left-content-div{
        width: 100%;
        height: 60%;
    }
    .product-left-content-div h1{
        font: 30px;
        height: 20%;
    }

    .product-text-container{
        width: 100%;
        height: 60%;
        font-size: 30px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
    }
    #text-product{
        height: 70%;
    }

    .product-text-container p{
        font-size: 21px;
    }
    .product-incubator-image-div .swiper-slide {
        height: auto !important;
        width: 100% !important;
    }
    .product-incubator-image-div img {
        height: auto;
        width: 22%;
        filter: blur(2.5px);
    }
    #bus-tracking-img {
        height: auto;
        width: 22%;
    }
}