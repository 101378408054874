.technologies {
    height: 100%;
    width: 100%;
    background-color: #ffffff;

}

.technologies-container {
    height: 100%;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: 0%;
}

.technologies-text-container {
    padding-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.technologies-text-container h1 {
    font-family: 'Poppins';
    font-size: 81px;
    font-weight: 700;
    margin: 0;

}

.technologies-text-container h3 {
    font-family: 'Poppins';
    font-size: 50px;
    font-weight: 600;
    margin: 0;
    line-height: 75px;
}

.technologies-text-container p {
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 500;
    margin: 15px 0px;
    line-height: 30.48px;
    width: 35%;
    /* background-color: yellow; */
}

.technologies-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-top: 3%;
    gap: 50px;
    height: 225px;
    width: 100%;
}

.tech-logo-container {
    margin: 1%;
    width: 179px;
    height: 182px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset -2.406667px 2.4066667556762695px 2.406667px 0px #A5A5A566, -2.4066667556762695px 2.4066667556762695px 2.4066667556762695px 0px #A5A5A566;
    border: 4px solid #FFFFFF1A;
    cursor: pointer;
    transition: 300ms ease;

}

.tech-logo-container img {
    height: auto;
    width: 70%;
}

.tech-logo-container:hover {
    transform: rotate(5deg);
    transition: 300ms ease;
}

/* .tech-logo-container:hover{
    width: 200px;
    height: 200px;
    transition: 400ms ease-in-out;
} */

.technologies-info {
    z-index: 999;
    position: relative;
}

.technologies-vectors {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: block;
}

.tsvg1 {
    display: inline-block;
    position: absolute;
    top: 35%;
    left: 5%;
}

.tsvg2 {
    display: inline-block;
    position: absolute;
    top: 12%;
    left: 18%;
}

.tsvg3 {
    display: inline-block;
    position: absolute;
    top: 16%;
    right: 23%;

}

.tsvg4 {
    display: inline-block;
    position: absolute;
    bottom: 10%;
    left: 32%;
}

.tsvg9 {
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;
}

.tsvg5 {
    display: inline-block;
    position: absolute;
    bottom: 13%;
    right: 37%;
}

.tsvg7 {
    display: inline-block;
    position: absolute;
    bottom: 15%;
}

.tsvg6 {
    display: inline-block;
    position: absolute;
    bottom: 1%;
    right: 8%;
}

.tsvg8 {
    display: inline-block;
    position: absolute;
    bottom: 13%;
    right: 0%;
}

.mySql-heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.mySql-heading img {
    width: 140px;
}

/* Reponsive for Tablets */
@media screen and (max-width: 602px) and (min-height: 962px) {

    .technologies-text-container {
        width: 100%;
    }

    .technologies-text-container>h1,
    h3 {
        width: 100%;
        /* background-color: yellow; */
        font-size: 60px;
    }

    .technologies-text-container>p {
        /* background-color: violet; */
        width: 100%;
        margin-top: 20px;
    }

    .technologies-info-container {
        /* flex-direction: column; */
        flex-wrap: wrap;
    }

    .technologies-vectors {
        display: NONE;
    }
}


/* Animation for technologies */
.tsvg1,
.tsvg2,
.tsvg3,
.tsvg4,
.tsvg5 {
    animation: 3s infinite technologies-svg linear;
}


@keyframes technologies-svg {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(60deg);
    }
}

.tsvg7 {
    animation: 3s infinite svg-bounce linear;
    position: absolute;
    bottom: 8%;
}

@keyframes svg-bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

/* End Animation for technologies */

/* Responsive*/
@media only screen and (max-width: 1537px) {

    .technologies-text-container h1 {
        font-size: 65px;
    }

    .technologies-text-container h3 {

        font-size: 45px;

    }

    .technologies-text-container p {
        font-size: 25px;
        width: 40%;

    }

    .technologies-info-container {
        margin-top: 0%;

    }

    .tech-logo-container {
        width: 170px;
        height: 145px;
    }

    .technologies-text-container {
        padding-top: 0%;

    }
}

/* Responsive for Apple laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)) {
    .technologies-container {
        padding-top: 6%;
    }

    .technologies-text-container h1 {
        font-size: 55px;
    }

    .technologies-text-container h3 {
        font-size: 45px;
    }

    .technologies-text-container p {
        font-size: 20px;
        width: 59%;
    }

    .tech-logo-container {
        width: 148px;
        height: 156px;
    }

    .tsvg6 {
        right: 18%;
    }
}



/*laptop*/
@media (min-width: 1081px) and (max-width: 1366px) {
    .tsvg9 {
        display: inline-block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 6%;
        height: auto;
    }

    .tsvg8 {
        display: inline-block;
        position: absolute;
        bottom: 10%;
        right: 0%;
        height: auto;
        width: 6%;
    }

    .tsvg6 {
        display: inline-block;
        position: absolute;
        bottom: 1%;
        right: 7%;
        height: auto;
        width: 2%;
    }

    .technologies-text-container {
        padding-top: 3%;

    }

    .tsvg7 {
        animation: 3s infinite svg-bounce linear;
        position: absolute;
        bottom: 8%;
        width: 66px;
        height: auto;
    }

    .tech-logo-container {
        width: 143px;
        height: 135px;
    }

    .technologies-text-container h1 {
        font-size: 60px;
    }

    .technologies-text-container {
        padding-top: 4%;
    }

    .technologies-text-container h3 {
        font-size: 40px;
        line-height: 50px;
    }

    .technologies-text-container p {
        font-size: 20px;
        width: 40%;
    }

    .technologies-info-container {
        height: 185px;
    }

    .tsvg5 {
        bottom: 5%;
        right: 25%;
    }

    .tsvg4 {
        bottom: 7%;
        left: 25%;
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    .technologies-text-container {
        padding-top: 3%;

    }
}

/* Responsve for ipad air and ipad pro */
@media (min-width: 820px) and (max-width: 1080px) {
    .technologies {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 50px;
    }

    .technologies-container {
        height: 100%;
        width: 100%;
    }

    .technologies-text-container {
        height: 40%;
        font-size: 20px;
        width: 100%;
        justify-content: flex-start;
        display: flex;
    }

    .technologies-text-container h1 {
        font-size: 70px;
        width: 100%;
        height: 15%;
    }

    .technologies-text-container h3 {
        font-size: 50px;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
    }

    .technologies-text-container p {
        font-size: 40px;
        width: 100%;
        line-height: 80px;
    }

    .technologies-info-container {
        display: flex;
        justify-content: center;
    }

    .technologies-info {
        height: 60%;
        width: 100%;
    }

    .technologies-vectors {
        display: none;
    }

    .technologies-info-container {
        flex-wrap: wrap;
    }

    .tech-logo-container {
        height: 73px;
        width: 73px;
    }

    .tech-logo-container {
        height: 220px;
        width: 220px;
    }
}

@media (min-width: 768px) and (max-width: 819px) {
    .technologies {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 50px;
    }

    .technologies-container {
        height: 100%;
        width: 100%;
    }

    .technologies-text-container {
        height: 40%;
        font-size: 20px;
        width: 100%;
        justify-content: flex-start;
        display: flex;
    }

    .technologies-text-container h1 {
        font-size: 50px;
        width: 100%;
        height: 15%;
    }

    .technologies-text-container h3 {
        font-size: 40px;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
    }

    .technologies-text-container p {
        font-size: 30px;
        width: 100%;
        line-height: 50px;
    }

    .technologies-info-container {
        display: flex;
        justify-content: center;
    }

    .technologies-info {
        height: 60%;
        width: 100%;
    }

    .technologies-vectors {
        display: none;
    }

    .technologies-info-container {
        flex-wrap: wrap;
    }

    .tech-logo-container {
        height: 180px;
        width: 180px;
    }

}

/* Responsive for Mobile */
@media screen and (max-width: 767px) {
    .technologies {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 50px;
    }

    .technologies-container {
        height: 100%;
        width: 100%;
    }

    .technologies-text-container {
        height: 40%;
        font-size: 20px;
        width: 100%;
        justify-content: flex-start;
        display: flex;
    }

    .technologies-text-container h1 {
        font-size: 35px;
        width: 100%;
        height: 15%;
    }

    .technologies-text-container h3 {
        font-size: 30px;
        width: 100%;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 18px;
    }

    .technologies-text-container p {
        font-size: 18px;
        width: 100%;
    }

    .technologies-info-container {
        display: flex;
        justify-content: center;
    }

    .technologies-info {
        height: 60%;
        width: 100%;
    }

    .technologies-vectors {
        display: none;
    }

    .technologies-info-container {
        flex-wrap: wrap;
    }

    .tech-logo-container {
        height: 73px;
        width: 73px;
    }
}