
.about-main-container{  
    width: 100%;
    height: 100%;
    background-image: url('../../../../public/images/about-backgroud.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: hidden;
}
.about-sub-container{
    width: 100%;
    height: 100%;
    /* background-color: black; */
    position: relative;
}

#webdevelopmentscroll-leftVector{
    position: absolute;
    left: 550px;
    top: 140px;
}

#about-rightVector{
    position: absolute;
    top: 60px;
    left: 1350px;
}

#webdevelopmentscroll-whiteVector{
    position: absolute;
    left: 100px;
    top: 380px;
}

.sub-about-div-one{
    height: 100%;
    width: 100%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 40px;
    position: relative;
}

.about-div-one-left-img{
    height: 70%;
    width: 50%;
    box-sizing: border-box;
    margin-top: 7%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
} 

.about-div-one-left-img  img{
    height: 100%;
    width: 70%;
    background-size: cover;
}

.about-div-one-right{
    height: 100%;
    width:50%;
    box-sizing: border-box;
    margin-left: 13px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
}

.About-Us{
    height: 30%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    text-align: flex-start;
    font-family: "Poppins";
    box-sizing: border-box;
    font-size: 70px;

}

.About-Us  h1{
    font-weight: 700;
    color: #FF8402;
    margin: 0;
    font-size: 100%;
    /* background-color: red; */
    width: 100%;
    display: flex;
    
}

.About-Us  p{
    height: 60%;
    width: 100%;
    font-weight: 600;
    color: #424546;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-size: 25px;
}

.about-image-second{
    height: 70%;
    width: 100%;
    box-sizing: border-box;
    /* padding-left: 50px; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.about-image-second  img{
    height: 100%;
    width: 100%;
}

/* Svg css */
#about-svg-1{
    position: absolute;
    top: 15%;
    left: 20%;
   }

   #about-svg-2{
    position: absolute;
    top: 5%;
    right: 30%;
   }

   #about-svg-3{
    position: absolute;
    top:35%;
    left: 3%;
   }

   #about-svg-4{
    position: absolute;
    bottom: 2%;
    left: 25%;
   }

   #about-svg-1, #about-svg-2,#about-svg-3,#about-svg-4{
    animation: 3s infinite technologies-svg linear;
}


@keyframes technologies-svg {
    0%{
        transform: rotate(0deg);
    } 
    50%{
        transform: rotate(60deg);
    } 
}


/* Reponsive for Tablets */
@media screen and ((max-width: 601px) and (min-height:962px)) {
    
    /* First page */
    .about-main-container{
        height: 100%;
        width: 100%;
        padding-top: 80px;
        margin: 0;
        padding-left: 0;
        margin-left: 0;
    }

    .about-sub-container{
        height: 150%;
        width: 100%;
        /* background-color: yellow; */
        padding-left: 0;
        margin-left: 0
    }

    .sub-about-div-one{
        height: 50%;
        width: 100%;
        /* background-color: violet; */
        flex-direction: column-reverse;
        padding-left: 0;
        margin-left: 0
    }

    .about-div-one-right{
        width: 100%;
        height: 75%;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start
        /* background-color: antiquewhite; */
    }

    .About-Us{
        /* background-color: yellow; */
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }
    .About-Us > p{
        width: 100%;
        height: 60%;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        box-sizing: border-box;
    }
    .about-image-second{
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* background-color: red; */
        margin: 0;
        padding-left: 0;
        box-sizing: border-box;
        padding-right: 15px;
    }

    .about-image-second > img{
        height: 90%;
        width: 90%;
    }

    #about-us-heading{
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .about-div-one-left-img{
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .about-div-one-left-img > img{
        
        height: 100%;
        width: 87%;
    }


    /* Second page */
    .sub-about-div-second{
        margin-top: 40px;
        padding: 0;
        /* background-color: wheat; */
        height: 75%;
        width: 100%;
        justify-content: flex-start;
    }

    .sub-about-div-second-paragraph{
        width: 100%;
        height: 25%;
        /* background-color: violet; */
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-left: 0px;
    }

    .sub-about-div-second-images{
        height: 70%;
        padding-left: 0;
        display: flex;
        /* background-color: yellow; */
        justify-content: flex-start;
        align-items: flex-start;
        
    }

    .girls-images{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        /* background-color: turquoise; */
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .girls-images > div{
        width: 100%;
        height: 40%;
        background-color: yellow;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding-left: 20px;
    }

    .girls-images > div > img{
        height: 100%;
        width: 100%;
    }

    .circle-images-about{
        display: none;
    }
    
    /* Third page */

    .sub-about-div-three{
        height: 50%;
        width: 100%;
        /* background-color: yellow; */
    }

    .left-container, .right-container{
        height: 50%;
        width: 100%;
    }

    .about-privacy-policy{
        width: 100%;
        height: 100%;
    }

    .para{
        font-size: 20px;
        width: 100%;
    }
}
/* Responsive */
@media only screen and (max-width: 1537px) {


    .about-main-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }


    .About-Us h1 {
        font-weight: 700;
        color: #FF8402;
        margin: 0;
        font-size: 45px
    }

    #about-svg-1 {
        position: absolute;
        top: 10%;
        left: 20%;
    }

    #about-svg-2{
        right: 20%;
    }

    #about-svg-4{
        left: 20%;
        bottom: 0%;
    }

    .About-Us  p {
        font-size: 22px;
        height: 52%;
    }

}

@media only screen and (min-width: 1366px){

    .About-Us p{
        font-size:20px;
    }
    #about-svg-2{

        top: 5%;
        height: 5%;
    }

}


.about-main-container{  
    width: 100%;
    height: 100%;
    background-image: url('../../../../public/images/about-backgroud.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    overflow: auto;
    overflow-y: hidden;
}
.about-sub-container{
    width: 100%;
    height: 100%;
    /* background-color: black; */
    position: relative;
}

#webdevelopmentscroll-leftVector{
    position: absolute;
    left: 550px;
    top: 140px;
}

#about-rightVector{
    position: absolute;
    top: 60px;
    left: 1350px;
}

#webdevelopmentscroll-whiteVector{
    position: absolute;
    left: 100px;
    top: 380px;
}

.sub-about-div-one{
    height: 100%;
    width: 100%;
    /* background-color: aquamarine; */
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 40px;
    position: relative;
}

.about-div-one-left-img{
    height: 70%;
    width: 50%;
    box-sizing: border-box;
    margin-top: 7%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
} 

.about-div-one-left-img  img{
    height: 100%;
    width: 70%;
    background-size: cover;
}

.about-div-one-right{
    height: 100%;
    width:50%;
    box-sizing: border-box;
    margin-left: 13px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    padding-top: 5%;
}

.About-Us{
    height: 30%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    text-align: flex-start;
    font-family: "Poppins";
    box-sizing: border-box;
    font-size: 70px;

}

.About-Us  h1{
    font-weight: 700;
    color: #FF8402;
    margin: 0;
    font-size: 100%;
    /* background-color: red; */
    width: 100%;
    display: flex;
    
}

.About-Us  p{
    height: 60%;
    width: 100%;
    font-weight: 600;
    color: #424546;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    font-size: 25px;
}

.about-image-second{
    height: 70%;
    width: 100%;
    box-sizing: border-box;
    /* padding-left: 50px; */
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.about-image-second  img{
    height: 100%;
    width: 100%;
}

/* Svg css */
#about-svg-1{
    position: absolute;
    top: 15%;
    left: 20%;
   }

   #about-svg-2{
    position: absolute;
    top: 5%;
    right: 30%;
   }

   #about-svg-3{
    position: absolute;
    top:35%;
    left: 3%;
   }

   #about-svg-4{
    position: absolute;
    bottom: 2%;
    left: 25%;
   }

   #about-svg-1, #about-svg-2,#about-svg-3,#about-svg-4{
    animation: 3s infinite technologies-svg linear;
}


@keyframes technologies-svg {
    0%{
        transform: rotate(0deg);
    } 
    50%{
        transform: rotate(60deg);
    } 
}

/* Responsive laptop*/
@media only screen and (max-width: 1537px) {


    .about-main-container {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }


    .About-Us h1 {
        font-weight: 700;
        color: #FF8402;
        margin: 0;
        font-size: 45px
    }

    #about-svg-1 {
        position: absolute;
        top: 10%;
        left: 20%;
    }

    #about-svg-2{
        right: 20%;
    }

    #about-svg-4{
        left: 20%;
        bottom: 0%;
    }

    .About-Us  p {
        font-size: 20px;
        height: 52%;
    }
    .form {
        width: 31% !important;
    }

}

/* Responsive for Apple laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)){

    .about-div-one-left-img {
        height: 60%;
    }
    .about-div-one-left-img  img {
        width: 75%; 
    }

    .About-Us h1 {
        font-size: 35px;
    }

    .About-Us  p {
        font-size: 17px;
    }

    .about-image-second {
        height: 72%;
  
    }
    .about-div-one-right {
        justify-content: space-between;
        padding-top: 10%;
    }

  
}

@media (min-width:667px ) and (max-width:1081px) {
    .About-Us h1 {
        font-size: 28px;
    }
    .About-Us  p {
        font-size: 10px;
    }
    #about-svg-2 {
        right: 20%;
        width: 30px;
        height: auto;
        top: 10%;
    }
}

@media (min-width: 1081px) and (max-width: 1366px) {
    .About-Us  p {
        font-size: 18px;
        height: 52%;
    }
    .About-Us h1 {
        font-weight: 700;
        color: #FF8402;
        margin: 0;
        font-size: 42px;
    }
}

/* Responsive for ipad mini, ipad pro, and ipad  */
@media (min-width: 820px) and (max-width: 1080px){
    .about-main-container{
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .about-sub-container{
        height: 100%;
        width: 100%;
    }
    .sub-about-div-one {
        height: 100%;
        width: 100%;
        padding-left: 0px;
        display: flex;
        flex-direction: column-reverse;
        box-sizing: border-box;
        padding: 15px;
    }

    .about-div-one-right {
        height: 60%;
        width: 100%;
        padding-right: 15px;
    }
    .About-Us {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .About-Us h1 {
        font-size: 70px;
    }
    .About-Us  p {
        font-size: 30px !important;
    }
    .about-image-second {
        height: 55%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .about-image-second  img {
        height: 100%;
        width: 95%;
        margin-top: 130px;
    }
    .about-div-one-left-img{
        height: 50%;
        width: 100%;
    }
    
    #about-svg-1,#about-svg-2,  #about-svg-3,  #about-svg-4{
        display: none;
    }

    .about-div-one-left-img  img {
        height: 65%;
        width: 95%;
        background-size: cover;
        margin-top: 40px;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .about-main-container{
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .about-sub-container{
        height: 100%;
        width: 100%;
    }
    .sub-about-div-one {
        height: 100%;
        width: 100%;
        padding-left: 0px;
        display: flex;
        flex-direction: column-reverse;
        box-sizing: border-box;
        padding: 15px;
    }

    .about-div-one-right {
        height: 60%;
        width: 100%;
        padding-right: 15px;
    }
    .About-Us {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .About-Us h1 {
        font-size: 55px;
    }
    .About-Us  p {
        font-size: 25px !important;
    }
    .about-image-second {
        height: 55%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .about-image-second  img {
        height: 100%;
        width: 95%;
        margin-top: 130px;
    }
    .about-div-one-left-img{
        height: 50%;
        width: 100%;
    }
    
    #about-svg-1,#about-svg-2,  #about-svg-3,  #about-svg-4{
        display: none;
    }

    .about-div-one-left-img  img {
        height: 65%;
        width: 95%;
        background-size: cover;
        margin-top: 40px;
    }
}

/* Responsive For mobile */
@media screen and (max-width: 767px) {
    .about-main-container{
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
    .about-sub-container{
        height: 100%;
        width: 100%;
    }
    .sub-about-div-one {
        height: 100%;
        width: 100%;
        padding-left: 0px;
        display: flex;
        flex-direction: column-reverse;
        box-sizing: border-box;
        padding: 15px;
    }

    .about-div-one-right {
        height: 60%;
        width: 100%;
        padding-right: 15px;
    }
    .About-Us {
        height: 30%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .About-Us h1 {
        font-size: 30px;
    }
    .About-Us  p {
        font-size: 15px !important;
    }
    .about-image-second {
        height: 55%;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .about-image-second  img {
        height: 93%;
        width: 100%;
        margin-top: 20px;
    }
    .about-div-one-left-img{
        height: 50%;
        width: 100%;
    }
    
    #about-svg-1,#about-svg-2,  #about-svg-3,  #about-svg-4{
        display: none;
    }

    .about-div-one-left-img  img {
        height: 80%;
        width: 99%;
        background-size: cover;
    }
} 