.E_learning-main-container-content{

    box-sizing: border-box;
    width: 100%;
    height: auto;
}

.E_learning-sub-container-content{
    width: 100%;
    height: 100vh;
}

.E_learning_div-1{
    height: 100vh;
    width: 100%;
    padding-top: 80px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: relative;
    
}

.E_learning_content{
    height: 100%;
    width: 40%;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.E_learning_content_div{
    height: 80%;
    width: 80%;
    /* background-color: rgb(18, 152, 13); */
}

#E_learning_bielearn_heading{
    font-size: 50px;
    color: black;
    text-align: left;
    font-weight: 700;
}

.E_learning_bielearn_para{
    width: 80%;
    /* background-color: wheat; */
    height: 50%;
    font-size: 25px;
    text-align: left;
    color: black;
}

.E_learning_image_container{
    height: 100%;
    /* background-color:violet ; */
    width: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.E_learning_image_container_1{
    height: 70%;
    /* background-color:blue ; */
    width: 80%;
    border-radius: 50px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 10px 10px 5px 5px rgb(145, 141, 141);
}

#Bielearn_background,#iot_background{
    height: 75%;
}

#Bielearn_mobile,#iot-front-mobile{
    position: absolute;
    left: -35%;
}

#iot-front-mobile{
    left: -250px;
}

#marchecinq {
    height: 85%;
    width: 93%;
}

#GetNEat-2 {
    height: 80%;
    width: 94%;
}

#Washmart-background,#Signigraf-background{
    height: 80%;
}

#svg-1,#svg-2,#svg-3,#svg-4,#svg-5{
    position: absolute;
    top: 10%;
}

#svg-2 {
    left: 5%;
    top: 16%;
}
#svg-3 {
    top: 14%;
    left: 30%;
}

#svg-4{
    top: 80%;
    left: 20%;
}

#svg-5{
    right: 3%;
    top: 80%;
}

#svg-1, #svg-2, #svg-3, #svg-4,#svg-5{
    animation: 3s infinite technologies-svg linear;
}
img#washmart-mobile {
    position: absolute;
    left: -17%;
    height: 70%;
    width: auto;
}


@keyframes technologies-svg {
    0%{
        transform: rotate(0deg);
    } 
    50%{
        transform: rotate(60deg);
    } 
}

/* Responsive for Apple Laptop */
@media only screen and ((max-width: 1080px) and (max-height: 713px)){
    .E_learning_bielearn_para {
        font-size: 20px;
    }
    #E_learning_bielearn_heading {
        font-size: 35px;
    }
    #Bielearn_background, #iot_background {
        height: 80%;
    }
    .E_learning_image_container_1 {
        height: 60%;
    }
    .E_learning_content {
        width: 45%;
    }
    #iot-front-mobile {
        left: -27%;
        height: 75%;
        top: 20%;
    }
    .E_learning_image_container {
        height: 85%;
    }
    #Bielearn_mobile, #iot-front-mobile {
        left: -27%;
        height: 75%;
        top: 17%;
    }
    .E_learning_content {
        width: 45%;
        padding-top: 3%;
    }
}

/* Responsive for Mobile */
@media screen and (max-width: 767px){
    /* Genaral */
    .E_learning-main-container-content{
        height: auto;
        width: 100%;
    }
    .E_learning_div-1{
        height: 100vh;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
        padding: 0;
    }

    .E_learning_content{
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .E_learning_image_container{
        height: 50%;
        width: 100%;

    }

    .E_learning_content_div{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .E_learning_content_div_1{
        height: 300px;
        width: 50%;
        
    }
    #iot_background{
        height: 200px;
    }
    .E_learning_image_container_1 {
        height: 250px;
        width: 220px;
        width: 80%;
        border-radius: 50px;
    }

    #iot-front-mobile{
        height: 150px;
        left: -70px;
    }
    .E_learning_bielearn_para{
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
        font-size: 20px;
    }
    #E_learning_bielearn_heading {
        margin-left: 10px;
    }

    #svg-1,#svg-2,#svg-3,#svg-4,#svg-5{
        display: none;
    }

    /* Game Development */
    #Bielearn_mobile{
        height: 160px;
        left: -70px;
    }
    img#washmart-mobile {
        position: absolute;
        left: -6%;
        height: 55%;
        width: auto;
    }
    
}



    
  /* Laptops and Desktops */
  @media (min-width: 1081px) and (max-width: 1366px) {
    .E_learning_image_container_1 {
        height: 50%;
        width: 80%;
        border-radius: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 6px 5px 5px rgb(145, 141, 141);
    }
    #iot-front-mobile,#Bielearn_mobile  {
        left: -37%;
        width: 70%;
        height: auto;
    }
    .E_learning_bielearn_para {
        width: 95%;
        height: 50%;
        font-size: 25px;
        text-align: left;
        color: black;
    }
    #Bielearn_background, #iot_background {
        width: 75%;
        height: auto;
    }
    #GetNEat-2 {
        height: auto;
        width: 75%;
    }
    #marchecinq {
        height: auto;
        width: 75%;
    }
    .E_learning_bielearn_para {
        width: 100%;
        height: 50%;
        font-size: 22px;
        text-align: left;
        color: black;
    }
    #E_learning_bielearn_heading {
        font-size: 46px;
        color: black;
        text-align: left;
        font-weight: 700;
    }
    #Washmart-background, #Signigraf-background {
        height: 88%;
        width: auto;
    }
    img#washmart-mobile {
        position: absolute;
        left: -65px;
        height: 80%;
        width: auto;
    }
  }
  @media (min-width: 1367px) and (max-width: 1600px) {
    .E_learning_image_container_1 {
        height: 59%;
        width: 80%;
        border-radius: 50px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 3px 6px 5px 5px rgb(145, 141, 141);
    }
    #iot-front-mobile,#Bielearn_mobile {
        left: -37%;
        width: 70%;
        height: auto;
    }
    .E_learning_bielearn_para {
        width: 95%;
        height: 50%;
        font-size: 25px;
        text-align: left;
        color: black;
    }
    #Bielearn_background, #iot_background {
        width: 75%;
        height: auto;
    }
    #GetNEat-2 {
        height: auto;
        width: 75%;
    }
    #marchecinq {
        height: auto;
        width: 75%;
    }
    #Washmart-background, #Signigraf-background {
        height: 88%;
        width: auto;
    }
    img#washmart-mobile {
        position: absolute;
        left: -90px;
        height: 80%;
        width: auto;
    }
    
}
@media (min-width: 2200px) {
    #E_learning_bielearn_heading {
        font-size: 100px;
    }
    .E_learning_bielearn_para {
        font-size: 35px;
    }
    #Bielearn_mobile, #iot-front-mobile {
        position: absolute;
        left: -27%;
    }
    img#washmart-mobile {
        position: absolute;
        left: -90px;
        height: 80%;
        width: auto;
    }

}
  
@media (min-width: 820px) and (max-width: 1080px) {
   
    .E_learning_div-1 {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }
    .E_learning-main-container-content{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 5%;
    }
    
    .E_learning-sub-container-content{
        height: 100%;
        width: 100%;
    }
    #svg-1, #svg-2, #svg-3, #svg-4, #svg-5{
        display: none;
    }


    .E_learning_image_container{
        height: 40%;
        width: 100%;
    }
    .E_learning_image_container_1{
        height: 90%;
        width: 70%;
        padding: 0;
        margin: 0;
    }
    .Bielearn_background{
        height: 100%;
        width: 100%;
    }

    .E_learning_content{
        height: 50%;
        width: 100%;
    }
    #Bielearn_background, #iot_background {
        height: 100%;
        width: 100%;
    }
    #Bielearn_mobile, #iot-front-mobile {
        position: absolute;
        left: -35%;
        height: 90%;
        top: 8%;
    }
    .E_learning_content_div{
        height: 60%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        text-align: left;
        padding: 0;
    }

    #E_learning_bielearn_heading {
        font-size: 70px;
        color: black;
        text-align: left;
        font-weight: 700;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        height: 10%;
    }

    .E_learning_bielearn_para {
        width: 100%;
        /* background-color: wheat; */
        height: 100%;
        text-align: left;
        color: black;
        font-size: 42px;
        box-sizing: border-box;
        padding: 3%;
    }

    img#washmart-mobile{
        position: absolute;
        left: -15%;
        height: 70%;
        top: 20%;
    }
    #Bielearn_background, #iot_background {
        height: 90%;
        width: 92%;
    }

  }

@media (min-width: 768px) and (max-width:819px){
    .E_learning-main-container-content{
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        padding-top: 5%;
    }
    
    .E_learning-sub-container-content{
        height: 100%;
        width: 100%;
    }
    #svg-1, #svg-2, #svg-3, #svg-4, #svg-5{
        display: none;
    }

    .E_learning_div-1{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column-reverse;
    }

    .E_learning_image_container {
        height: 36%;
        width: 90%;
    }
    .E_learning_image_container_1{
        height: 90%;
        width: 70%;
        padding: 0;
        margin: 0;
    }
    .Bielearn_background{
        height: 100%;
        width: 100%;
    }

    .E_learning_content{
        height: 50%;
        width: 100%;
    }
    #Bielearn_background, #iot_background {
        height: 90%;
        width: 90%;
    }
    #Bielearn_mobile{
        position: absolute;
        left: -41%;
        height: 100%;
        top: 0%;
    }

    #iot-front-mobile {
        height: 90%;
        top: 10%;
        left: -30%;
    }
    .E_learning_content_div{
        height: 60%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        text-align: left;
        padding: 0;
    }

    #E_learning_bielearn_heading {
        font-size: 70px;
        color: black;
        text-align: left;
        font-weight: 700;
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        height: 10%;
    }

    .E_learning_bielearn_para {
        width: 100%;
        /* background-color: wheat; */
        height: 100%;
        text-align: left;
        color: black;
        font-size: 42px;
        box-sizing: border-box;
        padding: 3%;
    }

    img#washmart-mobile{
        position: absolute;
        left: -15%;
        height: 70%;
        top: 20%;
    }
}

@media screen and (max-width: 767px) {
    .E_learning_image_container_1 {
        height: 227px;
        width: 315px;
        border-radius: 50px;
    }
    #E_learning_bielearn_heading {
        margin-left: 10px;
        margin-top: 0;
        margin-bottom: 10px;
    }
    #iot-front-mobile,#Bielearn_mobile  {
        height: 150px;
        left: -72px;
    }
    .E_learning_bielearn_para {
        width: 90%;
        padding: 15px;
        box-sizing: border-box;
        font-size: 18px;
    }
  }
  