.contact-main-container {

    height: 100%;
    width: 100%;
    background-color: rgb(39 45 47);
    box-sizing: border-box;
    padding-top: 45px;
    color: #fff;
    position: relative;

}

.contact-sub-container {
    height: 100%;
    width: 100%;
    /* background-color: rgb(38, 112, 176); */
    display: flex;
    flex-direction: column;


}

#contact-bottom-right-vector {
    position: absolute;
}

.contact-heading {
    height: 13%;
    width: 100%;
    /* background-color: antiquewhite; */
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat";
    font-size: 50px;
}

.contact-heading-middle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
}

.contact-heading>div {
    font-size: 50px;
    font-weight: 800;
}


#contact-right-vector {
    height: 50px;
    width: 50px;
    position: relative;
    top: -20px;
}

.contact-middle-container {
    height: 87%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


#contact-middle-white-vector {

    height: 50px;
    width: 40px;
    position: relative;
    left: 25px;
}

.contact-form {
    height: 100%;
    width: 80%;
    box-sizing: border-box;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../../public/images/contact-background.png');
    background-repeat: no-repeat;
    background-size: 100% 93%;
    position: relative;
}

.form {
    height: 90%;
    width: 32%;
    background-color: #424546;
    border-top-right-radius: 70px;
    box-shadow: 11px 11px 21px 0px #2222227d;
}

.form>form {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    position: relative;

}

.form>form>input {
    font-size: 20px;
    width: 80%;
    height: 30px;
    border: none;
    background: transparent;
    border-bottom: 2px solid #919B9E;
    color: #626262;
    color: #fff;
    font-family: 'Poppins';
}


form>input,
form>textarea {
    text-align: left;
    outline: none;
    resize: none;
    height: 150px;
}

form>label {
    text-align: left;
    color: #8D8D8D;
    font-weight: 400;
    font-size: 20px;
    display: inline-block;
    width: 80%;
    height: 2%;
    font-family: poppins;
}


#err_username,
#err_number,
#err_email,#err_usernameContact, #err_usernumberContact, #err_emailContact{
    font-size: 14px;
    position: absolute;
    top: 11%;
    color: rgb(222, 83, 83);
    font-family: 'Poppins';
    left: 10%;
    display: none;
}

#err_usernumberContact {
    top: 22%;
}

#err_emailContact{
    top: 33%;
}

form>textarea ::-webkit-scrollbar {
    display: block;
}


.form>form>textarea {
    width: 80%;
    height: 100px;
    background: transparent;
    border: 1px solid #919B9E;
    font-size: 20px;
    color: #fff;

}

form>div {
    height: 10%;
}

form>button {
    background-color: #8A8888;
    color: white;
    border: none;
    font-size: 20px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    border-radius: 10px;
    cursor: pointer;
    width: 100px;
    text-align: center;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.contact-circle>div {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;

}

.captcha-div {
    height: 15%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#captcha{
    transform: scale(0.77);
    -webkit-transform: scale(0.8s);
}

#threeDot {
    position: absolute;
    bottom: 10%;
    height: 10%;
    right: 30%;
}

#Group {
    position: absolute;
    right: 0;
    bottom: 30%;
}

#contact-vector-1 {
    position: absolute;
    left: 33%;
}

#contact-vector-2 {
    position: absolute;
    left: 68%;
}

#contact-vector-3 {
    position: absolute;
    left: 5%;
    top: 40%;
}

#contact-vector-4 {
    position: absolute;
    left: 30%;
    top: 85%;
}

/* Animation for Contact */

#contact-vector-1,
#contact-vector-2,
#contact-vector-3,
#contact-vector-4 {
    animation: 3s infinite technologies-svg linear;
}


#captcha iframe {
    width: 100%;
}

.captcha-container {
    max-width: 500px;
    width: 90%;
    text-align: center;
}

.captcha-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.captcha-refresh {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    margin-top: 10px;
    display: inline-block;
}

.rc-anchor-normal .rc-anchor-content {
    height: 74px;
    width: 60%;
}


@keyframes technologies-svg {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(60deg);
    }
}



#contact-arrow {
    animation: 3s infinite svg-bounce linear;
}

@keyframes svg-bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}
/* End animation for Conatct */


@media (min-width: 769px) and (max-width: 1080px) {

    .contact-heading{
        font-size: 30px;
        width: 100%;
    }

    .contact-middle-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
    }
    .contact-heading-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 100%;
    }
    .contact-left-logos{
        display:none;
    }

   .contact-form{
        height: 100%;
        width: 100%;
        padding-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        overflow-x: hidden;
    }
    
    .form{
        height: 100%;
        width: 60% !important;
        padding: 10px;
        box-sizing: border-box;
        background-color: rgb(39 45 47);;
        margin-left: 30px;
        font-family: 'Poppin';
    }
    .form> form {
        justify-content: flex-start;
        gap: 30px;
    }

    form > button{
        font-size: 25px;
        width: 100px;
        text-align: center;
    }

   #label{
    top: 0px;
   }


   .captcha-div{
    width: 82%;
    height: 15%;
    display: inline-block;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    
   }

   #captcha{
    transform:scale(0.77);
    -webkit-transform:scale(0.9999);
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
 
   }

   .contact-right-logos{
    height: 85vh;
    width: 20%;
    /* background-color: yellow; */
   }

   #contact-right-bottom-logo-1{
    height: 70px;
    width: 70px;
   }
   #contact-circle-1, #contact-circle-2, #contact-circle-3{
    height: 10px;
    width: 10px;
    left: 1px;

   }

   .group-logo{
    width: 60px;
    height: 70px;
    margin-left: 15px;
   }

   .contact-right-logos{
    display: none;
   }

   #contact-vector-1, #contact-vector-2, #threeDot, #Group{
    display: none;
   }
   .contact-form {
    background-image: none;
}

}

/* Responsive for Apple laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)) {

    .form {
        width: 50%;
    }

    .contact-heading {
        font-size: 40px;
    }
}


/* @media (min-width:667px ) and (max-width:1081px) {
    .form>form>input {
        font-size: 15px;
        width: 80%;
        height: 30px;
        border: none;
        background: transparent;
        border-bottom: 2px solid #919B9E;
        color: #626262;
        color: #fff;
        font-family: 'Poppins';
    }


    form>label {
        text-align: left;
        color: #8D8D8D;
        font-weight: 400;
        font-size: 15px !important;
        display: inline-block;
        width: 80%;
        height: 2%;
        font-family: poppins;
    }
    form>button {
        font-size: 15px;
        width: 28%;
        text-align: center;
        padding: 0;
        width: 100px;
        margin-bottom: 2%;
    }
    .captcha-div {
        justify-content: center;
        margin-left: 45%;
    }
    .homeContact {
            height: 100%;
            width: 40% !important;
            padding: 10px;
            box-sizing: border-box;
            background-color: rgb(39 45 47);
            margin-left: 30px;
            font-family: 'Poppin';
        
    }
    .homeContact .captcha-div {
        justify-content: center;
        margin-left: 45%;
    }
    .homeContact .captcha-div {
        justify-content: center;
        margin-left: 30%;
        margin-top: 5%;
    }
} */

@media (min-width: 1081px) and (max-width: 1366px) {


    form>button {
        background-color: #8A8888;
        color: white;
        border: none;
        font-size: 20px;
        font-weight: 600;
        line-height: 38px;
        letter-spacing: 0em;
        text-align: left;
        border-radius: 10px;
        cursor: pointer;
        width: 100px;
        text-align: center;
    }

    .form> form > input {
        font-size: 17px;
        width: 80%;
        height: 30px;
        border: none;
        background: transparent;
        border-bottom: 2px solid #919B9E;
        color: #626262;
        color: #fff;
        font-family: 'Poppins';
    }


    form>label {
        text-align: left;
        color: #8D8D8D;
        font-weight: 400;
        font-size: 17px !important;
        display: inline-block;
        width: 80%;
        height: 2%;
        font-family: poppins;
    }
    #err_usernameContact{
        top: 12%;
    }
    #err_usernumberContact{
        top: 24%;
    }
    #err_emailContact{
        top: 36.5%;
    }

}
@media (min-width: 1367px) and (max-width: 1600px) {
    form > label {
        top: 0px;
        font-size: 20px !important;
    }
    
    #err_usernameContact{

    }
    #err_usernumberContact{
        top: 23%;
    }
    #err_emailContact{
        top: 35%;
    }
}

/* Responsive for Ipad air and ipad mini */
@media (min-width: 820px) and (max-width: 1080px){
    .contact-heading{
        font-size: 70px;
        width: 100%;
    }

    .contact-middle-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
    }
    .contact-heading-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 100%;
    }
    .contact-left-logos{
        display: none;
    }

   .contact-form{
        height: 100%;
        width: 100%;
        padding-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        overflow-x: hidden;
    }
    
    
    .form{
        height: 100%;
        width: 100% !important;
        padding: 10px;
        box-sizing: border-box;
        background-color: rgb(39 45 47);;
        margin-left: 30px;
        font-family: 'Poppin';
    }
    .form> form {
        justify-content: space-evenly;
        gap: 0px !important;
    }
    
    form > button{
        font-size: 40px;
        width: 20%;
        height: 8%;
        text-align: center;
    }

   #label{
    top: 0px;
    font-size: 40px;
   }


   .captcha-div{
    width: 82%;
    height: 15%;
    display: inline-block;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 0px;
    
   }

   #captcha{
    transform:scale(0.77);
    -webkit-transform:scale(0.7) !important;
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    margin-left: 20px;
   }

   .contact-right-logos{
    height: 85vh;
    width: 20%;
   }

   #contact-right-bottom-logo-1{
    height: 70px;
    width: 70px;
   }
   #contact-circle-1, #contact-circle-2, #contact-circle-3{
    height: 10px;
    width: 10px;
    left: 1px;
   }

   .group-logo{
    width: 60px;
    height: 70px;
    margin-left: 15px;
   }

   .contact-right-logos{
    display: none;
   }

   #contact-vector-1, #contact-vector-2, #threeDot, #Group{
    display: none;
   }
   .contact-form {
    background-image: none;
    }

    .form > form > textarea {
        width: 80%;
        height: 60px !important;
    }
    #err_usernameContact{
        top: 10%;
        font-size: 28px;
    }
    #err_usernumberContact {
        top: 21%;
        font-size: 28px;
    }
    #err_emailContact{
        top: 32.5%;
        font-size: 28px;
        
    }
    .form > form > textarea {
        width: 80%;
        height: 150px !important;
        font-size: 40px;
    }
    #label{
        font-size: 40px;
        font-family: 'Poppins';
    }
    #captcha{
        -webkit-transform: scale(1.5) !important;
        margin-right: 28%;
        
    }
    #userContactName, #userContactNumber, #userContactEmail{
        font-size: 35px;
        height: 50px;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .contact-heading{
        font-size: 60px;
        width: 100%;
    }

    .contact-middle-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
    }
    .contact-heading-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 100%;
    }
    .contact-left-logos{
        display: none;
    }

   .contact-form{
        height: 100%;
        width: 100%;
        padding-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        overflow-x: hidden;
    }
    
    .form{
        height: 100%;
        width: 100% !important;
        padding: 10px;
        box-sizing: border-box;
        background-color: rgb(39 45 47);;
        margin-left: 30px;
        font-family: 'Poppin';
    }
    .form> form {
        justify-content: space-evenly;
        gap: 0px !important;
    }

    form > button{
        font-size: 40px;
        width: 20%;
        height: 8%;
        text-align: center;
    }

   #label{
    top: 0px;
    font-size: 40px;
   }


   .captcha-div{
    width: 82%;
    height: 15%;
    display: inline-block;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 0px;
    
   }

   #captcha{
    transform:scale(0.77);
    -webkit-transform:scale(0.7) !important;
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    margin-left: 20px;
   }

   .contact-right-logos{
    height: 85vh;
    width: 20%;
   }

   #contact-right-bottom-logo-1{
    height: 70px;
    width: 70px;
   }
   #contact-circle-1, #contact-circle-2, #contact-circle-3{
    height: 10px;
    width: 10px;
    left: 1px;
   }

   .group-logo{
    width: 60px;
    height: 70px;
    margin-left: 15px;
   }

   .contact-right-logos{
    display: none;
   }

   #contact-vector-1, #contact-vector-2, #threeDot, #Group{
    display: none;
   }
   .contact-form {
    background-image: none;
    }

    .form > form > textarea {
        width: 80%;
        height: 60px !important;
    }
    #err_usernameContact{
        top: 12%;
        font-size: 20px;
    }
    #err_usernumberContact {
        top: 24.5%;
        font-size: 20px;
    }
    #err_emailContact{
        top: 36.5%;
        font-size: 20px;
        
    }
    .form > form > textarea {
        width: 80%;
        height: 125px !important;
        font-size: 40px;
    }
    #label{
        font-size: 30px;
        font-family: 'Poppins';
    }
    #captcha{
        -webkit-transform: scale() !important;
        margin-right: 18%;
    }
    #userContactName, #userContactNumber, #userContactEmail{
        font-size: 28px;
        height: 50px;
    }
}

/* Responsive for Mobile */
@media screen and (max-width: 767px){
    .contact-heading{
        font-size: 30px;
        width: 100%;
    }

    .contact-middle-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
    }
    .contact-heading-middle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60%;
        height: 100%;
    }
    .contact-left-logos{
        display: none;
    }

   .contact-form{
        height: 100%;
        width: 100%;
        padding-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
        overflow-x: hidden;
    }
    
    .form{
        height: 100%;
        width: 100% !important;
        padding: 10px;
        box-sizing: border-box;
        background-color: rgb(39 45 47);;
        margin-left: 30px;
        font-family: 'Poppin';
    }
    .form> form {
        justify-content: flex-start;
        gap: 30px;
    }

    form > button{
        font-size: 25px;
        width: 100px;
        text-align: center;
    }

   #label{
    top: 0px;
   }


   .captcha-div{
    width: 82%;
    height: 15%;
    display: inline-block;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 30px;
    
   }

   #captcha{
    transform:scale(0.77);
    -webkit-transform:scale(0.7) !important;
    transform-origin:0 0;
    -webkit-transform-origin:0 0;
    margin-left: 20px;
   }

   .contact-right-logos{
    height: 85vh;
    width: 20%;
   }

   #contact-right-bottom-logo-1{
    height: 70px;
    width: 70px;
   }
   #contact-circle-1, #contact-circle-2, #contact-circle-3{
    height: 10px;
    width: 10px;
    left: 1px;
   }

   .group-logo{
    width: 60px;
    height: 70px;
    margin-left: 15px;
   }

   .contact-right-logos{
    display: none;
   }

   #contact-vector-1, #contact-vector-2, #threeDot, #Group{
    display: none;
   }
   .contact-form {
    background-image: none;
    }

    .form > form > textarea {
        width: 80%;
        height: 60px !important;
    }
    #err_usernameContact{
        top: 31.9px;
        font-size: 11px;
    }
    #err_usernumberContact {
        top: 97px;
        font-size: 11px;
    }
    #err_emailContact{
        top: 160px;
        font-size: 11px;
    }
    .form > form > textarea {
        width: 80%;
        height: 70px !important;
    }
    #label{
        font-size: 18px;
        font-family: 'Poppins';
    }
}