.portfolio-main-container{
    height: 100vh;
    width: 100%;
    background-color: #444647;
    box-sizing: border-box;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    position: relative;
    padding-top: 3%;
}

.portfolio-heading {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    /* background-color: violet; */
}

.portfolio-heading-subdiv{
    height: 100%;
    width: 50%;
    /* background-color: antiquewhite; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.portfolio-heading-left-vector{
    height: 100%;
    width: 20%;
    /* background-color: blue; */
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 15px;
}


.portfolio-heading-mainheading{
    height: 100%;
    width: 60%;
    /* background-color: rgb(32, 158, 74); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.portfolio-heading-mainheading > span {
    font-size: 70px;
    font-weight: 700;
    color: white;
}
.portfolio-heading-mainheading > h3{
    position: relative;
    top: 10px;
    font-size: 25px;
    color: white;
}

.portfolio-heading-right-vector{
    height: 100%;
    width: 20%;
    /* background-color: rgb(209, 172, 23); */
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    
}

.portfolio-main-Slider{
    height: 70%;
    width: 100%;
    /* background-color: antiquewhite; */
    box-sizing: border-box;
    margin-top: 20px;
    
}

.slider{
    height:110%;
    width: 100%;
    overflow-x: scroll;
    box-sizing: border-box;
    

}

.actual-slider {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    flex-direction: row;
    animation: slider 10s infinite linear;
    margin-left: 3%;
}

@keyframes slider {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50);
    }
}
.Slider-card-1{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Slider-card-content{
    height: 90%;
    width: 90%;
    background-image: url('../../../../public/images/port_Game_Development.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 3%;
    color: #272D2F;
}


.Cms-background{
    background-image: url('../../../../public/images/port_Cms.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    
}

.Iot-background{
    background-image: url('../../../../public/images/port_iot.png');

}

.Ecommerce-background{
    background-image: url('../../../../public/images/port_Ecommerce.png');

}

.ELearning-background{
    background-image: url('../../../../public/images/port_E_learning.png'); 
    width: 97%; 

}

.GameDevelopment-background{
    background-image: url('../../../../public/images/port_Game_Development.png');
    padding-right: 15px;
    width: 95%;
}

.SocialMedia-background{
    background-image: url('../../../../public/images/port_Social_Media.png');
    padding-right: 10px;
    width: 95%;

}

.cms-card-content{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 40%;
    /* background-color: red; */
}
.card-content-heading-para{
    height: 40%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background-color:red; */
}

.card-content-heading-para h1 {
    width: 148%;
    margin-top: 10%;
    font-size: 25px;
}


.slider-card-1 {
    height: 100%;
    width: 100%;
    margin-left: 15px;
    border-radius: 25px;
    box-sizing: border-box;
    /* background-color: yellow; */
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
}


::-webkit-scrollbar{
    display: none;
}


#bottom-left-vector{
    position: absolute;
    top: 860px;
    left: 650px;
    height: 50px;
    width: 50px;
    z-index: 0;

}

.posvg1{
    right: 7%;
    bottom: 1%;
    position: absolute;
    display: inline-block;
}
.posvg2{
    right: 0;
    bottom: 0;
    position: absolute;
    display: inline-block;
}
.slider-bottom-circles{
    height: 100%;
    width: 10%;
    /* background-color: antiquewhite; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.portfolio-bottom-corner-logo{
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
 
    margin-top: 6px;
}

.slider-bottom > img {
    margin-top: 10px;
}
.actual-slider .swiper-slide-active .Slider-card-content{
    transform: scale(1.2);
}
.actual-slider .swiper{
    height: 100%;
}
.card-content-heading-para p {
    height: 50%;
    font-size: 18px;
}



#portfolio-vector-2{
    position: absolute;
    left: 80%;
    top:10%;
}


#portfolio-vector-3 {
    position: absolute;
    left: 30%;
    bottom: 7%;
}

#portfolio-vector-4{
    position: absolute;
    left: 63%;
    top: 85%;
}

#portfolio-arrow {
    position: absolute;
    left: 50%;
   bottom: 2%;
}


/* Animation start for Portfolio  */
#portfolio-vector-1, #portfolio-vector-2, #portfolio-vector-3, #portfolio-vector-4{
    animation: 3s infinite technologies-svg linear;
}


@keyframes technologies-svg {
    0%{
        transform: rotate(0deg);
    } 
    50%{
        transform: rotate(60deg);
    } 
}



#portfolio-arrow{
    animation: 3s infinite svg-bounce linear;
}


@media screen and (max-width: 1920px) {
    .actual-slider .swiper-wrapper>div {
        width: 18% !important;
    }
}

@keyframes svg-bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-30px);} 
   60% {transform: translateY(-15px);} 
}
/* End animation for portfolio */



#portfolio-vector-2{
    position: absolute;
    left: 80%;
    top:10%;
}


#portfolio-vector-4{
    position: absolute;
    left: 63%;
    bottom: 12%;
}

#portfolio-arrow {
    position: absolute;
    left: 48%;
   bottom: 3%;
}


/* Animation start for Portfolio  */
#portfolio-vector-1, #portfolio-vector-2, #portfolio-vector-3, #portfolio-vector-4{
    animation: 3s infinite technologies-svg linear;
}


@keyframes technologies-svg {
    0%{
        transform: rotate(0deg);
    } 
    50%{
        transform: rotate(60deg);
    } 
}



#portfolio-arrow{
    animation: 3s infinite svg-bounce linear;
}


@keyframes svg-bounce {
   0%, 20%, 50%, 80%, 100% {transform: translateY(0);} 
   40% {transform: translateY(-30px);} 
   60% {transform: translateY(-15px);} 
}
/* End animation for portfolio */


/* Responsive for  Mobile*/
/* @media (max-width: 320px){
    .portfolio-main-container{
        padding-top: 20%;
    }

    .ELearning-background {
        width: 60%;
    }
    #portfolio-vector-1,#portfolio-vector-2, #portfolio-vector-3, #portfolio-vector-4, #portfolio-arrow{
        display: none !important;
    }

    .portfolio-heading-left-vector{
        display: none;
    }
    .portfolio-heading-mainheading > span {
        font-size: 30px !important;
    }
    .portfolio-heading-mainheading{
        width: 100%;
    }
    .portfolio-heading-right-vector{
        display: none;
    }
} */


/* Responsive */
@media only screen and (max-width: 1537px) {

    .portfolio-heading-mainheading > span {
        font-size: 50px;
    }


    .card-content-heading-para h1 {
        /* background-color: tomato; */
        font-size: 25px;
        box-sizing: border-box;
        padding-top: 5%;
    }
    .card-content-heading-para {
        height: 40%;
        width: 87%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;
        padding: 2%;
    }

    #card-iot-heading{
        margin-top: 40px;
    }

    #card-game-development-heading{
        margin-top: 60px;
    }

    .portfolio-heading {
        height: 9%;
    }
    .contact-heading{
        margin-top: 2%;
    }
    .actual-slider {
        margin-left: 1%;
    }
}

/* Responsive for Apple laptop */
 @media screen and ((max-width: 1080px) and (max-height: 713px)) {
    
    .portfolio-heading {
        height: 10%;
    }
    .portfolio-heading-mainheading > span {
        font-size: 40px;
    }
    #product-white-Vector {
        left: 1%;
        top: 30%;
        height: 5%;
    }
  
}

/*laptop*/
@media (min-width: 1081px) and (max-width: 1366px) {
    .portfolio-heading {
        height: 9%;
    }
    .Slider-card-content {
        height: 78%;
    }
    .card-content-heading-para h1 {
        padding-top: 0%;
        font-size: 20px;
    }
    #card-iot-heading {
        margin-top: 14px;
    }
    .card-content-heading-para p {
        height: 50%;
        font-size: 14px;
        width: 94%;
    }
    .Slider-card-content {
        background-size: 107% 100%;
    }
    #portfolio-arrow {
        position: absolute;
        left: 48%;
        bottom: 3%;
        width: 5%;
        height: auto;
    }
    .GameDevelopment-background {
        padding-right: 0px;
    }
    .card-content-heading-para h1 {
        width: 100%;
    }
    .card-content-heading-para h1 {
        width: 100%;
        margin-top: 10%;
        font-size: 22px;
    }
    #card-game-development-heading {
        margin-top: 29px;
    }
    .card-content-heading-para p {
        height: 50%;
        font-size: 15px;
    }
    .Slider-card-content {
        background-size: 100% 100%;
    }
    .card-content-heading-para p {
        height: 50%;
        font-size: 14px;
    }
    #card-iot-heading {
        margin-top: 15px;
    }

}

@media (min-width: 1367px) and (max-width: 1600px) {
    .card-content-heading-para h1 {
        font-size: 22px;
        box-sizing: border-box;
        padding-top: 5%;
        width: 100%;
    }
    .card-content-heading-para p {
        height: 82px;
        font-size: 15px;
        margin-right: 7px;
    }
    #card-iot-heading {
        margin-top: 15px;
    }
}


/* Responsive for ipad pro and ipad air */
@media (min-width: 820px) and (max-width: 1080px){
   
    .portfolio-main-container{
        height: 100%;
        width: 100%;
    }

    .portfolio-heading{
        height: 10%;
        width: 100%;

    }
    .portfolio-heading-subdiv{
        height: 100%;
        width: 100%;
    }
    .portfolio-heading-mainheading{
        height: 100%;
        width: 100%;
        gap: 3px;

    }
    .portfolio-heading-mainheading > span {
        font-size: 70px;
    }
    .portfolio-heading-mainheading > h3 {
        position: relative;
        top: 10px;
        font-size: 40px;
        color: white;
    }

    .portfolio-heading-left-vector, .portfolio-heading-right-vector, .posvg2, .posvg1,#portfolio-vector-3,#portfolio-vector-4, #portfolio-arrow{
        display: none;
    }

    .portfolio-main-Slider {
        height: 79%;
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .actual-slider {
        margin-left: 1%;
    }
    .slider{
        height: 100%;
        width: 100%;
    }
    .Slider-card-content {
        height: 90%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        padding-top: 3%;
        color: #272D2F;
    }

    .Slider-card-1 {
        height: 112%;
        width: 600px;
        margin-left: 185px;
    }
    .actual-slider .swiper-wrapper>div {
        width: 84% !important;
    }

    .card-content-heading-para h1 {
        /* background-color: tomato; */
        font-size: 45px;
        box-sizing: border-box;
        /* padding-top: 5%; */
    }
    .card-content-heading-para p {
        height: 50%;
        font-size: 40px;
    }

}

@media (min-width: 768px) and (max-width: 819px){
    .portfolio-main-container{
        height: 100%;
        width: 100%;
    }

    .portfolio-heading{
        height: 10%;
        width: 100%;

    }
    .portfolio-heading-subdiv{
        height: 100%;
        width: 100%;
    }
    .portfolio-heading-mainheading{
        height: 100%;
        width: 100%;
        gap: 3px;

    }
    .portfolio-heading-mainheading > span {
        font-size: 60px;
    }
    .portfolio-heading-mainheading > h3 {
        position: relative;
        top: 10px;
        font-size: 35px;
        color: white;
    }

    .portfolio-heading-left-vector, .portfolio-heading-right-vector, .posvg2, .posvg1,#portfolio-vector-3,#portfolio-vector-4, #portfolio-arrow{
        display: none;
    }

    .portfolio-main-Slider {
        height: 79%;
        width: 100%;
        box-sizing: border-box;
        margin-top: 20px;
    }

    .actual-slider {
        margin-left: 1%;
    }
    .slider{
        height: 100%;
        width: 100%;
    }
    .Slider-card-content {
        height: 90%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        padding-top: 3%;
        color: #272D2F;
    }

    .Slider-card-1 {
        height: 112%;
        width: 500px;
        margin-left: 185px;
    }
    .actual-slider .swiper-wrapper>div {
        width: 84% !important;
    }

    .card-content-heading-para h1 {
        /* background-color: tomato; */
        font-size: 40px;
        box-sizing: border-box;
        /* padding-top: 5%; */
    }
    .card-content-heading-para p {
        height: 50%;
        font-size: 30px;
    }
}

@media screen and (max-width: 767px){
    .portfolio-main-container{
        height: 100%;
        width: 100%;
    }

    .portfolio-heading{
        height: 10%;
        width: 100%;

    }
    .portfolio-heading-subdiv{
        height: 100%;
        width: 100%;
    }
    .portfolio-heading-mainheading{
        height: 100%;
        width: 100%;
        gap: 3px;

    }
    .portfolio-heading-mainheading > span {
        font-size: 30px;
    }
    .portfolio-heading-mainheading > h3 {
        position: relative;
        top: 10px;
        font-size: 20px;
        color: white;
    }
    .portfolio-heading-left-vector, .portfolio-heading-right-vector, .posvg2, .posvg1,#portfolio-vector-3,#portfolio-vector-4, #portfolio-arrow{
        display: none;
    }

    .slider{
        height: 100%;
        width: 100%;
    }

    .Slider-card-1{
        height: 110%;
        width: 300px;
        margin-left: 90px;
    }
    .actual-slider .swiper-wrapper>div {
        width: 84% !important;
    }

}
