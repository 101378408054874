.testimonials {
    height: 100%;
    width: 100%;
}

.testimonials-container {
    width: 100%;
    height: 100%;
    background-image: url("../../../../../public/images/Rectangle\ 47backgroundtestimonials.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    position: relative;
}

.testimonials-vectors {
    position: absolute;
    right: 4%;
    top: 3%;
}

.testimonials-info {
    padding-top: 5%;
    width: 66%;
    height: 85%;
}

.testimonials-header h1 {
    font-family: Quicksand;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    color: #695B7D;
    margin-bottom: 0px;

}

.testimonials-header hr {
    width: 15%;
    margin-top: 0;
    height: 4px;
    border-width: 0px;
    color: #695b7d;
    background-color: #695b7d;
    border-radius: 75px;
}

.testimonials-rating {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    height: 85%;
}

.testimonials-rating-left {
    height: 83%;
    width: 50%;
}

.testimonials-rating-right {
    height: 100%;
    width: 50%;
}

.testimonials-rating-left-first {
    width: 600px;
    height: 274px;
    border-radius: 20px;
    box-shadow: 2px 2px 20px 0px #695B7D40;
    background: #FFFFFF;
}

.rating-header {
    display: flex;
    width: 100%;
    height: 45%;
    flex-direction: row;
    padding-top: 20px;
    align-items: center;
}

.rating-profile {
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rating-per-info {
    text-align: left;
}

.rating-per-info h3 {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
    margin-top: 5px;
}

.rating-per-info p {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 4px 0px;
}

.rating-text {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    width: 91%;
    margin: 8px 28px;
}

.f1 {
    height: 219px;
    margin-bottom: 30px;
}

.f2 {
    height: 320px;
}

.r-f2 {
    padding-top: 0;
}

.f3 {
    height: 260px;
    margin-bottom: 30px;
}

.r-f3 {
    padding-top: 8px;
}

.see-more {
    position: absolute;
    bottom: 2%;
    display: flex;
    align-items: center;
    right: 18%;
}

.see-more P {
    margin-right: 10px;
    font-family: Quicksand;
    font-size: 30px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    color: #682DB1;
}

@media only screen and (max-width: 1537px) {
    .testimonials-info {
        padding-top: 1.5%;
    }

    .testimonials-rating-left-first {
        width: 465px;
    }

    .f1 {
        height: 190px;
        margin-bottom: 12px;
    }

    .f2 {
        height: 250px;
    }

    .f3 {
        height: 220px;
        margin-bottom: 10px;
    }

    .testimonials-rating-left {
        width: 48%;
    }
}

/* Responsive for Mobile */
@media only screen and ((max-width: 1080px) and (max-height: 713px)) {

    .testimonials-info {
        padding-top: 1.5%;
    }

    .testimonials-rating-left-first {
        width: 465px;

    }

    .f1 {
        height: 209px;
        margin-bottom: 12px;
        width: 412px;

    }

    .f2 {
        height: 230px;
        width: 412px;

    }

    .f3 {
        height: 220px;
        margin-bottom: 10px;
        width: 412px;

    }

    .testimonials-rating-left {
        width: 48%;
        margin-right: 100px;
    }

    .rating-text {

        font-size: 15px;
        line-height: 20px;

    }

    .rating-profile img {
        height: 60px;
    }

    .rating-per-info h3 {

        font-size: 19px;

    }
}

/* Tablets */
@media (min-width: 601px) and (max-width: 768px){
    .testimonials {
        height: 120%;
        width: 100%;
    }

    .testimonials-container {
        height: 100%;
        width: 100%;
    }

    .testimonials-info {
        width: 100%;
        height: 100%;
    }

    .testimonials-rating {
        display: flex;
        flex-direction: column;
    }

    .testimonials-rating-left {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
    }

    .testimonials-rating-right {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-top: 13px;
    }

    .testimonials-vectors svg {
        height: 20px;

    }

    .f1,
    .f2 {
        width: 85%;
        font-size: 20px;
        height: 45%;
    }

    #admin_female_avatar {
        height: 70%;
    }

    .rating-text {
        font-size: 20px;
    }

    .rating-per-info img {
        height: 10px;
    }

    .rating-per-info h3 {
        font-size: 25px;
    }
    .rating-per-info p {
        font-size: 17px;
    }

    .testimonials-rating-right {
        height: 50%;
        width: 100%;
    }

    .f3 {
        width: 85%;
        height: 45%;
    }

    .rating-profile img {
        height: 70%;
    }


    .see-more P {
        font-size: 20px;
    }

    .see-more svg {
        height: 40px;
    }

    .see-more {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 10px -22px 22px;
        width: 60%;
    }

}

@media (min-width: 769px) and (max-width: 1080px) {
    .testimonials-info {
        padding-top: 2%;
    }

    .testimonials-rating-left {
        height: 83%;
        width: 50%;
    }

    .testimonials-rating-left-first {
        width: 96%;
        height: unset;
    }

    .f2 {
        height: 235px;
    }

    .r-f2 {
        padding-top: 0;
        height: 51%;
    }
    .rating-text {
        font-size: 16px;
    }
    .rating-per-info h3 {
        font-size: 16px;
    }
    .rating-profile img{
        height: auto;
        width: 80px;
    };
    .rating-header {
        padding-top: 11px;
    }
    .rating-per-info img{
        width: 65%;
        height: auto;
    }
    .testimonials-rating-left-first {
        margin-bottom: 20px;
    }
    .testimonials-info {
        width: 85%;
    }
    .f2{
        height: unset;
    }
    .see-more P {
        font-size: 25px;
    }
    .see-more-btn{
        height: auto;
        width: 35px;
    }
    .testimonials-rating-left {
        margin-right: 15px;
        margin-bottom: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
}

/* Laptops and Desktops */
@media (min-width: 1081px) and (max-width: 1366px) {
    .testimonials-info {
        padding-top: 2%;
    }

    .testimonials-rating-left {
        height: 83%;
        width: 50%;
    }

    .testimonials-rating-left-first {
        width: 96%;
        height: unset;
    }

    .f2 {
        height: auto;
    }

    .r-f2 {
        padding-top: 0;
        height: 51%;
    }
    .rating-text {
        font-size: 16px;
    }
    .rating-per-info h3 {
        font-size: 16px;
    }
    .rating-profile img{
        height: auto;
        width: 80px;
    };
    .rating-header {
        padding-top: 11px;
    }
    .rating-per-info img{
        width: 65%;
        height: auto;
    }
    .testimonials-rating-left-first {
        margin-bottom: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .testimonials-info {
        width: 85%;
    }
    .f2{
        height: unset;
    }
    .see-more P {
        font-size: 25px;
    }
    .see-more-btn{
        height: auto;
        width: 35px;
    }
    .rating-header {
        padding-top: 6px;
    }

}

@media (min-width: 1367px) and (max-width: 1600px) {
    .testimonials-info {
        padding-top: 2%;
    }

    .testimonials-rating-left {
        height: 83%;
        width: 50%;
    }

    .testimonials-rating-left-first {
        width: 96%;
        height: unset;
    }

    .f2 {
        height: auto;
    }

    .r-f2 {
        padding-top: 0;
        height: 59%;
    }
    .testimonials-rating-left-first {
        margin-bottom: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .rating-header {
        padding-top: 6px;
    }

}

@media (min-width: 1601px) {
    .testimonials-info {
        padding-top: 2%;
    }

    .testimonials-rating-left {
        height: 83%;
        width: 50%;
    }

    .testimonials-rating-left-first {
        width: 96%;
    }

    .f2 {
        height: 235px;
    }

    .r-f2 {
        padding-top: 0;
        height: 59%;
    }
    .testimonials-rating-left-first {
        margin-bottom: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

}

/* Responsive for ipad pro ipad air */
@media (min-width: 820px) and (max-width: 1080px){
    .testimonials {
        height: 130%;
        width: 100%;
        overflow-x: hidden;
    }

    .testimonials-container {
        height: 100%;
        width: 100%;
    }

    .testimonials-info {
        width: 100%;
        height: 100%;
    }

    .testimonials-rating {
        display: flex;
        flex-direction: column;
    }

    .testimonials-rating-left {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
    }

    .testimonials-rating-right {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-top: 13px;
    }

    .testimonials-vectors svg {
        height: 20px;

    }

    .f1{
        width: 95%;
        font-size: 10px;
        height: 48%;
    }
    .f1 p{

    }

    .f2{
        width: 95%;
        font-size: 18px;
        height: 44%;
    }

    #admin_female_avatar {
        height: 80%;
    }
    .rating-text {
        font-family: 'Montserrat';
        font-size: 30px;
        font-weight: 500;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        height: 44%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        margin: 0
    
    }

    .rating-per-info img {
        height: 30px;
    }

    .rating-per-info h3 {
        font-size: 30px ;
    }

    .testimonials-rating-right {
        height: 50%;
        width: 100%;
    }

    .f3{
        width: 95%;
        height: 45%;
    }

    .rating-profile img {
        height: 80%;
        width: 50%;
    }


    .see-more P {
        font-size: 46px;
    }

    .see-more svg {
        height: 40px;
        width: 60px;
    }

    .see-more {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 10px -22px 22px;
        width: 60%;
    }

    .rating-per-info p {
        font-family: Quicksand;
        font-size: 25px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        margin: 20px 0px;
    }
}

@media (min-width: 768px) and (max-width: 819px){}

/* Responsive for Mobile */
@media screen and (max-width: 767px) {
    .testimonials {
        height: 130%;
        width: 100%;
        overflow-x: hidden;
    }

    .testimonials-container {
        height: 100%;
        width: 100%;
    }

    .testimonials-info {
        width: 100%;
        height: 100%;
    }

    .testimonials-rating {
        display: flex;
        flex-direction: column;
    }

    .testimonials-rating-left {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-direction: column;
    }

    .testimonials-rating-right {
        height: 50%;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-top: 13px;
    }

    .testimonials-vectors svg {
        height: 20px;

    }

    .f1{
        width: 95%;
        font-size: 10px;
        height: 48%;
    }
    .f1 p{

    }

    .f2{
        width: 95%;
        font-size: 18px;
        height: 44%;
    }

    #admin_female_avatar {
        height: 80%;
    }
    .rating-text {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        /* line-height: 22px; */
        letter-spacing: 0em;
        text-align: left;
        width: 100%;
        height: 44%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        margin: 0
    
    }

    .rating-per-info img {
        height: 10px;
    }

    .rating-per-info h3 {
        font-size: 16px ;
    }

    .testimonials-rating-right {
        height: 50%;
        width: 100%;
    }

    .f3{
        width: 95%;
        height: 45%;
    }

    .rating-profile img {
        height: 80%;
    }


    .see-more P {
        font-size: 20px;
    }

    .see-more svg {
        height: 40px;
    }

    .see-more {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 10px -22px 22px;
        width: 60%;
    }
}