.Webxr-first-page-content-container{
    flex-direction: row-reverse;
}

#Webxr-first-page-image{
    width: 80%;
    height: auto;
}

.webxr-second-apge{
    background-image: url('../../../../public/images/Webxr-second-page-image.png');
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 320px) {
    .Webxr-first-page-content-container{
        flex-direction: column;
    }
    #Webxr-first-page-image{
        height: 70%;
        width: 70% !important;
    }

    #vector-color, #white-Vector,#Vectoremptyrotate,#white-Vector,#service-threeDot, #Vectorempty{
        display: none;
    }

}
@media (min-width: 321px) and (max-width: 375px){
    .Webxr-first-page-content-container{
        flex-direction: column;
    }
    #vector-color, #white-Vector,#Vectoremptyrotate,#white-Vector,#service-threeDot, #Vectorempty{
        display: none;
    }
    #Webxr-first-page-image{
        height: 70%;
        width: 70% !important;
    }
}

@media (min-width: 376px) and (max-width: 600px){
    .Webxr-first-page-content-container{
        flex-direction: column;
    }
    #vector-color, #white-Vector,#Vectoremptyrotate,#white-Vector,#service-threeDot, #Vectorempty{
        display: none;
    }

    #Webxr-first-page-image{
        height: 80%;
        width:50% !important;

    }
    .left-container-Mobile-image{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width: 768px) {
    #Webxr-first-page-image {
        width: 56%;
        height: auto;
    }
}