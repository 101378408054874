.drop-main-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.Drop-first-page {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-direction: row;
    background-image: url('../../../../public/images/Untitled.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

}

.Drop-first-page-sub-div {
    height: 100%;
    width: 100%;
    background-image: linear-gradient(49deg, rgb(255 255 255 / 47%), rgb(255 255 255), rgb(255 255 255));
    box-sizing: border-box;
    padding-top: 80px;
}

.Drop-first-page-sub-container {
    height: 100%;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding-top: 0%;
}

.Drop-first-page-text-content {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 30px;


}

.Drop-first-image-content {
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Drop-heading {
    height: 20%;
    /* background-color: violet; */
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
}

#Drop {
    color: orange;
}

.Drop-paragraph {
    font-size: 25px;
    text-align: left;
}

.Drop-enquire-now {
    height: 19%;
    width: 100%;
    /* background-color: violet; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20%;

}

#Drop-image {
    height: 75%;
}

.drop-enquire-now-btn {
    /* background-color: yellow; */
    margin: 0;
}

#drop-vector-1 {
    position: absolute;
    top: 10%;
    left: 40%;
}

#drop-vector-2 {
    position: absolute;
    top: 10%;
    left: 65%;
}

#drop-vector-3 {
    position: absolute;
    top: 45%;
    left: 60%;
}

#drop-vector-4 {
    position: absolute;
    bottom: 15%;
    left: 10%;
}

#drop-vector-5 {
    position: absolute;
    bottom: 10%;
    right: 40%;
}

#drop-vector-6 {
    position: absolute;
    left: 2%;
    top: 12%;
}

#drop-vector-1,
#drop-vector-2,
#drop-vector-3,
#drop-vector-4,
#drop-vector-5,
#drop-vector-6,
#Dropsecondpage-vector-1,
#Dropsecondpage-vector-2,
#Dropsecondpage-vector-3,
#Dropsecondpage-vector-4,
#Dropsecondpage-vector-5 {
    animation: 3s infinite product-svg linear;
}

@keyframes product-svg {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(60deg);
    }
}


/* Second page Css */

.Drop-Second-page {
    height: 200%;
    width: 100%;
    background-image: url('../../../../public/images/DropSecondpage-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 80px;
    position: relative;

}

.Drop-second-page-logo {
    height: 8%;
    width: 100%;
    /* background-color: brown;s */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10%;
    position: relative;

}

#Drop-secondpage-heading-circle {
    position: absolute;
    left: 7%;
}

.DropSecondpage-video-image {
    height: 38%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-left: 10%;
}

#Dropsecondpage-vector-1 {
    position: absolute;
}

#Dropsecondpage-vector-2 {
    position: absolute;
    right: 10%;
    top: 10%;
}

#Dropsecondpage-vector-3 {
    position: absolute;
    left: 10%;
    top: 20%;
}

#Dropsecondpage-vector-4 {
    position: absolute;
    left: 10%;
    top: 40%;
}

#Dropsecondpage-vector-5 {
    position: absolute;
    left: 40%;
    top: 47%;
}

.product-in-glanace {
    height: 15%;
    /* background-color: blueviolet; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 3%;
    font-size: 20px;
    color: #fff;
}

.Drop-secondpage-cards {
    height: 38%;
}

.Drop-secondpage-cards-scroller {
    display: flex;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.Drop-secondpage-cards-scroller .swiper-horizontal {
    width: 100%;
    height: 100%;
}
.Drop-second-page-card-1 {
    height: 80%;
    background-color: #fff;
    width: 18%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;
    box-sizing: border-box;
    padding: 2%;
}
.drop-ytContainer {
    width: 760px;
    height: 496px;
    position: relative;
}
.ytMobile {
    width: 474px;
    height: 302px;
    position: absolute;
    left: 0;
}
.dropMobile {
    width: 313px;
    height: 335px;
    position: absolute;
    left: 50%;
    top: 26%;
}
.ytVideoDrop {
    width: 414px;
    height: 269px;
    position: absolute;
    left: 18px;
    top: 17px;
    border-radius: 6px;
}

.Drop-second-page-card-1>img {
    height: 36%;
    width: auto;

}

.Drop-second-page-card-1 h1{
    font-size: 35px;
}

.Drop-second-page-card-1>p {
    font-size: 20px;
    color: #000;
}

.Drop-second-page-card-2,
.Drop-second-page-card-4 {
    background-color: #FFF8E4;
}

.Drop-second-page-card-3 {
    background-color: #C0DCFC;
}

/* Third page css */

.Drop-third-page {
    height: 150%;
    background-image: url('../../../../public/images/Untitled.png');
    background-repeat: no-repeat;
    background-size: cover;

}

.Drop-third-page-sub-div {
    height: 100%;
    background: linear-gradient(128deg, #FFFFFF 63%, rgba(255, 255, 255, 0) 106%);
    display: flex;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding-top: 80px;
}

.Drop-third-page-sub-div-mobile{
    display: none;
}   

.Drop-third-page-left-content {
    height: 100%;
    width: 40%;
    /* background-color: yellow;*/
    display: flex;
    /* justify-content: space-evenly; */
    flex-direction: column;
}

.Drop-third-page-middle-content {
    height: 100%;
    width: 10%;
    /* background-color: rgb(20, 172, 45); */
}

.Drop-third-page-right-content {
    height: 100%;
    width: 40%;
    /* background-color: rgb(47, 124, 225); */
}

.Drop-how-it-work {
    height: 20%;
    /* background-color: violet; */
    padding: 20px;
    display: flex;
    box-sizing: border-box;
    font-size: 20px;
    color: #FF8402;
    font-weight: 700;
    position: relative;
    flex-direction: column;
    justify-content: space-between;

}

.Drop-how-it-work>h1 {
    z-index: 4;
}

#how-it-work-circle {
    position: absolute;
    /* opacity: 0.6; */
    top: 0;
    left: 0;
}

#how-it-work-vector {
    position: absolute;
    bottom: 10%;
    right: 10%;
}
.Drop-third-page-middle-content>svg {
    height: 65%;
    width: auto;
    margin-top: 80%;
}

.drop-third-page-left-content {
    height: 65%;
    display: flex;
    padding-left: 10px;
    padding: 10px;
    flex-direction: column;
}

.step-div {
    height: 41%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 35px;
    box-sizing: border-box;
    position: relative;
}

#Step-left-image {
    position: absolute;
    left: 20px;
    top: 0%;
}

.step-div>h1 {
    height: 11%;
    background-color: #008F88;
    text-align: right;
    width: 30%;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 35px;
    border-top-right-radius: 35px;
    font-size: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.third-page-left-paragaph {
    height: 50%;
    /* background-color: yellow; */
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    position: relative;
}

.third-page-left-paragaph>h1 {
    height: 11%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 30px;
    color: #272D2F;
    font-family: 'Montserrat';
    text-align: right;
}

.third-page-left-paragaph>p {
    height: 60%;
    /* background-color: violet; */
    width: 90%;
    text-align: right;
    font-size: 25px;
    color: #272D2F;
}
.Third-right-div-1 {
    height: 50%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    padding-top: 28%;
    flex-direction: column;
    position: relative;
    padding-top: 19%;
}
.step01 {
    height: 13%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border: 2px solid #0065B8;
}

.step01>h1 {
    height: 80%;
    width: 90%;
    background-color: #0065B8;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    color: #fff;
}

.step-1-paragraph {
    /* background-color: violet; */
    height: 80%;
    color: #272D2F;
    position: relative;
}

.step-1-paragraph>h1 {
    text-align: left;
    font-size: 40px;
    font-size: 30px;
    /* color: #272D2F; */
}

.step-1-paragraph>p {
    font-size: 25px;
    width: 80%;
    text-align: left;
}

#step01-image {
    position: absolute;
    top: 5%;
    right: 0;
}

.Third-right-div-2 {
    display: flex;
    box-sizing: border-box;
    /* background-color: yellow; */

}
.step-3-paragraph {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 85%;
}

.step02 {
    border: 2px solid #008F88;
    border-radius: 50px 50px 0px;
    height: 18%;
}

.step02>h1 {
    background-color: #008F88;
    border-radius: 50px 50px 0px;
}

.step03 {
    border: 2px solid #FF8402;
    height: 13%;
}
.step03>h1 {
    background-color: #FF8402;
    font-size: 30px;
    height: 80%;
    width: 90%;
}

#step03-image {
    position: absolute;
    left: 62%;
    top: 2%;
}

/* Fourth page css */

.Drop-fourth-page {
    height: 100%;
    width: 100%;
    background-image: url('../../../../public/images/Drop-fourth-page-background.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
}
.drop-fourth-page-inner-div{
    height: 428px;
    width: 1152px;
    background-image: url('../../../../public/images/polygonDrop.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
}

.drop-4-page-div-1 {
    height: 20%;
    width: 15%;
    position: absolute;
    left: 4%;
    bottom: 27%;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.drop-4-page-div-2 {
    height: 18%;
    width: 13%;
    position: absolute;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    left: 19.7%;
    top: 20%;
    text-align: center;
}

.drop-4-page-div-3 {
    position: absolute;
    top: 20%;
    left: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #fff;
    flex-direction: column;
    gap: 0;
    line-height: 45px;
}


.drop-4-page-div-3>#p1 {
    font-size: 60px;
    font-weight: 700;

}
.drop-4-page-div-3>p {
    font-size: 30px;

}

.drop-4-page-div-4 {
    height: 20%;
    width: 13%;
    position: absolute;
    bottom: 17%;
    left: 26.5%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.drop-4-page-div-5 {
    width: 14%;
    position: absolute;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    right: 19%;
    top: 19%;
    text-align: center;
}

.drop-4-page-div-6 {
    height: 20%;
    width: 13%;
    position: absolute;
    bottom: 15%;
    right: 26%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.drop-4-page-div-7 {
    height: 20%;
    width: 15%;
    position: absolute;
    right: 4%;
    bottom: 29%;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
.Drop-fourth-page p {
    font-size: 16px;
}

@media only screen and (max-width: 1537px) {
    .Drop-how-it-work>h1 {
        margin: 0;
        font-size: 55px;
    }
    #Drop {
        margin: 0;
        font-size: 55px;
    }
    .Third-right-div-1 {
        padding-top: 4%;
    }
    .Drop-secondpage-cards-scroller,  .Drop-secondpage-cards-scroller .swiper {
        width: 100%;
    }
    .Drop-secondpage-cards-scroller .swiper-wrapper {
        margin-left: 25%;
    }
    .Drop-secondpage-cards-scroller .swiper-slide-prev{
        margin-left: 0px;
    }
    .Drop-third-page-middle-content>svg {
        height: 95%;
    }
    .step02 {
        height: 60px;
    }
    .Third-right-div-1.Third-right-div-2 {
        margin-top: 30%;
    }
    .Drop-fourth-page p{
        font-size: 16px;
    }

    .drop-product .swiper-wrapper {
        height: 100% !important;
    }
    .drop-product .swiper{
        width: 100% !important;
        height: 100% !important;
    }
    .drop-product .Drop-second-page-card-1 {
        width: 100% !important;
    }
    .drop-product .swiper-wrapper {
        margin-left: 0%;
    }
    .drop-product .swiper-slide{
        margin-right: 7% !important;
    }
 
}

/* Responsive for apple laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)) {
    .Drop-fourth-page {
        background-size: 100% 100%;
    
    }
    .Drop-fourth-page p {
        font-size: 16px;
    }
    .drop-4-page-div-3 {
        top: 30%;
        left: 40%;
        line-height: 35px;
    }
    .drop-4-page-div-1 {
        left: 7%;
        bottom: 31%;
      
    }
    .drop-4-page-div-2 {
     
        left: 22.6%;
        top: 27%;
    }
    .drop-4-page-div-4 {
        bottom: 23%;
    }

    .drop-4-page-div-5 {
        top: 27%;
    }
    .drop-4-page-div-6 {
        bottom: 22%;
    }
    .drop-4-page-div-7 {
        bottom: 31%;    
    }
    .drop-4-page-div-3>#p1 {
        font-size: 50px;
        font-weight: 700;
    }

}

/* @media (min-width: 601px) and (max-width: 768px){
    .Drop-first-page{
        height: 100vh;
        width: 100%;
    }
    .Drop-first-page-sub-container{
        height: 100%;
        width: 100%;
        flex-direction: column-reverse;
    }
    .Drop-first-image-content{
        height: 50%;
        width: 100%;
    }

    .Drop-first-page-text-content{
        width: 100%;
        height: 60%;
        box-sizing: border-box;
        padding: 10px;
    }
    #Drop{
        font-size: 40px;
    }

    .Drop-paragraph p{
        font-size: 20px;
        
    }
    .Drop-enquire-now {
        justify-content: center;
    }
    #drop-vector-1,#drop-vector-2,#drop-vector-3,#drop-vector-4,#drop-vector-5, #Dropsecondpage-vector-1,#Dropsecondpage-vector-2, #Dropsecondpage-vector-3, #Dropsecondpage-vector-4, #Dropsecondpage-vector-5{
        display: none;
    }

    .Drop-second-page-logo {
        height: 8%;
        width: 100%;
    }
    .Drop-second-page-logo > svg{
        height:70%;
        width: 50%;
    }
    .DropSecondpage-video-image {
        height: 10%;
        width: 100%;
    }
    #Drop-secondpage-heading-circle {
        position: absolute;
        left: -12%;
    }
    .product-in-glanace {
        height: 10%;
        font-size: 10px;
    }
    .Drop-third-page{
        height: 100vh;
    }

    .Drop-third-page-sub-div{
        display: none;
    }

    .Drop-third-page-sub-div-mobile{
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-left: 20px;
        background: linear-gradient(128deg, #FFFFFF 63%, rgba(255, 255, 255, 0) 106%);
    }

    #step-line{
        height: 70%;
        margin-bottom: 190px;
        
    }
    .Drop-third-page-sub-div-mobile-content{
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .mobile-step01-div{
        height: 30%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 20px;
        color: #272D2F;
        width: 80%;
    }

    .mobile-step01-div > p{
        font-size: 20px;   
    }
    .step01-mobile{
        border: 2px solid #0065B8;
        width: 35%;
        height: 20%;
        border-radius: 20px 25px 25px 0px;
        box-sizing: border-box;
        padding: 5px;
        color: #fff;
        font-weight: 700;
    }
    .step01-mobile-head{
        border-radius: 20px 25px 25px 0px;
        background-color: #0065B8;
        padding: 7px;
        font-weight: 600;
    }

    .Drop-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;

    }

    .drop-4-page-div-1,.drop-4-page-div-2, .drop-4-page-div-4, .drop-4-page-div-6, .drop-4-page-div-7,.drop-4-page-div-5,.Sem-4-page-div-5 {
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       

    }

    .Drop-fourth-page > div > p{
        font-size: 20px;
        text-align: left;
        
    }

    .drop-4-page-div-3>#p1 {
        font-size: 45px !important;
    }

    #Lets{
        display: none;
    }

    .drop-4-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 90%;
        left: 30px;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

} */

/* Responsive for ipad air and ipad air*/
@media (min-width: 820px) and (max-width: 1080px){
    .Drop-first-page{
        height: 100vh;
        width: 100%;
        overflow: hidden;
        
    }
    .Drop-first-page-sub-container{
        height: 100%;
        width: 100%;
        flex-direction: column-reverse;
    }
    .Drop-first-image-content{
        height: 40%;
        width: 100%;
    }

    .Drop-first-page-text-content{
        width: 100%;
        height: 60%;
        box-sizing: border-box;
        padding: 10px;
    }
    #Drop{
        font-size: 60px;
    }

    #Drop-image {
        height: 90%;
    }

    .Drop-paragraph p{
        font-size: 30px;
    }
    .Drop-enquire-now {
        margin-top: 0% !important;
        justify-content: center;
    }
    #drop-vector-1,#drop-vector-2,#drop-vector-3,#drop-vector-4,#drop-vector-5, #Dropsecondpage-vector-1,#Dropsecondpage-vector-2, #Dropsecondpage-vector-3, #Dropsecondpage-vector-4, #Dropsecondpage-vector-5, #drop-vector-6{
        display: none;
    }
    
    .Drop-Second-page{
        display: block;
        height: 130%;
        width: 100%;
        padding: 0;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .Drop-second-page-logo{
        height: 9%;
    }
    .Drop-second-page-logo SVG{
        height: 50%;
        left: 10px;
    }

    .DropSecondpage-video-image{
        height: 28%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .DropSecondpage-video-image img{
        height: 80%;
        width: 100%;
    }

    .product-in-glanace{
        height: 7%;
        font-size: 10px;
        width: 100%;
    }

    .Drop-secondpage-cards{
        height: 55%;
        display: flex;
    }
    .Drop-second-page-card-1>h1 {
        font-size: 45px;
    }
    .Drop-second-page-card-1>p {
        font-size: 30px;
        color: #000;
    }

    .Drop-third-page{
        height: 100vh;
    }

    .Drop-third-page-sub-div{
        display: none;
    }

    .Drop-third-page-sub-div-mobile{
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-left: 20px;
        background: linear-gradient(128deg, #FFFFFF 63%, rgba(255, 255, 255, 0) 106%);
    }

    #step-line{
        height: 72%;
        margin-bottom: 190px;
        
    }
    .Drop-third-page-sub-div-mobile-content{
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .mobile-step01-div {
        height: 35%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 20px;
        color: #272D2F;
        font-size: 25px;
    }

    .step01-mobile{
        border: 2px solid #0065B8;
        width: 35%;
        height: 15%;
        border-radius: 20px 25px 25px 0px;
        box-sizing: border-box;
        padding: 5px;
        color: #fff;
        font-weight: 700;
    }
    .step01-mobile-head{
        border-radius: 20px 25px 25px 0px;
        background-color: #0065B8;
        padding: 7px;
        font-weight: 600;
    }
    
    .Drop-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 70px;
    }

    .drop-4-page-div-1,.drop-4-page-div-2, .drop-4-page-div-4, .drop-4-page-div-6, .drop-4-page-div-7,.drop-4-page-div-5{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       justify-content: flex-start;
    }

    .Drop-fourth-page > div > p{
        font-size: 25px;
        text-align: left;
        
    }

    .drop-4-page-div-3>#p1 {
        font-size: 50px !important;
    }

    #Lets{
        display: none;
    }

    .drop-4-page-div-3{
        top: 10px;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #step-line {
        height: 73%;
        margin-bottom: 40%;
    }

    .drop-product{
        width: 100%;
    }
    .ytVideoDrop {
        width: 87%;
        height: 79%;
        position: absolute;
        left: 4%;
        top: 18px;
        border-radius: 6px;
    }
    .DropSecondpage-video-image img {
        height: 90%;
        width: 100%;
    }
    .dropMobile {
        width: 238px !important;
        height: auto !important;
        right: -43px;
        left: unset;
        bottom: -53px;
        top: unset;
    }
    .drop-ytContainer {
        width: 80%;
        height: 80%;
        position: relative;
    }
    .Drop-secondpage-cards .swiper-slide{
        width:400px !important;
        height: 90%;
    }
    .drop-product .swiper-slide {
        margin-right: 19% !important;
        margin-left: 33.5% !important;

    }

    .drop-fourth-page-inner-div{
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    .drop-4-page-div-3>#p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -80%;

        /* font-weight: 700; */
    }
    .drop-4-page-div-1, .drop-4-page-div-2, .drop-4-page-div-4, .drop-4-page-div-6, .drop-4-page-div-7, .drop-4-page-div-5 {
        text-align: left;
    }
    .Drop-fourth-page p {
        font-size: 35px;
    }
    .drop-4-page-div-3>#p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -52%;
        /* font-weight: 700; */
    }
    .DropSecondpage-video-image {
    height: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.product-in-glanace {
    height: 7% !important;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 3%;
    font-size: 17px;
    color: #fff;
    width: 100%;
}
   
}

@media (min-width: 768px) and (max-width: 819px){
    .Drop-first-page{
        height: 100vh;
        width: 100%;
        overflow: hidden;
        
    }
    .Drop-first-page-sub-container{
        height: 100%;
        width: 100%;
        flex-direction: column-reverse;
    }
    .Drop-first-image-content{
        height: 40%;
        width: 100%;
    }

    .Drop-first-page-text-content{
        width: 100%;
        height: 60%;
        box-sizing: border-box;
        padding: 10px;
    }
    #Drop{
        font-size: 60px;
    }

    #Drop-image {
        height: 80%;
    }

    .Drop-paragraph p{
        font-size: 30px;
    }
    .Drop-enquire-now {
        margin-top: 0% !important;
        justify-content: center;
    }
    #drop-vector-1,#drop-vector-2,#drop-vector-3,#drop-vector-4,#drop-vector-5, #Dropsecondpage-vector-1,#Dropsecondpage-vector-2, #Dropsecondpage-vector-3, #Dropsecondpage-vector-4, #Dropsecondpage-vector-5, #drop-vector-6{
        display: none;
    }
    
    .Drop-Second-page{
        display: block;
        height: 130%;
        width: 100%;
        padding: 0;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .Drop-second-page-logo{
        height: 9%;
    }
    .Drop-second-page-logo SVG{
        height: 50%;
        left: 10px;
    }

    .DropSecondpage-video-image{
        height: 28%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .DropSecondpage-video-image img{
        height: 80%;
        width: 100%;
    }

    .product-in-glanace{
        height: 7%;
        font-size: 10px;
        width: 100%;
    }

    .Drop-secondpage-cards{
        height: 60%;
        display: flex;
    }
    .Drop-second-page-card-1>h1 {
        font-size: 45px;
    }
    .Drop-second-page-card-1>p {
        font-size: 30px;
        color: #000;
    }

    .Drop-third-page{
        height: 100vh;
    }

    .Drop-third-page-sub-div{
        display: none;
    }

    .Drop-third-page-sub-div-mobile{
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-left: 20px;
        background: linear-gradient(128deg, #FFFFFF 63%, rgba(255, 255, 255, 0) 106%);
    }

    #step-line{
        height: 72%;
        margin-bottom: 190px;
        
    }
    .Drop-third-page-sub-div-mobile-content{
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .mobile-step01-div {
        height: 35%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 20px;
        color: #272D2F;
        font-size: 25px;
    }

    .step01-mobile{
        border: 2px solid #0065B8;
        width: 35%;
        height: 20%;
        border-radius: 20px 25px 25px 0px;
        box-sizing: border-box;
        padding: 5px;
        color: #fff;
        font-weight: 700;
    }
    .step01-mobile-head{
        border-radius: 20px 25px 25px 0px;
        background-color: #0065B8;
        padding: 7px;
        font-weight: 600;
    }
    
    .Drop-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 70px;
    }

    .drop-4-page-div-1,.drop-4-page-div-2, .drop-4-page-div-4, .drop-4-page-div-6, .drop-4-page-div-7,.drop-4-page-div-5{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
       display: flex;
       justify-content: flex-start;
    }

    .Drop-fourth-page > div > p{
        font-size: 25px;
        text-align: left;
        
    }

    .drop-4-page-div-3>#p1 {
        font-size: 45px !important;
    }

    #Lets{
        display: none;
    }

    .drop-4-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #step-line {
        height: 72%;
        margin-bottom: 37%;
    }
    .drop-product{
        width: 100%;
    }
    .ytVideoDrop {
        width: 80%;
        height: 60%;
        position: absolute;
        left: 4%;
        top: 15px;
        border-radius: 6px;
    }
    .DropSecondpage-video-image img {
        height: 70%;
        width: 93%;
    }
    .dropMobile {
        width: 160px !important;
        height: auto !important;
        right: 24px;
        left: unset;
        bottom: 24px;
        top: unset;
    }
    .drop-ytContainer {
        width: 80%;
        height: 100%;
        position: relative;
    }
    .Drop-secondpage-cards .swiper-slide{
        width:400px !important;
        height: 90%;
    }
    .drop-product .swiper-slide {
        margin-right: 1% !important;
        margin-left: 44% !important;
    }
    .product-in-glanace {
        height: 6%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        padding-right: 3%;
        font-size: 14px;
        color: #fff;
        width: 100%;
    }

    .drop-fourth-page-inner-div{
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    .drop-4-page-div-3>#p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -80%;

        /* font-weight: 700; */
    }
    .drop-4-page-div-1, .drop-4-page-div-2, .drop-4-page-div-4, .drop-4-page-div-6, .drop-4-page-div-7, .drop-4-page-div-5 {
        text-align: left;
        display: flex;
        justify-content: flex-start;
    }
    .Drop-fourth-page p {
        font-size: 30px;
    }
    #Sem-p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -52%;
    }
    .Sem-fourth-page p {
        font-size: 30px;
    }
    .drop-4-page-div-3>#p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -60%;
    }

    #Bustracking-second-page-card-2{
        width: 400px;
    }

    .swiper-slide{
        width: 400px !important;
    }
    .drop-product .swiper-slide {
        margin-right: 1% !important;
        margin-left: 50% !important;
    }
    .drop-product .swiper-slide {
        margin-right: -12% !important;
        /* margin-left: 60% !important; */
    }
}

/* Responsive for Mobile */
@media screen and (max-width: 767px){
    .Drop-first-page{
        height: 100vh;
        width: 100%;
        overflow: hidden;
        
    }
    .Drop-first-page-sub-container{
        height: 100%;
        width: 100%;
        flex-direction: column-reverse;
    }
    .Drop-first-image-content{
        height: 40%;
        width: 100%;
    }

    .Drop-first-page-text-content{
        width: 100%;
        height: 60%;
        box-sizing: border-box;
        padding: 10px;
    }
    #Drop{
        font-size: 40px;
    }

    #Drop-image {
        height: 85%;
    }

    .Drop-paragraph p{
        font-size: 20px;
    }
    .Drop-enquire-now {
        margin-top: 0% !important;
        justify-content: center;
    }
    #drop-vector-1,#drop-vector-2,#drop-vector-3,#drop-vector-4,#drop-vector-5, #Dropsecondpage-vector-1,#Dropsecondpage-vector-2, #Dropsecondpage-vector-3, #Dropsecondpage-vector-4, #Dropsecondpage-vector-5, #drop-vector-6{
        display: none;
    }
    
    .Drop-Second-page{
        display: block;
        height: 130%;
        width: 100%;
        padding: 0;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .Drop-second-page-logo{
        height: 9%;
    }
    .Drop-second-page-logo SVG{
        height: 50%;
        left: 10px;
    }

    .DropSecondpage-video-image{
        height: 28%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .DropSecondpage-video-image img{
        height: 80%;
        width: 100%;
    }

    .product-in-glanace{
        height: 7%;
        font-size: 10px;
        width: 100%;
    }

    .Drop-secondpage-cards{
        height: 60%;
        display: flex;
    }

    .Drop-third-page{
        height: 100vh;
    }

    .Drop-third-page-sub-div{
        display: none;
    }

    .Drop-third-page-sub-div-mobile{
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-top: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        padding-left: 20px;
        background: linear-gradient(128deg, #FFFFFF 63%, rgba(255, 255, 255, 0) 106%);
    }

    #step-line{
        height: 72%;
        margin-bottom: 190px;
        
    }
    .Drop-third-page-sub-div-mobile-content{
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .mobile-step01-div {
        height: 35%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        padding-left: 20px;
        color: #272D2F;
        font-size: 14px;
    }

    .step01-mobile{
        border: 2px solid #0065B8;
        width: 35%;
        height: 22%;
        border-radius: 20px 25px 25px 0px;
        box-sizing: border-box;
        padding: 5px;
        color: #fff;
        font-weight: 700;
    }
    .step01-mobile-head{
        border-radius: 20px 25px 25px 0px;
        background-color: #0065B8;
        padding: 7px;
        font-weight: 600;
    }
    
    .Drop-fourth-page{
        height: 100%;
        width: 100%;
        background-image: none;
        background-color: #000;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0;
        margin: 0;
        flex-direction: column;
        padding-top: 60px;
    }

    .drop-4-page-div-1,.drop-4-page-div-2, .drop-4-page-div-4, .drop-4-page-div-6, .drop-4-page-div-7,.drop-4-page-div-5{
        position: relative;
        width: 90%;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
       border: 1px solid #fff;
       border-radius: 20px;
       box-sizing: border-box;
       padding: 10px;
       height: 14%;
       border: 1px solid #aba8a8;
    }

    .Drop-fourth-page > div > p{
        font-size: 18px;
        text-align: left;
        
    }

    .drop-4-page-div-3>#p1 {
        font-size: 45px !important;
    }

    #Lets{
        display: none;
    }

    .drop-4-page-div-3{
        top: 0;
        box-sizing: border-box;
        padding: 10px;
        height: 10%;
        width: 100%;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #step-line {
        height: 73%;
        margin-bottom: 45%;
    }

    .drop-product{
        width: 100%;
    }
    .ytVideoDrop {
        width: 83%;
        height: 62%;
        position: absolute;
        left: 3%;
        top: 8px;
        border-radius: 6px;
    }
    .DropSecondpage-video-image img {
        height: 70%;
        width: 93%;
    }
    .dropMobile{
        width: 125px !important;
        height: auto !important;
        right: 0px;
        left: unset;
        bottom: 0px;
        top: unset;
    }
    .drop-ytContainer {
        width: 303px;
        height: 224px;
        position: relative;
    }
    .Drop-secondpage-cards .swiper-slide{
        width:266px !important;
    }
    .drop-product .swiper-slide {
        margin-right: 19% !important;
        margin-left: 45% !important;
    }

    .drop-fourth-page-inner-div{
        background-image: none;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        text-align: left;
    }
    .drop-4-page-div-3>#p1 {
        font-size: 80px;
        position: absolute;
        margin: 0;
        top: -80%;

        /* font-weight: 700; */
    }
    .drop-4-page-div-1, .drop-4-page-div-2, .drop-4-page-div-4, .drop-4-page-div-6, .drop-4-page-div-7, .drop-4-page-div-5 {
        text-align: left;
    }
}