.page-not-found {
    height: 100%;
    width: 100%;
    background: url("/public/images/pnpDark.png");
    background-size: 100% 100%;
}
.daypnp{
    background: url("/public/images/pnp.png");
    background-size: 100% 100%;
}
.dayContainer{
    margin-top: 10% !important;
}
.dayHeading{
    color: black !important;
}
.page-not-found-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pnp-containt-container {
    margin-top: 25%;
}
.page-not-found-container h1 {
    font-family: Inter;
    font-size: 50px;
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;

}

.page-not-found-container button {
    font-family: Inter;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    width: 172px;
    height: 50px;
    top: 652px;
    left: 683px;
    border-radius: 10px;
    background: #407BFF;
    border: 0px solid;

}
