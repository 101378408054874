@import url('https://fonts.googleapis.com/css?family=Poppins');

.navbar {
  width: 100%;
  position: fixed;
  z-index: 999;
  color: black;
  z-index: 9999;

}

.navbarMob {
  display: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  margin: 7px 51px;
}

.navbar-items {
  width: 50%;
  font-size: 25px;
  margin: 0;
  display: flex;
  font-family: 'Poppins', sans-serif;
  line-height: 37.5px;
  align-items: center;
  width: fit-content;
  margin-bottom: 20px;
}

.navbar-items ul {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  height: 100%;
  margin: 0;
  padding: 0;
  visibility: visible;
  opacity: 0;
  transition: all 250ms ease-in-out;
}

.item-list.active {
  transition: all 250ms ease-in-out;
  opacity: 10;
}

.navbar-items li {
  margin-right: 60px;
  display: block;
  position: relative;
}

.navbar-items li p:hover,
#product:hover,
#services:hover,
#portfolio:hover,
#about:hover,
#contactNav:hover {
  color: #FF8402 !important;
  transition: 200ms ease;
  cursor: pointer;
}

.menu-toggle-container {
  height: 100%;
  width: 45px;
  display: none;
  align-items: center;
  cursor: pointer;
}

.menu-toggle {
  margin-right: 30px;
  cursor: pointer;

}

.mobile-nav-toggle {
  cursor: pointer;
  transform: translate(-50%, -50%);
  position: relative;
}

.mobile-nav-toggle span,
.mobile-nav-toggle span:before,
.mobile-nav-toggle span:after {
  cursor: pointer;
  border-radius: 25px;
  height: 5px;
  width: 40px;
  background: rgb(0, 0, 0);
  position: absolute;
  content: '';
  transition: all 400ms ease-in-out;
  left: 0;

}

.whiteToggle span,
.whiteToggle span:before,
.whiteToggle span:after {
  background: rgb(255, 255, 255);
}

.mobile-nav-toggle span:before {
  top: -8px;
}

.mobile-nav-toggle span:after {
  bottom: -8px;
}

.mobile-nav-toggle.active span {
  background-color: transparent;
}

.mobile-nav-toggle.active span:before {
  top: 0;
  transform: rotate(45deg);
}

.mobile-nav-toggle.active span:after {
  bottom: 0;
  transform: rotate(-45deg);
}

p {
  margin: 0;
}


.product-containt,
.services-containt,
.portfolio-containt {
  display: inline-block;
  position: absolute;
  height: 142px;
  width: 396px;
  background: rgb(255 255 255 / 48%);
  left: 0px;
  border-radius: 0px 0px 15px 15px;
  box-shadow: 4px 4px 10px 0px #00000047;
  border: 1px solid #c4c4c475;
  -webkit-backdrop-filter: blur(4.5px);
  backdrop-filter: blur(4.5px);
}

.product-list-container,
.services-list-container,
.portfolio-list-container {
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0px;
  height: 80%;
  /* color: black; */
}

.product-list-container p,
.services-list-container p,
.portfolio-list-container p {
  margin: 0;
  margin-left: 10px;
  margin-bottom: 0px;
}

.product-list-container span,
.services-list-container span,
.portfolio-list-container span {
  margin-left: 20px;
}

#product .product-containt,
#services .services-containt,
#portfolio .portfolio-containt {
  opacity: 0;
  visibility: hidden;

}

#product:hover .product-containt,
#services:hover .services-containt,
#portfolio:hover .portfolio-containt {
  opacity: 9;
  visibility: visible;
  transition: 500ms ease;
}

.services-containt {
  width: 450px;
}

.portfolio-containt {
  width: 580px;
}

.portfolio-containt {
  right: 0;
  left: unset;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/* Responsive file for Apple Laptop */
@media screen and ((max-width:1080px) and (max-height:713px)) {

  #logoImg {
    height: 50px;
  }

  .navbar-items {
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .navbar-items li {
    margin-right: 50px;
  }
}

/* Responsive for Mobile */



@media only screen and (max-width: 600px) {
  #logoImg {
    height: 20px;
  }

  .navbar-container {
    margin: 20px 4px;
  }

  .navbar-items li {
    margin-right: 15px;
    display: block;
    position: relative;
    font-size: 9px;
    margin-top: -3px;
  }

  #about {
    margin-top: -11px;
  }

  #contactNav {
    margin-top: -13px;
  }

  .text-containt {
    margin-bottom: 210px;
  }

  .svg1,
  .svg2,
  .svg3,
  .svg4,
  .svg5 {
    display: none;
  }
}


@media (min-width: 769px) and (max-width: 1080px) {
  #logoImg {
    width: 116px;
    height: auto;
  }

  .menu-toggle-container {
    height: 84%;
    width: 43px;
    display: none;
    align-items: center;
    cursor: pointer;
  }

  .mobile-nav-toggle span,
  .mobile-nav-toggle span:before,
  .mobile-nav-toggle span:after {
    width: 28px;
  }

  .item-list p {
    margin-bottom: 0px;
  }

  .navbar-items {
    font-size: 18px;
  }

  .navbar-items li {
    margin-right: 40px;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    margin: 9px 19px;
  }

  .product-containt,
  .services-containt,
  .portfolio-containt {
    width: 320px;
  }

  .services-containt {
    width: 360px;
  }

  .portfolio-containt {
    width: 395px;
  }

}

@media screen and (max-width:1080px) {
  .navbar-container {
    display: none;
  }

  .navbarMob {
    display: block;
  }

  .navbarMob {
    width: 100%;
    height: 65px;
    background-color: #444647;
  }

  .navbarMob-container {
    width: 100%;
    height: 100%;
    background: #444647;

  }

  .upper-nav {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .upper-nav-img {
    height: auto;
    margin-left: 15px;
    width: 150px;
  }

  .menu-toggle-mob-container {
    height: 100%;
    width: 45px;
    display: none;
    align-items: center;
    cursor: pointer;
  }

  .menu-toggle-mob {
    margin-right: 30px;
    cursor: pointer;

  }

  .mobile-nav-toggle-mob {
    cursor: pointer;
    transform: translate(-50%, -50%);
    position: relative;
  }

  .mobile-nav-toggle-mob span,
  .mobile-nav-toggle-mob span:before,
  .mobile-nav-toggle-mob span:after {
    cursor: pointer;
    border-radius: 25px;
    height: 4px;
    width: 29px;
    background: rgb(255 255 255);
    position: absolute;
    content: '';
    transition: all 250ms ease-in-out;
    left: 0;
  }

  .whiteToggle span,
  .whiteToggle span:before,
  .whiteToggle span:after {
    background: rgb(255, 255, 255);
  }

  .mobile-nav-toggle-mob span:before {
    top: -8px;
  }

  .mobile-nav-toggle-mob span:after {
    bottom: -8px;
  }

  .mobile-nav-toggle-mob.active span {
    background-color: transparent;
  }

  .mobile-nav-toggle-mob.active span:before {
    top: 0;
    transform: rotate(45deg);
  }

  .mobile-nav-toggle-mob.active span:after {
    bottom: 0;
    transform: rotate(-45deg);
  }

  .menu-toggle-container-mob {
    width: 35px;
    height: 30px;
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  .lower-div-mob {
    width: 100%;
    height: 0;
    visibility: hidden;
    transition: 200ms ease;
  }

  .lower-div-mob.active {
    width: 100%;
    height: 1000%;
    visibility: visible;
    background: #444647;
    transition: 200ms ease;
  }

  .nab-mob-container {
    height: 100%;
    width: 100%;
    display: flex;
    font-family: 'Poppins', sans-serif;
    line-height: 37.5px;
    align-items: center;
    color: #ffffff;

  }

  .nav-mob-containt-container {
    height: 100%;

  }

  .nav-mob-contint-list {
    margin-top: 15px;
    margin-left: 15px;
    text-align: left;
  }

  .navmob-list {
    margin-top: 15px;
  }

  .nav-mob-contint-list p {
    margin-top: 10px;
  }

  .navmob-sublist1,
  .navmob-sublist2,
  .navmob-sublist3 {
    margin-left: 30px;
    display: none;
    transition: 200ms ease;
  }

  .upper-nav img {
    width: 140px;
    height: auto;
    margin-left: 15px;
  }

  .nav-mob-contint-list {
    margin-top: 15px;
    margin-left: 15px;
    text-align: left;
    font-size: 25px;
  }

}

/*laptop*/
@media (min-width: 1081px) and (max-width: 1366px) {
  #logoImg {
    width: 130px;
    height: auto;
  }

  .menu-toggle-container {
    height: 84%;
    width: 43px;
    display: none;
    align-items: center;
    cursor: pointer;
  }

  .mobile-nav-toggle span,
  .mobile-nav-toggle span:before,
  .mobile-nav-toggle span:after {
    width: 35px;
  }

  .item-list p {
    margin-bottom: 0px;
  }

  .navbar-items {
    font-size: 22px;
  }

  .navbar-items li {
    margin-right: 45px;
  }

  .navbar-container {
    display: flex;
    justify-content: space-between;
    margin: 9px 19px;
  }

  .product-containt,
  .services-containt,
  .portfolio-containt {
    width: 360px;
  }

  .services-containt {
    width: 400px;
  }

  .portfolio-containt {
    width: 500px;
  }
}

@media (min-width: 1367px) and (max-width: 1600px) {}