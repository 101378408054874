
.blur-div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999999;
    visibility: hidden;
    top: 0;
    left: 0;
    background-color:rgba(120, 125, 125, 0.324);
}

/* Reponsive for Desktop */
.letsConnect-box{
    width: 55%;
    height: 65%;
    background-color: #272d2f;
    position: absolute;
    left: 23%;
    top: 20%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    flex-direction: column;
    padding: 10px;
}

#sir-logo{
    height: 80%;
    width: 100%;
}

.lets-connect-heading{
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.lets-connect-heading > h1 {
    width: 50%;
    text-align: left;
    color: #fff;
    font-weight: 700;
    box-sizing: border-box;
    padding-left: 5%;
}


.lets-connect-content{
    height: 80%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction:row;
    /* background-color: wheat; */
}

.lets-connect-form{
    height: 100%;
    width: 40%;
    background-color: transparent;
    /* background-color: wheat; */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
}

#lets-connect-form-tag{
    /* background-color: forestgreen; */
    justify-content: space-evenly;
    line-height: 5px;
}

.lets-connects-image{
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 80px;
    flex-direction: column;
}

.lets-connects-image > img{
    height: 50%;
    width: 100%;
    background-size: cover;
    
}

.lets-connect-cross-icon{
    cursor: pointer;
    color: #fff;
    height: 30px;
    width: 40px;
}

.form > form > textarea{
    border-radius: 3%;
}

#lets-Connect-textarea{
    height: 70px;
}

#biencaps-logo{
    height: 70%;
    width: 60%;
}

#err_username{
    top: 13.5%;
}
#err_number{
    top: 25.5%;
}

#err_email{
    top: 37%;
}

#captcha iframe {
    width: 100%;
}

.captcha-container {
    max-width: 500px;
    width: 90%;
    text-align: center;
}

.captcha-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.captcha-refresh {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
    margin-top: 10px;
    display: inline-block;
}

.rc-anchor-normal .rc-anchor-content {
    height: 74px;
    width: 60%;
}



/*Reponsive for laptop*/
@media only screen and (max-width: 1080px) {
    .letsConnect-box{
        width: 60%;
        height: 70%;
        top: 15%;
    }

    .lets-connect-heading{
        font-size: 13px;
    }

    .lets-connect-heading > h1{
        width: 100%;
        height: 80%;
    }

    form{
      
        font-size: 10px;
        gap: 10px;
    }

    form > input{
        background-color: rgb(86, 82, 86);

    }

    .form > form > textarea{
        height: 60px;
        width: 80%;
    }  
    
    #biencaps-logo{
        height: 50%;
        margin-top: 40px;
    }

}

/* Responsive */
@media only screen and (max-width: 1537px) {

    .lets-connect-heading > h1 {
        font-size: 25px;
    }

    .form > form > textarea {
        width: 80%;
        height: 57px;
    }

    .lets-connect-content {
        height: 82%;
        
    }

    form > label {
        top: 0px;
        font-size: 15px;
    }

}

/* Responsive for Apple laptop */
@media screen and ((max-width: 1080px) and (max-height: 713px)){
    .lets-connect-heading > h1 {
        font-size: 20px;
    }

    form > button {
        width: 30%;
    }

    .BottomContact-main-container {
        height: 44%;
    }
}


/* Reponsive for Tablets */
/* @media screen and ((max-width: 601px) and (min-height: 962px)) {
   
} */

/* @media screen and (min-width:667px ) and (max-width:1081px) {
    form>button {
        font-size: 15px;
        width: 80px;
        height: 40px;
    }

    form > label {
        font-size: 15px !important;
        margin-left: 9%;
        line-height: normal;
        height: 20px;
    }
    #captcha{
        transform: scale(60%);
    }
    .form>form>input {
        font-size: 15px;
        width: 73%;
        height: 26px;
    }
    .form > form > textarea {
        width: 73%;
        height: 60px;
    }
    #biencaps-logo {
        height: auto;
        width: 50%;
    }
    .lets-connects-image > img {
        height: auto;
        width: 100%;
        background-size: cover;
    }
    .lets-connect-form {
        height: 100%;
        width: 48% !important;
    }
    .lets-connect-heading > h1 {
        font-size: 14px;
        margin-left: 9%;
        width: 50%;
    }
    .captcha-div {
        justify-content: center;
        margin-left: 45%;
    }
    .form> form {
        gap: 0px;
    }
    #lets-Connect-textarea {
        height: 61px;
        margin-bottom: 5%;
    }
} */


@media (min-width: 1081px) and (max-width: 1366px) {
    .lets-connect-heading > h1 {
        font-size: 18px;
    }
    form>label {
        font-size: 12px !important;

    }
    .form>form>input {
        font-size: 12px;
    }
    #lets-Connect-textarea {
        height: 50px;
    }
    #captcha{
        transform: scale(86%);
    }
    #biencaps-logo {
        height: auto;
        width: 50%;
    }
    .lets-connects-image > img {
        height: auto;
        width: 100%;
    }
    .letsConnectCaptcha{
        transform: scale(70%); 

    }
    #err_username{
        font-size: 12px;
        top: 13.5%;
    }
    #err_number{
        font-size: 12px;
        top: 26%;
    }
    #err_email{
        font-size: 12px;
        top: 38.5%;
    }

    
}

@media (min-width: 1367px) and (max-width: 1600px) {
    #biencaps-logo {
        height: auto;
        width: 50%;
    }
    .lets-connects-image > img {
        height: auto;
        width: 100%;
    }
    .lets-connect-heading > h1 {
        font-size: 18px;
    }
    form>label {
        font-size: 15px !important;
        margin-top: 13px;
    }
    .form>form>input {
        font-size: 15px;
    }
    #lets-Connect-textarea {
        height: 50px;
        font-size: 15px;
    }
    #captcha{
        transform: scale(100%);
    }
    #biencaps-logo {
        height: auto;
        width: 50%;
    }
    .lets-connects-image > img {
        height: auto;
        width: 80%;
    }
    #err_username{
        font-size: 12px;
        top: 13.5%;
    }
    #err_number{
        font-size: 12px;
        top: 26%;
    }
    #err_email{
        font-size: 12px;
        top: 38.5%;
    }
    #captcha{
        transform: scale(100%) ;

    }

    .letsConnectCaptcha{
        transform: scale(70%) !important;
    }



}


/* Responsive for ipad */
@media (min-width: 820px) and (max-width: 1080px){
    .letsConnect-box {
        width: 86%;
        height: 70%;
        top: 15%;
        left: 6%;
    }
   
    .lets-connect-heading > h1 {
        font-size: 15px;
    }
    .lets-connect-cross-icon {
        width: 20px;
    }
    .lets-connect-content{
        height: 85%;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    .lets-connect-form {
        height: 100%;
        margin: 0;
        border-radius: 0;
    }
    .lets-connects-image {
        display: none;
    }

    #lets-connect-form-tag {
        gap: 15px;
    }
    
    .form > form {
        height: 100%;
    }
    .letsConnect-label {
        font-size: 25px !important;
        font-family: "Poppin";
        margin-top: 10px;
    }

    .form > form > input {
        font-size: 25px;
    }
    #err_username{
        top: 11%;
        font-size: 15px;
    }
    #err_number{
        top: 21%;
        font-size: 15px;
    }
    
    #err_email {
        top: 31%;
        font-size: 15px;
    }

    .captcha-div{
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #captcha{
        transform:scale(0.7);
        -webkit-transform:scale(1) !important;
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        margin-left: 150px;
        margin-top: -26px;
    }   

    #lets-connect-form-tag {
        /* background-color: forestgreen; */
        justify-content: space-evenly;
        line-height: 7px;
    }
    #letsConnetcSubmit{
        width: 31%;
        height: 12%;
    }
    #lets-Connect-textarea {
        height: 90px !important;
        font-size: 25PX;
    }
    .lets-connect-heading > h1 {
        font-size: 26px;
    }
    .lets-connect-cross-icon {
        width: 35px;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .letsConnect-box {
        width: 86%;
        height: 70%;
        top: 15%;
        left: 6%;
    }
   
    .lets-connect-heading > h1 {
        font-size: 15px;
    }
    .lets-connect-cross-icon {
        width: 20px;
    }
    .lets-connect-content{
        height: 85%;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    .lets-connect-form {
        height: 100%;
        margin: 0;
        border-radius: 0;
    }
    .lets-connects-image {
        display: none;
    }

    #lets-connect-form-tag {
        gap: 15px;
    }
    
    .form > form {
        height: 100%;
    }
    .letsConnect-label {
        font-size: 25px !important;
        font-family: "Poppin";
        margin-top: 10px;
    }

    .form > form > input {
        font-size: 25px;
    }
    #err_username{
        top: 11%;
        font-size: 15px;
    }
    #err_number{
        top: 21%;
        font-size: 15px;
    }
    
    #err_email {
        top: 32%;
        font-size: 15px;
    }

    .captcha-div{
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #captcha{
        transform:scale(0.7);
        -webkit-transform:scale(1) !important;
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        margin-left: 86px;
        margin-top: 30px;
    }   

    #lets-connect-form-tag {
        /* background-color: forestgreen; */
        justify-content: space-evenly;
        line-height: 7px;
    }
    #letsConnetcSubmit{
        width: 31%;
        height: 12%;
    }
    #lets-Connect-textarea {
        height: 90px !important;
        font-size: 25PX;
    }
    .lets-connect-heading > h1 {
        font-size: 26px;
    }
    .lets-connect-cross-icon {
        width: 35px;
    }
}
/* Responsive for mobile */
@media only screen and (max-width: 767px){
    .lets-connect-heading > h1 {
        font-size: 15px;
    }
    .lets-connect-cross-icon {
        width: 20px;
    }
    .lets-connect-content{
        height: 85%;
        padding: 0;
        margin: 0;
        width: 100%;
    }
    
    .lets-connect-form {
        height: 100%;
        margin: 0;
        border-radius: 0;
    }
    .lets-connects-image {
        display: none;
    }
    .letsConnect-box {
        width: 86%;
        height: 60%;
        top: 15%;
        left: 4%;
    }

    #lets-connect-form-tag {
        gap: 15px;
    }
    
    .form > form {
        height: 100%;
    }
    .letsConnect-label {
        font-size: 15px !important;
        font-family: "Poppin";
        margin-top: 10px;
    }

    .form > form > input {
        font-size: 15px;
    }
    #err_username{
        top: 10%;
        font-size: 12px;
    }
    #err_number{
        top: 22.5%;
        font-size: 12px;
    }
    
    #err_email {
        top: 35.5%;
        font-size: 12px;
    }

    .captcha-div{
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #captcha{
        transform:scale(0.7);
        -webkit-transform:scale(0.6) !important;
        transform-origin:0 0;
        -webkit-transform-origin:0 0;
        margin-left: 86px;
        margin-top: 30px;
    }   

    #lets-connect-form-tag {
        /* background-color: forestgreen; */
        justify-content: space-evenly;
        line-height: 7px;
    }
    #letsConnetcSubmit{
        width: 31%;
        height: 12%;
    }
}
