.tech-mysql {
    background-color: #272d2f;
    height: 100%;
    width: 100%;
    color: #ffffff;
}

.techmysql-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mysql-info {
    width: 1024px;
    height: 630px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5%;
}

.mysql-info-container {
    width: 578px;
    height: 593.97px;
    position: relative;
}

.upper-info,
.lower-info {
    height: 50%;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
}

.uinfo,
.linfo {
    width: 234.9689178466797px;
    height: 234.9689178466797px;
    border-radius: 34px;
    background-color: #666666;
    margin: 0px 27px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.info-heading {
    font-family: 'Poppins';
    font-size: 25px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0em;
    margin-top: 2px;

}

.uinfo-container,
.linfo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.mysql-svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.ssvg1 {
    position: absolute;
    display: inline-block;
    left: 2%;
    top: 40%;
}

.ssvg2 {
    position: absolute;
    display: inline-block;
    left: 25%;
    top: 5%;
}

.ssvg3 {
    position: absolute;
    display: inline-block;
    right: 25%;
    bottom: 15%;
}

.ssvg4 {
    position: absolute;
    display: inline-block;
    right: 10%;
    bottom: 45%;
}

.ssvg5 {
    position: absolute;
    display: inline-block;
    top: 10%;
    right: 10%;
}

.ssvg6 {
    position: absolute;
    display: inline-block;
    right: 1%;
    bottom: 2%;
}

.ssvg7 {
    position: absolute;
    display: inline;
    top: 0%;
    right: 0;
}


.ssvg1,
.ssvg2,
.ssvg3,
.ssvg4,
.ssvg5 {


    animation: 3s infinite vectors linear;
}

@keyframes vectors {
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(60deg);
    }
}


.reliability {
    width: 234.9689178466797px;
    height: 234.9689178466797px;
    border-radius: 34px;
    background-color: #666666;
    margin: 0px 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    flex-direction: column;

}

.paragraph-reliability {
    scale: 0;
    height: 77%;
    width: 81%;
    border-radius: 34px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    position: absolute;
    text-align: left;
    box-sizing: border-box;
    padding: 5px;
    white-space: normal;
    flex-direction: column;
    opacity: 1;
}
.uinfo-top-right {
    width: 234.9689178466797px;
    height: 234.9689178466797px;
    border-radius: 34px;
    background-color: #666666;
    margin: 0px 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
}

.paragraph-uinfo-top-right {
    scale: 0;
    /* background-color: rgb(177, 119, 42); */
    height: 77%;
    width: 81%;
    border-radius: 34px;
    display: flex;
    align-items: center;
    position: absolute;
    text-align: left;
    box-sizing: border-box;
    padding: 5px;
    white-space: normal;
    flex-direction: column;
    opacity: 1;
}


.linfo1 {
    width: 234.9689178466797px;
    height: 234.9689178466797px;
    border-radius: 34px;
    background-color: #666666;
    margin: 0px 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    flex-direction: column;

}

.linfo1-paraghraph {
    scale: 0;
    /* background-color:antiquewhite ; */
    height: 77%;
    width: 81%;
    border-radius: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    text-align: left;
    box-sizing: border-box;
    padding: 5px;
    white-space: normal;
    flex-direction: column;
}


.linfo2 {
    width: 234.9689178466797px;
    height: 234.9689178466797px;
    border-radius: 34px;
    background-color: #666666;
    margin: 0px 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    flex-direction: column;
}

.linfo2-paraghraph {
    scale: 0;
    /* background-color:antiquewhite ; */
    height: 77%;
    width: 81%;
    border-radius: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    text-align: left;
    box-sizing: border-box;
    padding: 5px;
    white-space: normal;
    flex-direction: column;
}

.mysql-page-for-mobile {
    display: none;
}

#Rock-solid-Reliablity, #Performance{
    font-size: 22px;
    display: none;
}

@media (min-width: 1081px) and (max-width: 1600px) {
    .reliability {
        width: 210.968918px;
        height: 210.968918px;
    }
    .linfo1 {
        width: 210.9689178466797px;
        height: 210.9689178466797px;
    }
    .uinfo-top-right {
        width: 210.9689178466797px;
        height: 210.9689178466797px;
    }
    .linfo2 {
        width: 210.9689178466797px;
        height: 210.9689178466797px;
    }
    .info-heading {
        font-size: 20px;
    }
    .upper-info, .lower-info {
        height: 45%;
    }
    .mysql-info-container {
        width: 574px;
        height: 529.97px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .paragraph-uinfo-top-right,  .linfo2-paraghraph, .linfo1-paraghraph, .paragraph-reliability{
        font-size: 13px;
    }
    
}

@media (min-width: 820px) and (max-width: 1080px){
    .nodejs-logo-div-center {
        display: none;
    }

    .mysql-page-for-mobile {
        display: block;
        height: 100%;
        width: 100%;
    }

    .mysql-svg {
        display: none;
    }

    .mysql-info {
        display: none;
    }

    .react-mobile-heading {
        margin-top: 30px
    }

        
    .mysql-mobile-card-1-left h3{
        font-size: 28px !important;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .nodejs-logo-div-center {
        display: none;
    }

    .mysql-page-for-mobile {
        display: block;
        height: 100%;
        width: 100%;
    }

    .mysql-svg {
        display: none;
    }

    .mysql-info {
        display: none;
    }

    .react-mobile-heading {
        margin-top: 30px
    }

    .mysql-mobile-card-1-left h3{
        font-size: 28px;
    }
}

/* Responsive for mobile */
@media screen and (max-width: 767px) {
    .nodejs-logo-div-center {
        display: none;
    }

    .mysql-page-for-mobile {
        display: block;
        height: 100%;
        width: 100%;
    }

    .mysql-svg {
        display: none;
    }

    .mysql-info {
        display: none;
    }

    .react-mobile-heading {
        margin-top: 30px
    }
}
