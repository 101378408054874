@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Oleo+Script:wght@700&display=swap');

.landing {
    width: 100%;
    height: 100%;
    background-image: url("../../../../public/images/Untitled.png");
    background-size: 100% 100%;
    display: block;
}

.landing-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: linear-gradient(49deg, rgb(255 255 255 / 47%), rgb(255 255 255), rgb(255 255 255));
    overflow: hidden;
}

.animation-container {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.svg1 {
    top: 17.5%;
    display: inline-block;
    position: absolute;
    left: 20%;
}

.svg2 {
    top: 45%;
    display: inline-block;
    position: absolute;
    left: 10.6%;
}

.svg3 {
    bottom: 27%;
    display: inline-block;
    position: absolute;
    left: 29%;
}

.svg4 {
    bottom: 12%;
    display: inline-block;
    position: absolute;
    right: 38%;
}

.svg5 {
    top: 17%;
    display: inline-block;
    position: absolute;
    right: 18.7%;
}

/* Amination part for landing */

.vector-arrow {

    animation: 3s infinite svg-bounce linear;

}

@keyframes svg-bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.svg1,
.svg2,
.svg3,
.svg4,
.svg5 {

    animation: svg-cones infinite 2s linear;

}

@keyframes svg-cones {

    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(60deg);
    }
}



/* End animation part for landing */

.text-containt {
    position: absolute;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.text-containt h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 81px;
    line-height: 121.5px;
    margin: 0;
}

#text-content-span {
    font-family: "Oleo Script";
    color: #ff8402;
    font-size: 90px;
    font-weight: 700;
    display: inline-block;
    width: 450px;
}

#h3 {
    font-family: 'Montserrat';
    font-size: 25px;
    line-height: 30.48px;
    margin: 0;
    font-weight: 700;
    width: 659px;
}

.connect {
    width: 221px;
    height: 49.6px;
    background-color: white;
    box-shadow: 2px 4px 10px 0px #00000047;
    margin-top: 35px;
    cursor: pointer;
    border-radius: 5px;
}

.connect p {
    margin: 0;
    margin-top: 12px;
    font-size: 22px;
    line-height: 24.38px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

.vector-arrow {
    position: absolute;
    bottom: -60%;
    border: 1px;
    display: inline-block;
    left: 45%;
}


.vector-corner1 {
    position: absolute;
    left: 0%;
    bottom: -4px;

}

.vector-corner2 {
    position: absolute;
    display: inline-block;
    right: 0%;
    bottom: -4px;

}

.vector-corner-round {
    position: absolute;
    display: inline-block;
    right: 98px;
    bottom: -4px;
}

.vector-corner-cone {
    position: absolute;
    display: inline-block;
    right: 196px;
    bottom: -4px;
}

.corner-faint-cone {
    position: absolute;
    display: inline-block;
    bottom: 95px;
    right: 0;
}

.corner-square-vector {
    position: absolute;
    display: inline-block;
    bottom: 95px;
    right: 79px;
}

.aside-square-vector {
    position: absolute;
    display: inline-block;
    bottom: 95px;
    right: 214px;
}

.vector-corner-up {
    position: absolute;
    left: 0;
    bottom: 129px;
    display: inline-block;
}

.vector-corner-up-side {
    position: absolute;
    left: 128px;
    bottom: 129px;
    display: inline-block;
}

.animation-container2 {
    width: 63%;
    height: 57%;
    display: block;
    position: relative;
    margin-right: 15%;
}

@media only screen and (max-width: 1504px) {
    .animation-container {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
    }

    .svg1 {
        top: 17.5%;
        display: inline-block;
        position: absolute;
        left: 20%;
    }

    .svg2 {
        top: 45%;
        display: inline-block;
        position: absolute;
        left: 10.6%;
    }

    .svg3 {
        bottom: 27%;
        display: inline-block;
        position: absolute;
        left: 29%;
    }

    .svg4 {
        bottom: 12%;
        display: inline-block;
        position: absolute;
        right: 44%;
    }

    .svg5 {
        top: 17%;
        display: inline-block;
        position: absolute;
        right: 18.7%;
    }

    .vector-arrow {
        bottom: -55%;
    }
}

/* Responsive for Apple laptop */
@media screen and ((max-width:1080px) and (max-height: 713px)) {

    .text-containt h1,
    #text-content-span {
        font-size: 70px;
        display: flex;
        padding-left: 10px;
    }

    .text-containt h1 {
        box-sizing: border-box;
        padding-left: 15%;
    }


    #h3 {
        font-size: 20px;
    }
}


