@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&display=swap');

.bielearn-prod {
    height: 100%;
    width: 100%;
    overflow-x: hidden;
}

.bielearn-prod-container {
    height: 100%;
    width: 100%;
    background-image: url("/public/images/bielearnLandingBackground.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
}

.bielearnProd-text-info {
    display: flex;
    height: 50%;
    width: 55%;
    margin-left: 5%;
    justify-content: flex-start;
    flex-direction: column;
    text-align: flex-start;
    margin-top: 10%;
    color: #FFFFFF;
}

.bielearn-text-heading h1 {
    font-family: 'Cabin', sans-serif;
    font-size: 64px;
    font-weight: 700;
    line-height: 78px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 17px;
}

.bielearn-text-heading p {
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 500;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;

}

.bielearn-btnInfo {
    display: flex;
    margin-top: 5%;
}

.bielearn-btnInfo button {
    width: 320px;
    height: 50px;
    border-radius: 50px;
    border: 0px solid;
    background: #FFFFFF;
    color: #504A7D;
    box-shadow: 4px 4px 20px 0px #00000026;
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;

}

.bielearn-btnInfo p {
    margin-left: 60px;
    font-family: 'Cabin', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    align-items: center;
}

.down-arrow {
    position: absolute;
    bottom: 5%;
    left: 50%;
    animation: 3s infinite svg-bounce linear;
}

@keyframes svg-bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}


/* @media (min-width: 601px) and (max-width: 768px){
.bielearnProd-text-info {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        padding: 15px;
        padding-top: 50px;
        display: flex ;
        justify-content: center ;
        align-items: center ;
    }

    .bielearn-text-heading h1 {
        font-size: 35px;
        width: 100%;
        line-height: 100px;

    }

    .bielearn-text-heading p {
        font-size: 25px;
        line-height: 40px;
        width: 100%;
    }

    .bielearn-btnInfo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        height: 30%;
        width: 50%;

    }

    .bielearn-btnInfo p {
        width: 70% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 20px;
    }

    .bielearn-btnInfo > button{
        height: 20%;
        width: 80%;
    }
    .down-arrow {
        display: none;
    }
} */



/* Responsive for laptop */
@media only screen and (max-width: 1537px) {

    .bielearn-text-heading h1 {
        font-size: 50px;
    }

    .bielearn-text-heading p {
        font-size: 30px;
    }

    .bie-solutions-info-container {
        width: 75%;
    }

    .bie-solutions-info-container {
        width: 100%;
        height: 100%;

    }

    .bie-solutions-info-upper,
    .bie-solutions-info-lower {
        height: 50%;

    }

    .container-upper img {
        height: 95%;
    }

    .container-lower img {
        height: 95%;
    }

    .testimonials-rating-left-first {
        font-size: 20px;
    }

}

/* Responsive for Mobile */
@media only screen and ((max-width: 1080px) and (max-height: 713px)) {

    .bielearn-text-heading h1 {
        font-size: 40px;
    }

    .bielearn-text-heading p {
        font-size: 25px;
        line-height: 40px;
    }

    .bie-solutions-info-container {
        width: 70%;
    }

    .bie-solutions-info-container {
        width: 100%;
        height: 100%;

    }

    .bie-solutions-info-upper,
    .bie-solutions-info-lower {
        height: 50%;

    }

    .container-upper img {
        height: 95%;
    }

    .container-lower img {
        height: 95%;
    }

    .testimonials-rating-left-first {
        font-size: 20px;
    }

    .bielearn-btnInfo button {
        width: 207px;
        font-size: 20px;
    }

    .bielearn-btnInfo p {
        font-size: 20px
    }

    .container-upper p {
        font-size: 12px;
    }
}

/* Responsive for ipad pro, ipad air */
@media (min-width: 820px) and (max-width: 1080px){
    .bielearnProd-text-info {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        padding: 15px;
        padding-top: 50px;
        overflow-x: hidden;
    }

    .bielearn-text-heading h1 {
        font-size: 70px;
        width: 100%;
        line-height: 70px;

    }

    .bielearn-text-heading p {
        font-size: 50px;
        line-height: 65px;
    }

    .bielearn-btnInfo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        height: 30%;

    }
    .bielearn-btnInfo button {
        width: 430px;
        height: 80px;
        border-radius: 50px;
        border: 0px solid;
        background: #FFFFFF;
        color: #504A7D;
        box-shadow: 4px 4px 20px 0px #00000026;
        font-family: 'Cabin', sans-serif;
        font-size: 45px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
    }

    .bielearn-btnInfo p {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 50px;
    }

    .bielearn-btnInfo p img{
        height: 100px;
    }

    .down-arrow {
        display: none;
    }
}

/* Responsive for ipad mini */
@media (min-width: 768px) and (max-width: 819px){
    .bielearnProd-text-info {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        padding: 15px;
        padding-top: 50px;
        overflow-x: hidden;
    }

    .bielearn-text-heading h1 {
        font-size: 70px;
        width: 100%;
        line-height: 70px;

    }

    .bielearn-text-heading p {
        font-size: 50px;
        line-height: 65px;
    }

    .bielearn-btnInfo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        height: 30%;

    }
    .bielearn-btnInfo button {
        width: 430px;
        height: 80px;
        border-radius: 50px;
        border: 0px solid;
        background: #FFFFFF;
        color: #504A7D;
        box-shadow: 4px 4px 20px 0px #00000026;
        font-family: 'Cabin', sans-serif;
        font-size: 45px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
    }

    .bielearn-btnInfo p {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        font-size: 50px;
    }

    .bielearn-btnInfo p img{
        height: 100px;
    }

    .down-arrow {
        display: none;
    }
}

/* Responsive for Mobile */
@media screen and (max-width: 767px) {
    .bielearnProd-text-info {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        padding: 15px;
        padding-top: 50px;
        overflow-x: hidden;
    }

    .bielearn-text-heading h1 {
        font-size: 35px;
        width: 100%;
        line-height: 50px;

    }

    .bielearn-text-heading p {
        font-size: 25px;
        line-height: 40px;
    }

    .bielearn-btnInfo {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        height: 30%;

    }

    .bielearn-btnInfo p {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
    }

    .down-arrow {
        display: none;
    }
}

