.bielearn-swiper {
    width: 100%;
    height: 50%;
}

.bielearn-swiper-container {
    height: 100%;
    width: 100%;
    padding-top: 0%;
    display: flex;
    align-items: center;
}

.bie-containt-container {
    width: 500px;
    height: 150px;
    border-radius: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px 0px #00000026;
    display: flex;
    align-items: center;
}

.bie-containt-container h3 {
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #212121;
    margin: 0;
    margin: 5px 0px;
}

.bie-sir-name {
    font-family: Quicksand;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #383838;
    margin: 5px 0px;
}

.bie-address {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #383838;

}

.bie-containt-text {
    margin-left: 25px;
}

.bie-containt-container img {
    margin-right: 25px;
}

.swiper-wrapper>div {
    width: 28%;
}

.bielearn-swiper-container .swiper {
    margin-left: 0%;
    padding: 1%;
}

.bielearn-swiper-container .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 1537px) {
    .bielearn-swiper-container .swiper-slide {
        width: 356.4px !important;
    }
    .bielearn-swiper-container .swiper-slide-prev {
        margin-left: 70px;
    }
    .bielearn-swiper-container {
        display: flex;
        align-items: center;
    }
}

/* Responsive for ipad air, ipad pro */
@media (min-width: 820px) and (max-width: 1080px){
    .bie-containt-container {
        /* width: 444px; */
        height: 149px;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0px #00000026;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 30px;
        overflow-x: hidden;
    }

    .bie-containt-container {
        width: 455px;
        height: 318px;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0px #00000026;
        display: flex;
        align-items: center;
        width: 117%;
        margin-left: -5px;
    }
    .bielearn-swiper-container {
        height: 100%;
        width: 150.99%;
        padding-top: 0%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0px;
        padding-left: 0px;
        /* background-color: yellow; */
    }
    .bie-containt-container img {
        margin-right: 7px;
        height: 73px;
    }
    .bie-containt-text {
        margin-left: 15px;
    }
    .bielearn-swiper-container .swiper-slide {
        width: 592.4px !important;
        margin-right: 3% !important;
    }
    .bie-containt-container h3 {
        font-family: 'Quicksand';
        font-size: 35px;

    }
    .bie-sir-name {
        font-family: 'Quicksand';
        font-size: 25px;
        font-weight: 500;
        line-height: 52px;
        letter-spacing: 0em;
        text-align: left;
        color: #383838;
        margin: 5px 0px;
    }
    .bie-address {
        font-family: Quicksand;
        font-size: 25px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: 0em;
        text-align: left;
        color: #383838;
    }
}

@media (min-width: 768px) and (max-width: 819px){

}

/* Responsive for Mobile */
@media screen and (max-width: 767px){
    .bie-containt-container {
        /* width: 444px; */
        height: 149px;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0px #00000026;
        display: flex;
        align-items: center;
        width: 100%;
        margin-left: 30px;
        overflow-x: hidden;
    }

    .bie-containt-container {
        /* width: 444px; */
        height: 149px;
        border-radius: 20px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0px #00000026;
        display: flex;
        align-items: center;
        width: 90%;
        margin-left: -5px;
    }
    .bielearn-swiper-container {
        height: 100%;
        width: 102.99%;
        padding-top: 0%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 0px;
        padding-left: 0px;
        /* background-color: yellow; */
    }
    .bie-containt-container img {
        margin-right: 7px;
        height: 73px;
    }
    .bie-containt-text {
        margin-left: 15px;
    }
    .bielearn-swiper-container .swiper-slide {
        width: 355.4px !important;
        margin-right: 3% !important;
    }
}