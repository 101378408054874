@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&family=Quicksand:wght@700&display=swap');

.bie-Solutions {
    height: 100%;
    width: 100%;
}
@media only screen and (max-width: 1537px){

    .timeline{
        top: 60% !important;
    }
    .testimonials-rating-left-first {
        width: 465px;
    }
}


#Timeline-mobile{
    display: none;   
}

.bie-Solutions-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
}

.bie-solutions-header {
    margin-top: 90px;
}

.bie-solutions-header h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
    text-align: left;
    color: #695B7D;

}


.bie-solutions-info-container {
    display: flex;
    height: 50%;
    width: 62%;
    margin-top: 1%;
    flex-direction: column;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    position: relative;
}

.bie-solutions-info-upper,
.bie-solutions-info-lower {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 23px 0px;
}

.container-upper,
.container-lower {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.container-lower {
    align-items: flex-start;
    flex-direction: row-reverse;
}

img.timeline {
    position: absolute;
    top: 56%;
    left: 2%;
    width: 100%;
    height: auto;
}

.bieSolutions-vectors {
    position: absolute;
    width: 100%;
    height: 100%;
}
svg.sosvg1 {
    position: absolute;
    left: 0;
    top: 15%;
}
svg.sosvg2 {
    position: absolute;
    left: 0;
    bottom: 15%;
}
svg.sosvg3 {
    position: absolute;
    right: 0;
    top: 5%;
}


/* Responsive for Apple Laptop */
@media only screen and ((max-width: 1080px) and (max-height: 713px)){
    .bie-solutions-info-container {
        width: 85%;   
    }
 }

 /* Responsive for Mobile */
 @media only screen and (max-width: 600px) {
    .bie-Solutions{
        height: 180vh;
        width: 100%;
    }

    .bie-solutions-info-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .timeline{
        display: none;
    }
    
    #Timeline-mobile{
        display: block;    
        position: absolute;
        top: 50px;
        height: 1000px;
        
    }
    .container-upper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        top: -10%;
    }

    .container-upper img {
        height: 40%;
    }

    .bieSolutions-vectors{
        display: none;
    }

    .container-lower{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        bottom: 20%;
    }

    .container-lower img{
        height: 40%;
    }

    .container-upper-1{
        top: 16%;
        left: 16%;

    }
    .container-upper-1 p{
        width: 80%;
    }

    .container-upper-2{
        top: -8%;
        left: 20%;

    }
    .container-upper-2 p{
        width: 80%;
    }

    .container-lower-1{
        bottom: 0%;
        left: 20%;
    }
    .container-lower-1 p{
        width: 80%;
    }
    .container-lower-2{
        bottom: 28%;
        left: 25%;
    }
    .container-lower-2 p{
        width: 80%;
    }
 }

 /* Smartphones */
@media (max-width: 320px) {
    .bie-Solutions{
        height: 180%;
        width: 100%;
    }

    .bie-solutions-info-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .timeline{
        display: none;
    }
    
    #Timeline-mobile{
        display: block;    
        position: absolute;
        top: 50px;
        height: 1000px;
        
    }
    .container-upper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        top: -7%;
    }

    .container-upper img {
        height: 40%;
    }

    .bieSolutions-vectors{
        display: none;
    }

    .container-lower{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        bottom: 20%;
    }

    .container-lower img{
        height: 40%;
    }

    .container-upper-1{
        top: 15%;
        left: 12%;

    }
    .container-upper-2{
        top: -8%;
        left: 20%;

    }

    .container-lower-1{
        bottom: 5%;
        left: 20%;
    }
    .container-lower-2{
        bottom: 31%;
        left: 25%;
    }
}

/* @media (min-width: 321px) and (max-width: 375px) {
    .bie-Solutions{
        height: 180%;
        width: 100%;
    }

    .bie-solutions-info-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .timeline{
        display: none;
    }
    
    #Timeline-mobile{
        display: block;    
        position: absolute;
        top: 50px;
        height: 1000px;
        
    }
    .container-upper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        top: -7%;
    }

    .container-upper img {
        height: 40%;
    }

    .bieSolutions-vectors{
        display: none;
    }

    .container-lower{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        bottom: 20%;
    }

    .container-lower img{
        height: 40%;
    }

    .container-upper-1{
        top: 15%;
        left: 12%;

    }
    .container-upper-2{
        top: -8%;
        left: 20%;

    }

    .container-lower-1{
        bottom: 5%;
        left: 20%;
    }
    .container-lower-2{
        bottom: 31%;
        left: 25%;
    }
} */

/* Tablets */
/* @media (min-width: 601px) and (max-width: 768px){
    .bie-Solutions{
        height: 180%;
        width: 100%;
    }

    .bie-solutions-info-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .timeline{
        display: none;
    }
    
    #Timeline-mobile{
        display: block;    
        position: absolute;
        top: 50px;
        height: 1000px;
        
    }
    .container-upper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        top: -7%;
    }

    .container-upper img {
        height: 40%;
    }

    .bieSolutions-vectors{
        display: none;
    }

    .container-lower{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        bottom: 20%;
    }

    .container-lower img{
        height: 40%;
    }

    .container-upper-1{
        top: 15%;
        left: 16%;

    }
    .container-upper-1 > p{
        width: 60%;
    }

    .container-upper-2{
        top: -8%;
        left: 25%;
    }
    .container-upper-2 > p{
        width: 60%;
    }

    .container-lower-1{
        bottom: 7%;
        left: 20%;
    }
    .container-lower-1 > p{
        width: 60%;
    }

    .container-lower-2{
        bottom: 30%;
        left: 25%;
    }
    .container-lower-2 > p{
        width: 60%;
    }
} */

/* @media (min-width: 769px) and (max-width: 1080px) {
    img.timeline {
        position: absolute;
        top: 56%;
        left: 10%;
        width: 79%;
        height: auto;
    }
    .bie-solutions-info-container {
        width: 70% !important;
        font-size: 10px;
    }
    .container-upper img {
        height: 78%;
        width: auto;
    }
    .container-lower img {
        height: 75%;
        width: auto;
    }
    .container-lower p{
        font-size: 12px;
    }
    .bie-solutions-header {
        margin-top: 30px;
    }
} */

/* Laptops and Desktops */
@media (min-width: 1081px) and (max-width: 1366px) {
    img.timeline {
        position: absolute;
        top: 56%;
        left: 11%;
        width: 80%;
        height: auto;
    }
    .bie-solutions-info-container {
        width: 74% !important;
        font-size: 13px;

    }
    .bie-solutions-header {
        margin-top: 30px;
    }
}

@media (min-width: 1367px) and (max-width: 1600px) {
    img.timeline {
        position: absolute;
        top: 56%;
        left: 11%;
        width: 80%;
        height: auto;
    }
}
@media (min-width: 1601px) {
    img.timeline {
        position: absolute;
        top: 56%;
        left: 11%;
        width: 80%;
        height: auto;
    }
    .bie-solutions-info-container {
        font-size: 15px;

    }
    .testimonials-info {
        padding-top: 2%;
    }
    .testimonials-rating-left {
        height: 83%;
        width: 50%;
    }
    .testimonials-rating-left-first {
        width: 96%;
    }
    .f2 {
        height: 235px;
    }
    .r-f2 {
        padding-top: 0;
        height: 59%;
    }
    .bie-solutions-header {
        margin-top: 30px;
    }
    .swiper-slide{
        width: 340px !important;
    }
   

}

@media (min-width: 820px) and (max-width: 1080px){
    .bie-Solutions{
        height: 180%;
        width: 100%;
        overflow-x: hidden;
    }

    .bie-solutions-info-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .timeline{
        display: none;
    }

    .bie-solutions-header {
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8%;
    }
    .bie-solutions-header h1 {
        font-size: 70px;
    }
    
    #Timeline-mobile{
        display: block;    
        position: absolute;
        top: 75px;
        height: 90%;
        
    }
    .container-upper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        top: -10%;
    }

    .container-upper img {
        height: 40%;
    }

    .bieSolutions-vectors{
        display: none;
    }

    .container-lower{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        bottom: 20%;
    }
    .container-lower img{
        height: 40%;
    }

    .container-upper-1 {
        top: 0%;
        left: 26%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        


    }
    .container-upper-1 p{
        width: 100%;
        font-size: 30px;
        line-height: 50px;
    }

    .container-upper-2 {
        top: 25%;
        left: 5%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        
    }
    .container-upper-2 p {
        width: 100%;
        font-size: 30px;
        line-height: 50px;
    }


    .container-lower-1 {
        bottom: 0%;
        left: 3%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        

    }
    .container-lower-1 p{
        width: 100%;
        font-size: 30px;
        line-height: 50px;
    }
    .container-lower-2 {
        bottom: 27%;
        right: 3%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        

    }
    .container-lower-2 p{
        width: 100%;
        font-size: 30px;
        line-height: 50px;
    }
}

@media (min-width: 768px) and (max-width: 819px){
    .bie-Solutions{
        height: 180%;
        width: 100%;
        overflow-x: hidden;
    }

    .bie-solutions-info-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .timeline{
        display: none;
    }

    .bie-solutions-header {
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 8%;
    }
    .bie-solutions-header h1 {
        font-size: 70px;
    }
    
    #Timeline-mobile{
        display: block;    
        position: absolute;
        top: 75px;
        height: 90%;
        
    }
    .container-upper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        top: -10%;
    }

    .container-upper img {
        height: 40%;
    }

    .bieSolutions-vectors{
        display: none;
    }

    .container-lower{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        bottom: 20%;
    }
    .container-lower img{
        height: 40%;
    }

    .container-upper-1 {
        top: 0%;
        left: 26%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        


    }
    .container-upper-1 p{
        width: 100%;
        font-size: 25px;
        line-height: 50px;
    }

    .container-upper-2 {
        top: 25%;
        left: 5%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        
    }
    .container-upper-2 p {
        width: 100%;
        font-size: 25px;
        line-height: 50px;
    }


    .container-lower-1 {
        bottom: 0%;
        left: 3%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        

    }
    .container-lower-1 p{
        width: 100%;
        font-size: 25px;
        line-height: 50px;
    }
    .container-lower-2 {
        bottom: 27%;
        right: 3%;
        width: 68%;
        height: 20%;
        font-size: 57px;
        

    }
    .container-lower-2 p{
        width: 100%;
        font-size: 25px;
        line-height: 50px;
    }
}
 /* Responsive for Mobile */
 @media screen and (max-width: 767px) {
    .bie-Solutions{
        height: 180%;
        width: 100%;
        overflow-x: hidden;
    }

    .bie-solutions-info-container{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }
    .timeline{
        display: none;
    }

    .bie-solutions-header {
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4%;
    }
    
    #Timeline-mobile{
        display: block;    
        position: absolute;
        top: 75px;
        height: 90%;
        
    }
    .container-upper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        top: -10%;
    }

    .container-upper img {
        height: 40%;
    }

    .bieSolutions-vectors{
        display: none;
    }

    .container-lower{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 15px;
        width: 60%;
        height: 30%;
        position: absolute;
        right: 11%;
        bottom: 20%;
    }
    .container-lower img{
        height: 40%;
    }

    .container-upper-1 {
        top: 27%;
        left: 6%;
        width: 80%;
        height: 20%;


    }
    .container-upper-1 p{
        width: 100%;
        font-size: 18px;
    }

    .container-upper-2 {
        top: 2%;
        left: 12%;
        width: 80%;
        height: 20%;
        font-size: 18px;

    }
    .container-upper-2 p{
        width: 100%;
        font-size: 18px;
    }


    .container-lower-1 {
        bottom: 0%;
        left: 4%;
        width: 80%;
        height: 20%;

    }
    .container-lower-1 p{
        width: 100%;
        font-size: 18px;
    }
    .container-lower-2 {
        bottom: 25%;
        left: 16%;
        width: 80%;
        height: 20%;
        font-size: 18px;

    }
    .container-lower-2 p{
        width: 100%;
        font-size: 18px;
    }
   
 }